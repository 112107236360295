import React from 'react';
import './deportation.css';
import HeaderTemplateOne from '../../../components/headertemplate/Headertemplate';
import TemplateOne from '../../../components/templateone/Templateone';


import LegalServicesMenuFour from '../../../components/legalservicesmenufour/Legalservicesmenufour';

const Deportation = () => {



    return (
      <div className="jemek__deportation-content" id="business">
      <div className='jemek__deportation-header'>
        <HeaderTemplateOne
        main_header="Deportation & Detention" />

        </div>

        <div className='jemek__deportation-body container'>
          <TemplateOne 
          

          subheader_text="As a leading law firm, Jemek Solicitors Limited is committed to providing expert legal services in all areas of deportation and administrative removal. Our team of experienced lawyers is dedicated to supporting clients facing deportation and detention, navigating complex legal procedures with care and expertise."
            
            
          header_one="Expert Advice and Representation"
          text_one="At Jemek Solicitors Limited, we pride ourselves on providing specialist advice and representation in all types of deportation and administrative removal cases. Our experienced team works diligently on cases such as automatic deportation following the commission of a criminal offence, criminal court recommended deportation, deportation deemed 'conducive to the public good', and deportation on national security grounds, including terrorism-related cases."

          header_two="Detainee Release"
          text_two="Our primary objective is to offer urgent and immediate support to clients detained under immigration-related powers. We understand the urgency of these situations and are committed to doing everything necessary to secure our clients' release. With a proven track record, we have successfully obtained the release of clients in numerous detention cases, navigating the legal landscape with skill and expertise."
        
          
          header_three="Detained Work Services"
          text_three=" We provide detained work services and assistance to secure release in a variety of situations, tailored to each client's unique circumstances. Our team excels in handling cases involving detention pending deportation or removal, detained clients in prisons, and individuals deemed unsuitable for detention due to various factors, such as age or health conditions."

          header_four="Compassionate and Dedicated Team"
          text_four="Our compassionate and dedicated team is experienced in handling sensitive cases involving children and families, mentally ill detainees, victims of torture, and victims of trafficking. We recognize the unique challenges and complexities of these cases and work diligently to advocate for our clients' best interests, ensuring they receive the support and representation they deserve."
          

          header_five="Support for Bail Applications"
          text_five="In addition to our other services, we also provide support for bail applications, ensuring that our clients have every opportunity to secure their freedom. Our comprehensive approach to deportation and detention cases ensures that our clients receive the expert guidance and representation they need during these challenging times."
          
          
          />
        </div>



        <LegalServicesMenuFour 
        main_header="More Immigration Law Content"

        menuItemId1={1}
        title_one="Business Immigration"
        link_one="/practice-areas/immigration-law/business-immigration"
        description_one="We offer expert guidance on UK business immigration, including comprehensive support for sponsorship license applications and resident labor market tests. We also provide legal updates and in-house briefings to keep your organization up-to-date with the latest changes in the immigration landscape, helping you maintain compliance and avoid costly legal repercussions."
        
        
        menuItemId2={2}
        title_two="Asylum & Human Rights"
        link_two="/practice-areas/immigration-law/asylum-and-human-rights"
        description_two="Applying for asylum or seeking protection under human rights laws can be a complex and emotional process. Our team of legal experts provides compassionate and dedicated support to individuals seeking asylum or applying for human rights claims."
        
        menuItemId3={3}
        title_three="EEA Citizens & Family Visa"
        link_three="/practice-areas/immigration-law/eea-citizens-and-family" 
        description_three="We understand that the immigration process can be overwhelming, especially for those who are not familiar with the system. As a result, we offer simplified entry processes for EEA citizen family members, including a range of documentation options. Our team is dedicated to providing comprehensive and efficient services for EEA citizens, helping them navigate the immigration process with ease."
        
        menuItemId4={4}
        title_four="Personal & Family Immigration"
        link_four="/practice-areas/immigration-law/personal-and-family"
        description_four="Our team provides expert assistance with a wide range of personal and family immigration matters, including visa and nationality applications. We understand the importance of family reunification and work diligently to help individuals and families navigate the complexities of the immigration system. We offer a range of services, from initial consultation to application preparation and representation at appeals, to ensure the best possible outcome for our clients."
        


        />


        </div>


    );
  };
  
  export default Deportation;