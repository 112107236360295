import React from 'react';
import './spouse_maintenance.css';


import HeaderTemplateOne from '../../../components/headertemplate/Headertemplate';
import TemplateOne from '../../../components/templateone/Templateone';

import LegalServicesMenu from '../../../components/legalservicesmenu/Legalservicesmenu';



const SpouseMaintenance = () => {



    return (
      <div className='jemek__spousalmaintenance' id="divorce">
      <div className='jemek__spousalmaintenance-header'>
        <HeaderTemplateOne 
        main_header="Spousal Maintenance"/>
      </div>

      <div className='jemek__spousalmaintenance-body container'>
        <TemplateOne

          subheader_text="Spousal maintenance is an important consideration in divorce proceedings, as it can provide much-needed support for a party who cannot adequately support themselves. At Jemek Solicitors Limited, we provide expert guidance on all aspects of spousal maintenance, striving to achieve the best outcomes for our clients."
          
          
          header_one="Determining Spousal Maintenance:"
          text_one="Spousal maintenance is awarded based on a range of factors, including the needs of the recipient, their own income (actual or potential), and the payer's income. Our team at Jemek Solicitors Limited will work diligently to ensure that the amount paid is calculated fairly and appropriately, taking all aspects of your case into consideration."

          header_two="Payment of Spousal Maintenance"
          text_two="If you are required to pay spousal maintenance, our team will provide support and guidance throughout the process to ensure that the payment you make to your ex-partner is fair. Our experienced professionals will take all factors of your case into consideration and provide the best representation possible."
          
          header_three="Adjusting Spousal Maintenance"
          text_three=" In some cases, the financial situation of one or both parties may change, and adjustments to the spousal maintenance payments may be necessary. At Jemek Solicitors Limited, we can facilitate an application on your behalf to request a reduction in your maintenance payments or vary the term if necessary. "
          text_important_three="Our goal is to provide support and guidance throughout the process, ensuring that any adjustments are made fairly and accurately."

          header_four="Ending Spousal Maintenance"
          text_four="It is essential to note that spousal maintenance automatically comes to an end if the recipient remarries or either party passes away. Our team at Jemek Solicitors Limited will provide guidance on the circumstances under which spousal maintenance may end and the steps required to do so."


        />

      </div>



      <LegalServicesMenu 
        main_header="More Family Law Content"
        

        menuItemId1={1}
        title_one="Pre-nuptial Agreements"
        link_one="/practice-areas/family-law/prenuptial-agreements" 
        description_one="Pre-nuptial agreements, once exclusive to the wealthy, have gained broader acceptance and can provide a framework for managing finances during marriage. Careful drafting is essential to ensure fairness and address unique individual circumstances."
        
        menuItemId2={2}
        title_two="Divorce & Separation"
        link_two="/practice-areas/family-law/divorce-and-separation"
        description_two="Our team is dedicated to providing practical advice and support throughout the entire process, ensuring the best possible outcome for all parties involved. We work with our clients to help them make informed decisions and provide a range of services that address their individual needs."
        

        menuItemId3={3}
        title_three="Financial Settlements"
        link_three="/practice-areas/family-law/prenuptial-agreements" 
        description_three="When it comes to divorce proceedings, financial settlements can be complex and require full disclosure of finances, negotiation, and potentially a final hearing. A Consent Order can be used to formalize agreements, becoming binding once sealed by the court. Our team at Jemek Solicitors Limited can provide expert guidance on this process and help achieve equitable results."
        
        menuItemId4={4}
        title_four="Financial Division"
        link_four="/practice-areas/family-law/financial-division"
        description_four="Our financial division at Jemek Solicitors Limited focuses on promoting settlements and providing cost-effective services in cases involving asset redistribution. We work closely with related professionals to ensure the best possible outcome for our clients, providing honest and experienced representation."

        menuItemId5={5}
        title_five="Civil Partnerships and Dissolution"
        link_five="/practice-areas/family-law/civil-partnerships-and-dissolution"
        description_five="We offer expert guidance in civil partnership dissolution, addressing finances and children. Our team at Jemek Solicitors Limited understands the complexities of partnership law, and we are committed to providing the best possible outcome for our clients."
        
        menuItemId6={6}
        title_six="Financial Claims For Unmarried Couples"
        link_six="/practice-areas/family-law/financial-claims"
        description_six="Unmarried couples face unique challenges when it comes to addressing financial claims, as they must rely on trust laws and different legal remedies. Our team at Jemek Solicitors Limited provides expert guidance to navigate these complex situations and achieve equitable results."

        menuItemId7={7}
        title_seven="Domestice Abuse"
        link_seven="/practice-areas/family-law/domestic-abuse"
        description_seven="Domestic abuse is a pervasive issue that can manifest in various forms. Our firm is committed to providing compassionate support and guidance to help victims navigate their options and seek the protection they deserve. At Jemek Solicitors Limited, we take this issue seriously and work tirelessly to ensure our clients' safety."
        
        menuItemId8={8}
        title_eight="Cohabitation"
        link_eight="/practice-areas/family-law/co-habitation"
        description_eight="Unmarried couples face unique legal challenges when it comes to parental responsibility and property division. Seeking expert legal advice can help to protect the rights and interests of both partners and ensure they are treated fairly under the law."
        


      />
      </div>
    );
  };
  
  export default SpouseMaintenance;