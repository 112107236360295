import React from 'react';
import './personal_immigration.css';
import HeaderTemplateOne from '../../../components/headertemplate/Headertemplate';
import TemplateOne from '../../../components/templateone/Templateone';


import LegalServicesMenuFour from '../../../components/legalservicesmenufour/Legalservicesmenufour';



const PersonalImmigration  = () => {



  return (
    <div className="jemek__personal-content" id="business">
    <div className='jemek__personal-header'>
      <HeaderTemplateOne
      main_header="Personal & Family Immigration" />

      </div>

      <div className='jemek__personaln-body container'>
        <TemplateOne 
        

        subheader_text="At our firm, we pride ourselves on our expertise in personal and family immigration, and our dedication to providing expert assistance and guidance in all aspects of visa and nationality applications. "
          
          
        header_one="Expert Assistance for Family Visas"
        text_one="We offer a wide range of support for family and personal visas, including family visit visas and visitor visas. These visas allow UK residents or citizens to bring their family members or visitors to the UK for a short period, and we guide clients through the application process to ensure their success."

      
      
        header_two="Guidance for Tier 4 and Tier 1 Visas"
        text_two="Our team is well-versed in handling Tier 4 and Tier 1 visa applications for international students, entrepreneurs, investors, and other high-value migrants. We work closely with clients to evaluate their eligibility, and provide expert guidance to ensure a smooth and successful application process."
      

        header_three="Support for Tier 2 and Spouse Visas"
        text_three="We also provide assistance with Tier 2 visas for skilled workers sponsored by a UK employer, as well as spouse visas for partners of UK citizens or residents. Our team helps clients navigate the complexities of the application process, ensuring a successful outcome."

        header_four="Applications for Dependent Relative and Children's Visas"
        text_four="Our services extend to dependent relative visas for elderly or disabled relatives, and children's visas for dependents under the age of 18. We offer comprehensive support throughout the application process, ensuring that our clients receive the expert guidance they need to achieve their goals."
      

        header_five= "Nationality and European Union Applications"
        text_five="Finally, we offer support for European Union applications and nationality applications, helping clients establish their right to live and work in the UK on a long-term basis. Our team understands the complexities of the application process, and provides expert guidance and support to ensure a successful outcome."

        />
      </div>



      <LegalServicesMenuFour 
      main_header="More Immigration Law Content"

      menuItemId1={1}
      title_one="Business Immigration"
      link_one="/practice-areas/immigration-law/business-immigration"
      description_one="We offer expert guidance on UK business immigration, including comprehensive support for sponsorship license applications and resident labor market tests. We also provide legal updates and in-house briefings to keep your organization up-to-date with the latest changes in the immigration landscape, helping you maintain compliance and avoid costly legal repercussions."
      
      
      menuItemId2={2}
      title_two="Asylum & Human Rights"
      link_two="/practice-areas/immigration-law/asylum-and-human-rights"
      description_two="Applying for asylum or seeking protection under human rights laws can be a complex and emotional process. Our team of legal experts provides compassionate and dedicated support to individuals seeking asylum or applying for human rights claims."
      
      menuItemId3={3}
      title_three="EEA Citizens & Family Visa"
      link_three="/practice-areas/immigration-law/eea-citizens-and-family" 
      description_three="We understand that the immigration process can be overwhelming, especially for those who are not familiar with the system. As a result, we offer simplified entry processes for EEA citizen family members, including a range of documentation options. Our team is dedicated to providing comprehensive and efficient services for EEA citizens, helping them navigate the immigration process with ease."
      
   
      menuItemId4={4}
      title_four="Deportation & Detention"
      link_four="/practice-areas/immigration-law/deportation-and-detention" 
      description_four="Being detained or facing deportation can be a traumatic and overwhelming experience. Our team provides comprehensive legal services for deportation and detention cases, including appeals and detainee release. We understand the importance of a swift and compassionate response, and work diligently to ensure that our clients are provided with the best possible representation and support throughout the entire process."
      


      />


      </div>


  );
};
  
  export default PersonalImmigration;