import React from 'react';
import './divorce.css';

import HeaderTemplateOne from '../../../components/headertemplate/Headertemplate';
import TemplateOne from '../../../components/templateone/Templateone';

import LegalServicesMenu from '../../../components/legalservicesmenu/Legalservicesmenu';


const Divorce = () => {



    return (
      <div className='jemek__divorce' id="divorce">
      <div className='jemek__divorce-header'>
        <HeaderTemplateOne 
        main_header="Divorce & Separation"/>
      </div>

      <div className='jemek__divorce-body container'>
        <TemplateOne

          subheader_text="Divorce or separation is a difficult decision that should be taken seriously. It can be an emotional and challenging time for all parties involved. At our law firm, we have a team of experienced professionals who can help you navigate this process. We provide sensitive and practical advice combined with decisive action to ensure the best outcome for you."
          
          
          header_one="Grounds for Divorce and Legal Separation"
          text_one="We understand that the divorce process can become more challenging if you have lost a marriage certificate, cannot locate your spouse, or if your spouse is uncooperative. Our team can advise you on the grounds for divorce and the law and process involved. Additionally, we can guide you on legal separation if you prefer a formal separation without dissolving the marriage."
          text_important_one="Our team at Jemek Solicitors Limited can help you navigate the complexities of pre-nuptial agreements and ensure that your interests are protected."

          header_two="Divorce Process in England and Wales"
          text_two="The divorce process in England and Wales is relatively straightforward. The only ground for divorce is the irreconcilable breakdown of the marriage, based on adultery, unreasonable behavior, desertion for more than two years, two years of separation with the other party's consent, or five years of separation.  "
          text_important_two=" Our team is skilled in resolving problems such as locating an absent party or addressing issues arising from foreign and international marriages."
          
          header_three="Ancillary Matters"
          text_three="We understand that divorce involves more than just the legal process. It can affect many aspects of your life, such as financial and child arrangements. Our team will advise you on ancillary matters, such as arrangements for children and financial claims. We aim to ensure that all parties involved are provided with the best outcome."
          

          header_four="Fixed Fees and Additional Charges"
          text_four="We offer a fixed fee of £800 plus VAT and court fees for a straightforward divorce. Typically, the divorce process takes between three to six months, and usually, no court attendance is necessary. However, if complications arise, additional charges and potentially further court fees may apply. Our team will discuss these charges with you beforehand."


        />

      </div>
      
      <LegalServicesMenu 
        main_header="More Family Law Content"
        

        menuItemId1={1}
        title_one="Pre-nuptial Agreements"
        link_one="/practice-areas/family-law/prenuptial-agreements" 
        description_one="Pre-nuptial agreements, once exclusive to the wealthy, have gained broader acceptance and can provide a framework for managing finances during marriage. Careful drafting is essential to ensure fairness and address unique individual circumstances."
        
        menuItemId2={2}
        title_two="Financial Settlement"
        link_two="/practice-areas/family-law/financial-settlements"
        description_two="When it comes to divorce proceedings, financial settlements can be complex and require full disclosure of finances, negotiation, and potentially a final hearing. A Consent Order can be used to formalize agreements, becoming binding once sealed by the court. Our team at Jemek Solicitors Limited can provide expert guidance on this process and help achieve equitable results."
        

        menuItemId3={3}
        title_three="Spousal Maintenance"
        link_three="/practice-areas/family-law/spousal-maintenance"
        description_three="At Jemek Solicitors Limited, we offer expert guidance on spousal maintenance, working to ensure fair and appropriate calculations for both recipients and payers. We understand that adjustments may be necessary due to changes in financial circumstances, and our team can assist with those as well. We are committed to achieving the best possible outcome for our clients."
        
        menuItemId4={4}
        title_four="Financial Division"
        link_four="/practice-areas/family-law/financial-division"
        description_four="Our financial division at Jemek Solicitors Limited focuses on promoting settlements and providing cost-effective services in cases involving asset redistribution. We work closely with related professionals to ensure the best possible outcome for our clients, providing honest and experienced representation."

        menuItemId5={5}
        title_five="Civil Partnerships and Dissolution"
        link_five="/practice-areas/family-law/civil-partnerships-and-dissolution"
        description_five="We offer expert guidance in civil partnership dissolution, addressing finances and children. Our team at Jemek Solicitors Limited understands the complexities of partnership law, and we are committed to providing the best possible outcome for our clients."
        
        menuItemId6={6}
        title_six="Financial Claims For Unmarried Couples"
        link_six="/practice-areas/family-law/financial-claims"
        description_six="Unmarried couples face unique challenges when it comes to addressing financial claims, as they must rely on trust laws and different legal remedies. Our team at Jemek Solicitors Limited provides expert guidance to navigate these complex situations and achieve equitable results."

        menuItemId7={7}
        title_seven="Domestice Abuse"
        link_seven="/practice-areas/family-law/domestic-abuse"
        description_seven="Domestic abuse is a pervasive issue that can manifest in various forms. Our firm is committed to providing compassionate support and guidance to help victims navigate their options and seek the protection they deserve. At Jemek Solicitors Limited, we take this issue seriously and work tirelessly to ensure our clients' safety."
        
        menuItemId8={8}
        title_eight="Cohabitation"
        link_eight="/practice-areas/family-law/co-habitation"
        description_eight="Unmarried couples face unique legal challenges when it comes to parental responsibility and property division. Seeking expert legal advice can help to protect the rights and interests of both partners and ensure they are treated fairly under the law."
        
      />
      </div>

    );
  };
  
  export default Divorce;