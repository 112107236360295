import React from "react";
import './masonrycard.css';



const MasonryCard = ({masonry_card_header, masonry_card_span, card_one_link, card_one_image, card_one_alt, card_one_header,
card_one_text, card_one_button, card_two_link, card_two_image, card_two_alt, card_two_header, card_two_text, card_two_button,
card_three_link, card_three_image, card_three_alt, card_three_header, card_three_text, card_three_button, card_four_link, card_four_image, card_four_alt, card_four_header, card_four_text, card_four_button}) =>{
    return (
    <div>
        <section className="jemek__masonry-card__section section__padding page-section">
          <div className="jemek__masonry-card__container container">
            <div className="jemek__masonry-card__header">
              <h3>{masonry_card_header} <span className="jemek__masonry-card__span-highlight">{masonry_card_span}</span></h3>
            </div>
            <div className="jemek__masonrycard-items-container container">
              <div className="jemek__masonrycard-items">

                <div className="jemek__masonrycard-item-wrapper">
                <div className="jemek__masonrycard-one">
                  <a href={card_one_link}>
                    <div className="jemek__masonrycard-img">
                      <img src={card_one_image} alt={card_one_alt} />
                    </div>
                    <div className="jemek__masonry-card__text-container">
                    <div className="jemek__masonrycard-text">
                      <h4>{card_one_header.map((item, index) => 
                      item.highlight ? <span className="span-text-highlight" key={index}> {item.text}</span> : item.text)}</h4>
                      <div className="jemek__masonry-card__text-description">
                      <p>
                        {card_one_text}
                      </p>
                      </div>
                      </div>
                      <div className="jemek__masonry-learnmore">
                      <p>{card_one_button.map((item, index) => 
                        item.highlight ? <span className="span-text-highlight-two" key={index}> {item.text}</span> : item.text)}</p>
                      </div>
                    </div>

                    
                  </a>
                </div>
                </div>

                <div className="jemek__masonrycard-item-wrapper">
                <div className="jemek__masonrycard-two">
                  <a href={card_two_link}>
                  <div className="jemek__masonrycard-img">
                    <img src={card_two_image} alt={card_two_alt}/>
                    </div>
                    <div className="jemek__masonry-card__text-container">
                    <div className="jemek__masonrycard-text">
                      <h4>{card_two_header.map((item, index) => 
                      item.highlight ? <span className="span-text-highlight" key={index}> {item.text}</span> : item.text)}</h4>
                      <div className="jemek__masonry-card__text-description">
                      <p>{card_two_text}</p>
                      </div>

                      </div>
                      <div className="jemek__masonry-learnmore">
                      <p>{card_two_button.map((item, index) => 
                        item.highlight ? <span className="span-text-highlight-two" key={index}> {item.text}</span> : item.text)}</p>
                      </div>
                    </div>


                  </a>
                </div>
                </div>

                <div className="jemek__masonrycard-item-wrapper">
                <div className="jemek__masonrycard-three">
                  <a href={card_three_link}>
                  <div className="jemek__masonrycard-img">
                    <img src={card_three_image} alt={card_three_alt} />
                    </div>

                    <div className="jemek__masonry-card__text-container">
                    <div className="jemek__masonrycard-text">
                      <h4>{card_three_header.map((item, index) => 
                      item.highlight ? <span className="span-text-highlight" key={index}> {item.text}</span> : item.text)}</h4>
                      <div className="jemek__masonry-card__text-description">
                      <p>
                        {card_three_text}
                      </p>
                      </div>

                      </div>
                      <div className="jemek__masonry-learnmore">
                      <p>{card_three_button.map((item, index) => 
                        item.highlight ? <span className="span-text-highlight-two" key={index}> {item.text}</span> : item.text)}</p>
                    
                    </div>
                    </div>
                  </a>
                </div>
              </div>

              <div className="jemek__masonrycard-item-wrapper">
                <div className="jemek__masonrycard-four">
                  <a href={card_four_link}>
                  <div className="jemek__masonrycard-img">
                    <img src={card_four_image} alt="" />
                    </div>

                    <div className="jemek__masonry-card__text-container">
                    <div className="jemek__masonrycard-text">
                      <h4>{card_four_header.map((item, index) => 
                      item.highlight ? <span className="span-text-highlight" key={index}> {item.text}</span> : item.text)}</h4>
                      <div className="jemek__masonry-card__text-description">
                      <p>
                        {card_four_text}
                      </p>
                      </div>
                      </div>
                      <div className="jemek__masonry-learnmore">
                        <p>{card_four_button.map((item, index) => 
                        item.highlight ? <span className="span-text-highlight-two" key={index}> {item.text}</span> : item.text)}</p>
                      </div>
                      </div>
                
                  </a>
                </div>
              </div>

              </div>
            </div>
          </div>
        </section>
    </div>
    )
}

export default MasonryCard;