import React, { useState, useEffect, useRef } from "react";

import { Link } from "react-router-dom";

import Fade from 'react-reveal/Fade';



import 'animate.css';

import "./landing.css";

import useIntersectionObserver from "../../components/intersectionobserver/Intersectionobserver";



import { RiArrowRightSLine, RiArrowDownSLine } from "react-icons/ri";






import {
 
  aboutus_landing2,

  feepaying2_funding,
  legalaid_funding,
} from "./imports";

import {

  business_immg,
  deportation_immg,
  asylum_immg,
  family_immg,
  eea_immg,
} from "./imports";

import {
  cohabitation_family,
  domestic_family,

  financial_family,
  spousal_family,
  settlement_family,
  prenup_family,
  divorce_family,
  claims_family,
  partnership_family,
} from "./imports";

import 'lazysizes'

import {
  childprotection_childcare,
  maintenance_childcare,
  residence_childcare,
  separation_childcare,
  adoption_family,
  explore_immigration,
  explore_family,
  explore_childcare,
} from "./imports";

const Index = () => {

  const [show, setShow] = useState(false);
  const refOne = useIntersectionObserver(setShow);
  const refTwo = useIntersectionObserver(setShow);
  const refThree = useIntersectionObserver(setShow);
  
  const handleClick = () => {
    window.location.href = "/who-we-are";
  };

  const [toggleImmigrationContent, setToggleImmigrationContent] =
    useState(false);
  const [toggleFamilyLawContent, setToggleFamilyLawContent] = useState(false);
  const [toggleChildCareContent, setToggleChildCareContent] = useState(false);
  const [togglePracticeCardTeaser, setTogglePracticeCardTeaser] =
    useState(true);

  const buttonRefs = useRef([]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        buttonRefs.current &&
        buttonRefs.current.every(
          (buttonRef) =>
            !buttonRef.current || !buttonRef.current.contains(event.target)
        ) &&
        !event.target.classList.contains("jemek__practiceareascard-teaser")
      ) {
        buttonRefs.current.forEach((buttonRef) => {
          if (buttonRef.current) {
            buttonRef.current.classList.remove("active");
          }
        });
      }
    };

    document.body.addEventListener("click", handleOutsideClick);

    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleImmigrationContentClick = () => {
    setToggleFamilyLawContent(false);
    setToggleChildCareContent(false);
    setToggleImmigrationContent(!toggleImmigrationContent);
    setTogglePracticeCardTeaser(false);

    if (toggleImmigrationContent) {
      setTogglePracticeCardTeaser(true);
    }
  };

  const handleFamilyLawContentClick = () => {
    setToggleImmigrationContent(false);
    setToggleChildCareContent(false);
    setToggleFamilyLawContent(!toggleFamilyLawContent);
    setTogglePracticeCardTeaser(false);

    if (toggleFamilyLawContent) {
      setTogglePracticeCardTeaser(true);
    }
  };
  const handleChildCareLawContent = () => {
    setToggleFamilyLawContent(false);
    setToggleImmigrationContent(false);
    setToggleChildCareContent(!toggleChildCareContent);
    setTogglePracticeCardTeaser(false);

    if (toggleChildCareContent) {
      setTogglePracticeCardTeaser(true);
    }
  };



  return (
    <div>
      <section className="jemek__header section__padding page-section lazyload" 
      id="landing"
      data-bg="url(/home/ghost/Documents/codingprojs/react_app_jemek/frontend_react/src/assets/hero-background-four.webp)">
        <div className="jemek__header-container">
                  <div className="jemek__hero-content  header center text-uppercase">
                    <div className="jemek__hero-content__wrapper">
                    <h1 className="gradient__text header">
                      We have the <span class="span-highlight">talent</span> and{" "}
                      <span class="span-highlight">experience</span> to meet
                      your needs!
                    </h1>
                    <p>
                      Jemek Solicitors Limited has an outstanding reputation of
                      offering excellent services in several areas of law.
                    </p>
                    <a href="/who-we-are">
                    <button
                      className="jemek__header-herobtn-one text-uppercase"
                      type="button"
                      href="#"
                    >
               
                      What We Do
                    </button>
                    </a>


                    <a href="/reach-us">
                    <button
                      className="jemek__header-herobtn-two text-uppercase"
                      type="button"
                     
                    >
                      Reach Us
                    </button>
                    </a>
                    </div>
                  </div>
        </div>
      </section>



      <div className="jemek__practiceareas" id="practiceareas">
        <div className="jemek__practiceareas-container">
          <div className="jemek__practiceareas-text text-center">
            <h1 className="gradient__text">Jemek Solicitors In Focus</h1>
            <p className="section-subheading">
              We have a well established team of solicitors, advocates,
              consultants and support staff that are always happy to help. We
              Are More Than Just a Law Firm.
            </p>
          </div>
          <div className="jemek__practiceareascard-data-container page-section">
            <div className="jemek__practiceareascard-components">
              <div className="jemek__practiceareascard-component-one">
                <button
                  ref={(ref) => (buttonRefs.current[0] = ref)}
                  className={`jemek__practiceareascard-component-one-label${
                    toggleImmigrationContent
                      ? " active"
                      : toggleFamilyLawContent ||
                        toggleChildCareContent ||
                        togglePracticeCardTeaser
                      ? " inactive"
                      : ""
                  }`}
                  onClick={handleImmigrationContentClick}
                >
                  <span>
                    Immigration and Asylum Law
                    {toggleImmigrationContent ? (
                      <RiArrowDownSLine color="#fff" />
                    ) : (
                      <RiArrowRightSLine color="#fff" />
                    )}
                  </span>
                </button>
              </div>

              <div className="jemek__practiceareascard-component-two">
                <button
                  ref={(ref) => (buttonRefs.current[1] = ref)}
                  className={`jemek__practiceareascard-component-two-label${
                    toggleFamilyLawContent
                      ? " active"
                      : toggleImmigrationContent ||
                        toggleChildCareContent ||
                        togglePracticeCardTeaser
                      ? " inactive"
                      : ""
                  }`}
                  onClick={handleFamilyLawContentClick}
                >
                  <span>
                    Family Law
                    {toggleFamilyLawContent ? (
                      <RiArrowDownSLine color="#fff" />
                    ) : (
                      <RiArrowRightSLine color="#fff" />
                    )}
                  </span>
                </button>
              </div>

              <div className="jemek__practiceareascard-component-three">
                <button
                  ref={(ref) => (buttonRefs.current[2] = ref)}
                  className={`jemek__practiceareascard-component-three-label${
                    toggleChildCareContent
                      ? " active"
                      : toggleImmigrationContent ||
                        toggleFamilyLawContent ||
                        togglePracticeCardTeaser
                      ? " inactive"
                      : ""
                  }`}
                  onClick={handleChildCareLawContent}
                >
                  <span>
                    Child Care Law
                    {toggleChildCareContent ? (
                      <RiArrowDownSLine color="#fff" />
                    ) : (
                      <RiArrowRightSLine color="#fff" />
                    )}
                  </span>
                </button>
              </div>
            </div>
            <div className="jemek__practiceareascard-components-cards">
              {togglePracticeCardTeaser && (
                <div className="jemek__practiceareascard-teaser card-animation" ref={refOne}>
                  <div className="jemek__practiceareascard-teaser-container container">
                    <div className="jemek__practiceareascard-teaser-row row">
                      <div className="jemek__practiceareascard-teaser-columnone col-lg-6 col-lg-6">
                        <div className="jemek__practiceareascard-teaser-itemone">
                          <Link to="/practice-areas?activeButton=Immigration and Asylum Law" className="jemek__practiceareascard-link">
                            <div className="jemek__practiceareascard-img-wrapper">
                              <img src={explore_immigration} className="lazyload" alt="Immigration & Asylum" />
                            </div>
                          </Link>
             
                        <div className="jemek__practiceareascard-textsection-wrapper">
                          <div className="jemek__practiceareascard-teaser-text">
                            <h4>Immigration & Asylum Law</h4>
                            <p>
                              We handle visa applications, asylum claims, and
                              nationality matters, guiding you through the
                              complex field of immigration law.{" "}
                            </p>
                          </div>
                          <div className="jemek__practiceareascard-button">
                            <Link to="/practice-areas?activeButton=Immigration and Asylum Law">
                              <button
                                className="jemek__practiceareascard-button btn btn-custom-transparent btn-xl"
                                type="button"
                                aria-label="Learn more about our Immigration & Asylum Law practice area"
                              >
                                Learn More
                              </button>
                            </Link>
                          </div>
                        </div>
                        </div>
                      </div>
                      <div className="jemek__practiceareascard-teaser-columntwo col-lg-6 col-lg-6">
                        <div className="jemek__practiceareascard-teaser-itemone">
                          <Link to="/practice-areas?activeButton=Family Law" className="jemek__practiceareascard-link">
                            <div className="jemek__practiceareascard-img-wrapper">
                              <img src={explore_family} alt="Family Law" />
                            </div>
                          </Link>

                          <div className="jemek__practiceareascard-textsection-wrapper">
                            <div className="jemek__practiceareascard-teaser-text">
                              <h4>Family Law</h4>
                              <p>
                                At Jemek Solicitors, we specialize in family law
                                matters, including divorce, financial
                                settlements, child custody, and cohabitation
                                agreements.
                              </p>
                            </div>
                            <div className="jemek__practiceareascard-button">
                              <Link to="/practice-areas?activeButton=Family Law">
                                <button
                                  className="jemek__practiceareascard-button btn btn-custom-transparent btn-xl"
                                  type="button"
                        
                                  aria-label="Learn more about our Family Law practice area"
                                >
                                  Learn More
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="jemek__practiceareascard-teaser-columnthree col-lg-6 col-lg-6">
                        <div className="jemek__practiceareascard-teaser-itemone">
                          <Link to="/practice-areas?activeButton=Child Care Law" className="jemek__practiceareascard-link">
                            <div className="jemek__practiceareascard-img-wrapper">
                              <img src={explore_childcare} alt="Child Care Law" />
                            </div>
                          </Link>

                          <div className="jemek__practiceareascard-textsection-wrapper">
                            <div className="jemek__practiceareascard-teaser-text">
                              <h4>Child Care Law</h4>
                              <p>
                                Protecting the rights and well-being of children
                                is of utmost importance to us. Our child care
                                law services cover various issues.
                              </p>
                            </div>
                            <div className="jemek__practiceareascard-button">
                              <Link to="/practice-areas?activeButton=Child Care Law">
                                <button
                                  className="jemek__practiceareascard-button btn btn-custom-transparent btn-xl"
                                  type="button"
                
                                  aria-label="Learn more about our Child Care Law practice area"
                                >
                                  Learn More
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <section className="jemek__ourpractice-cards-wrapper">
                <div className="jemek__ourpractice-immigration-components">
                  {toggleImmigrationContent && (
                    <div className="jemek__ourpractice-immigration-contents">
                      <div className="jemek__ourpractice-cards-grid">
                        <div className="jemek__ourpractice-first-card card-animation">
                          <div className="jemek__card-image">
                            <a
                              href="/practice-areas/immigration-law/business-immigration"
                              className="jemek__card-link"
                            >
                              <div className="jemek__card-img-wrapper">
                                <img src={business_immg} alt="Business Immigration" loading="lazy"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a
                                href="/practice-areas/immigration-law/business-immigration"
                                className="jemek__card-link"
                              >
                                <h3 className="jemek__card-data-title">
                                  Business Immigration
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Expert guidance on UK business immigration,
                                  including UKBA compliance and sponsorship
                                  licences.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/immigration-law/business-immigration"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="jemek__ourpractice-second-card card-animation">
                          <div className="jemek__card-image">
                            <a
                              href="/practice-areas/immigration-law/asylum-and-human-rights"
                              className="jemek__card-link"
                            >
                              <div className="jemek__card-img-wrapper">
                                <img src={asylum_immg} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a
                                href="/practice-areas/immigration-law/asylum-and-human-rights"
                                className="jemek__card-link"
                              >
                                <h3 className="jemek__card-data-title">
                                  Asylum and Human Rights
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Expert legal support for asylum and human
                                  rights claims, including judicial review and
                                  ECHR-based applications.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/immigration-law/asylum-and-human-rights"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="jemek__ourpractice-third-card card-animation">
                          <div className="jemek__card-image">
                            <a
                              href="/practice-areas/immigration-law/personal-and-family"
                              className="jemek__card-link"
                            >
                              <div className="jemek__card-img-wrapper">
                                <img src={family_immg} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a
                                href="/practice-areas/immigration-law/personal-and-family"
                                className="jemek__card-link"
                              >
                                <h3 className="jemek__card-data-title">
                                  Personal and Family Immigration
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Expert assistance in personal and family
                                  immigration, covering a wide range of visa and
                                  nationality applications.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/immigration-law/personal-and-family"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="jemek__ourpractice-fourth-card card-animation">
                          <div className="jemek__card-image">
                            <a
                              href="/practice-areas/immigration-law/eea-citizens-and-family"
                              className="jemek__card-link"
                            >
                              <div className="jemek__card-img-wrapper">
                                <img src={eea_immg} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a
                                href="/practice-areas/immigration-law/eea-citizens-and-family"
                                className="jemek__card-link"
                              >
                                <h3 className="jemek__card-data-title">
                                  EEA Citizens
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Simplified entry process for EEA citizen
                                  family members with a range of documentation
                                  options.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/immigration-law/eea-citizens-and-family"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="jemek__ourpractice-fifth-card card-animation">
                          <div className="jemek__card-image">
                            <a
                              href="/practice-areas/immigration-law/deportation-and-detention"
                              className="jemek__card-link"
                            >
                              <div className="jemek__card-img-wrapper">
                                <img src={deportation_immg} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a
                                href="/practice-areas/immigration-law/deportation-and-detention"
                                className="jemek__card-link"
                              >
                                <h3 className="jemek__card-data-title">
                                  Deportation and Detention
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Comprehensive legal services for deportation
                                  and detention cases, including appeals and
                                  detainee release.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/immigration-law/deportation-and-detention"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="jemek__ourpractice-family-components">
                  {toggleFamilyLawContent && (
                    <div className="jemek__ourpractice-family-contents">
                      <div className="jemek__ourpractice-cards-grid">
                        <div className="jemek__ourpractice-first-card card-animation">
                          <div className="jemek__card-image">
                            <a
                              href="/practice-areas/family-law/divorce-and-separation"
                              className="jemek__card-link"
                            >
                              <div className="jemek__card-img-wrapper">
                                <img src={divorce_family} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a
                                href="/practice-areas/family-law/divorce-and-separation"
                                className="jemek__card-link"
                              >
                                <h3 className="jemek__card-data-title">
                                  Divorce and Separation
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Comprehensive divorce and separation support
                                  with sensitivity and expertise for optimal
                                  outcomes.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/family-law/divorce-and-separation"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="jemek__ourpractice-second-card card-animation">
                          <div className="jemek__card-image">
                            <a
                              href="/practice-areas/family-law/financial-settlements"
                              className="jemek__card-link"
                            >
                              <div className="jemek__card-img-wrapper">
                                <img src={settlement_family} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a
                                href="/practice-areas/family-law/financial-settlements"
                                className="jemek__card-link"
                              >
                                <h3 className="jemek__card-data-title">
                                  Financial Settlement
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Financial settlements in divorce proceedings
                                  involve full disclosure of finances,
                                  negotiation, and a final hearing.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/family-law/financial-settlements"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="jemek__ourpractice-third-card card-animation">
                          <div className="jemek__card-image">
                            <a
                              href="/practice-areas/family-law/spousal-maintenance"
                              className="jemek__card-link"
                            >
                              <div className="jemek__card-img-wrapper">
                                <img src={spousal_family} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a
                                href="/practice-areas/family-law/spousal-maintenance"
                                className="jemek__card-link"
                              >
                                <h3 className="jemek__card-data-title">
                                  Spousal Maintenance
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  We offer expert guidance on spousal
                                  maintenance, ensuring fair and appropriate
                                  calculations for both recipients and payers.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/family-law/spousal-maintenance"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="jemek__ourpractice-fourth-card card-animation">
                          <div className="jemek__card-image">
                            <a
                              href="/practice-areas/family-law/financial-division"
                              className="jemek__card-link"
                            >
                              <div className="jemek__card-img-wrapper">
                                <img src={financial_family} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a
                                href="/practice-areas/family-law/financial-division"
                                className="jemek__card-link"
                              >
                                <h3 className="jemek__card-data-title">
                                  Financial Divison
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  We focus on promoting settlements and
                                  providing cost-effective services in cases
                                  involving asset redistribution.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/family-law/financial-division"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="jemek__ourpractice-fifth-card card-animation">
                          <div className="jemek__card-image">
                            <a
                              href="/practice-areas/family-law/civil-partnerships-and-dissolution"
                              className="jemek__card-link"
                            >
                              <div className="jemek__card-img-wrapper">
                                <img src={partnership_family} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a
                                href="/practice-areas/family-law/civil-partnerships-and-dissolution"
                                className="jemek__card-link"
                              >
                                <h3 className="jemek__card-data-title">
                                  Civil Partnerships and Dissolution
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Expert guidance in civil partnership
                                  dissolution, addressing finances and children.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/family-law/civil-partnerships-and-dissolution"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="jemek__ourpractice-sixth-card card-animation">
                          <div className="jemek__card-image">
                            <a
                              href="/practice-areas/family-law/financial-claims"
                              className="jemek__card-link"
                            >
                              <div className="jemek__card-img-wrapper">
                                <img src={claims_family} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a
                                href="/practice-areas/family-law/financial-claims"
                                className="jemek__card-link"
                              >
                                <h3 className="jemek__card-data-title">
                                  Financial Claims For Unmarried Couples
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Guiding unmarried couples through complex
                                  financial claims for fair resolutions.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/family-law/financial-claims"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="jemek__ourpractice-seventh-card card-animation">
                          <div className="jemek__card-image">
                            <a
                              href="/practice-areas/family-law/prenuptial-agreements"
                              className="jemek__card-link"
                            >
                              <div className="jemek__card-img-wrapper">
                                <img src={prenup_family} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a
                                href="/practice-areas/family-law/prenuptial-agreements"
                                className="jemek__card-link"
                              >
                                <h3 className="jemek__card-data-title">
                                  Pre-nuptial Agreements
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Pre-nuptial agreements offer financial
                                  management for marriages, requiring tailored
                                  drafting.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/family-law/prenuptial-agreements"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className='jemek__ourpractice-eighth-card card-animation'>
                            <div className='jemek__card-image'>
    <a href='#' className='jemek__card-link'>
      <div className='jemek__card-img-wrapper'>
        <img src={collaborative_family} alt='#'>

        </img>
      </div>
    </a>
    </div>
    <div className='jemek__data-container'>
    <div className='jemek__card-data'>

    <a href='#' className='jemek__card-link'>
      <h3 className='jemek__card-data-title'>Collaborative Law</h3>
    </a>
    <div className='jemek__card-data-description'>
      <p>
        We are dedicated to providing an innovative approach for more amicable family dispute resolutions.
      </p>
      </div>
      </div>
      <div className='jemek__cards-data-bottom'>
        <div className='jemek__cards-data-bottom-redirect'>
          <div className='jemek__cards-data-redirect-link-wrapper'>
            <a href='#' className='jemek__cards-data-link'>
              <span className='jemek__cards-data-redirect-link'>Learn More</span>
            </a>
          </div>
          </div>
        </div>

        </div>
        
            </div> */}

                        <div className="jemek__ourpractice-nineth-card card-animation">
                          <div className="jemek__card-image">
                            <a
                              href="/practice-areas/family-law/domestic-abuse"
                              className="jemek__card-link"
                            >
                              <div className="jemek__card-img-wrapper">
                                <img src={domestic_family} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a
                                href="/practice-areas/family-law/domestic-abuse"
                                className="jemek__card-link"
                              >
                                <h3 className="jemek__card-data-title">
                                  Domestic Abuse
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Compassionate assistance for domestic abuse
                                  victims, exploring options and ensuring
                                  protection.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/family-law/domestic-abuse"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="jemek__ourpractice-tenth-card card-animation">
                          <div className="jemek__card-image">
                            <a
                              href="/practice-areas/family-law/co-habitation"
                              className="jemek__card-link"
                            >
                              <div className="jemek__card-img-wrapper">
                                <img src={cohabitation_family} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a
                                href="/practice-areas/family-law/co-habitation"
                                className="jemek__card-link"
                              >
                                <h3 className="jemek__card-data-title">
                                  Co-habitation
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Our team offers legal guidance for
                                  cohabitation, covering property disputes,
                                  financial claims, and child support.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/family-law/co-habitation"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="jemek__ourpractice-childcare-components">
                  {toggleChildCareContent && (
                    <div className="jemek__ourpractice-childcare-contents">
                      <div className="jemek__ourpractice-cards-grid">
                        <div className="jemek__ourpractice-first-card card-animation">
                          <div className="jemek__card-image">
                            <a
                              href="/practice-areas/child-care-law/care-and-protection-of-children"
                              className="jemek__card-link"
                            >
                              <div className="jemek__card-img-wrapper">
                                <img
                                  src={childprotection_childcare}
                                  alt="#"
                                ></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a
                                href="/practice-areas/child-care-law/care-and-protection-of-children"
                                className="jemek__card-link"
                              >
                                <h3 className="jemek__card-data-title">
                                  Care and Protection of Children
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Expert guidance on child protection and care
                                  matters, including adoption, and child
                                  abduction cases.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/child-care-law/care-and-protection-of-children"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="jemek__ourpractice-second-card card-animation">
                          <div className="jemek__card-image">
                            <a
                              href="/practice-areas/child-care-law/child-maintenance"
                              className="jemek__card-link"
                            >
                              <div className="jemek__card-img-wrapper">
                                <img src={maintenance_childcare} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a
                                href="/practice-areas/child-care-law/child-maintenance"
                                className="jemek__card-link"
                              >
                                <h3 className="jemek__card-data-title">
                                  Child Maintenance Agreements
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Specialized, tailored support for child
                                  maintenance agreements prioritizing children's
                                  financial welfare.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/child-care-law/child-maintenance"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="jemek__ourpractice-third-card card-animation">
                          <div className="jemek__card-image">
                            <a
                              href="/practice-areas/child-care-law/residence-and-contact"
                              className="jemek__card-link"
                            >
                              <div className="jemek__card-img-wrapper">
                                <img src={residence_childcare} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a
                                href="/practice-areas/child-care-law/residence-and-contact"
                                className="jemek__card-link"
                              >
                                <h3 className="jemek__card-data-title">
                                  Residence and contact
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Expert support for residence and contact
                                  matters prioritizing children's best
                                  interests.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/child-care-law/residence-and-contact"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="jemek__ourpractice-fourth-card card-animation">
                          <div className="jemek__card-image">
                            <a
                              href="/practice-areas/child-care-law/adoptions"
                              className="jemek__card-link"
                            >
                              <div className="jemek__card-img-wrapper">
                                <img src={adoption_family} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a
                                href="/practice-areas/child-care-law/adoptions"
                                className="jemek__card-link"
                              >
                                <h3 className="jemek__card-data-title">
                                  Adoptions
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Personalized, comprehensive legal support for
                                  adoption clients, ensuring positive outcomes
                                  for all involved.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/child-care-law/adoptions"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="jemek__ourpractice-fifth-card card-animation">
                          <div className="jemek__card-image">
                            <a
                              href="/practice-areas/child-care-law/separation-agreements"
                              className="jemek__card-link"
                            >
                              <div className="jemek__card-img-wrapper">
                                <img src={separation_childcare} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a
                                href="/practice-areas/child-care-law/separation-agreements"
                                className="jemek__card-link"
                              >
                                <h3 className="jemek__card-data-title">
                                  Separation Agreements
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Compassionate legal support for separation
                                  agreements, ensuring fair outcomes and stable
                                  futures.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/child-care-law/separation-agreements"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <div
        className="jemek__aboutus section__padding page-section "
        id="aboutus"
      >
   
        <div className="jemek__aboutus-container" ref={refTwo}>
          <div className="jemek__aboutus-row row">
            <div className="jemek__aboutus-column ">
              <div className="jemek__aboutus-item">
                <a className="jemek__about-us__link" href="/who-we-are">
                <img
                  className="jemek__aboutus-image" 
                  src={aboutus_landing2}
                  alt="aboutus"
                />
                </a>

              </div>
            </div>

            <div className="jemek__aboutus-column-two">
              <div className="jemek__aboutus-item">
                <div className="jemek__aboutus__copy-right">
                  <div className="jemek__aboutus-copy-right-muted text-uppercase">
                    <p>About us</p>
                  </div>
                  <div className="jemek__aboutus__copy-right-content center">
                    <h2 className="jemek__aboutus-header-text gradient__text">
                    Experienced Solicitors Committed to {""}
                    <span className="jemek__aboutus-text-highlight-two">
                        success
                      </span> and{" "}
                    <span className="jemek__aboutus-text-highlight-one">
                        excellence
                      </span>{" "} in Legal Practice{" "}
                    </h2>
                    <p className="jemek__about-us__text">
                    Founded in the early 2010s, Jemek Solicitors Limited is a distinguished Birmingham-based legal firm. Our dedication to accessible and personalized legal services, coupled with an exceptional level of care, has bolstered our reputation and fostered strong community ties.
                    </p>
                  </div>
                  <div className="jemek__aboutus-item">
                    <button
                      className="jemek__aboutus-herobtn"
                      type="button"
                      onClick={handleClick}
                    >
                      Find Out More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

 

      </div>

      {/* <div className='jemek__bg'>
  <h1>Discover Jemek Solicitors</h1>
        </div> */}

      <div className="jemek__fees section__padding page-section" id="fees" ref={refThree}>
        <div className="jemek__fees-container container" >
          <div className="jemek__fees-row">
            <div className="jemek__fees-columns">

              <div className="jemek__fees-column-one">
                <h3 className="text-uppercase">
                  Funding Your{" "}
                  <span className="jemek__fees-text-highlight">Case</span>
                </h3>
              </div>
              </div>
              </div>
        </div>

              <div className="jemek__feescard-teaser">
                  <div className="jemek__feescard-teaser-container container">
                    <div className="jemek__feescard-items">
                      <div className="jemek__feescard-item-wrapper">
      
                        <div className="jemek__feescard-one">
                          <div className="card-content__image">
                          <a href="/funding-your-case/legal-aid" className="jemek__feescard-link">
                            <div className="jemek__feescard-img-wrapper">
                              <img src={legalaid_funding} alt="" />
                            </div>
                          </a>
                 
                          </div>

 
                  
                      <div className="jemek__feescard-textsection-wrapper">
                          <div className="jemek__feescard-teaser-text">
                            <h4>Legal Aid</h4>
                            <p>
                            Understanding the financial strain of legal services, we ensure our expertise is accessible. For eligible individuals, Legal Aid is available.{" "}
                            </p>
                          </div>
                          <div className="card-content__text-bottom">
                          <div className="jemek__feescard-button">
                            <Link to="/practice-areas?activeButton=Immigration and Asylum Law">
                              <button
                                className="jemek__feescard-button btn btn-custom-transparent btn-xl"
                                type="button"
                                href="#"
                              >
                                Learn More
                              </button>
                            </Link>
                          </div>
                          </div>
                        </div>
                        </div>
                      </div>

                      <div className="jemek__feescard-item-wrapper">
      
                        <div className="jemek__feescard-two">
                        <div className="card-content__image">
                          <a href="/funding-your-case/fee-paying-clients" className="jemek__feescard-link">
                            <div className="jemek__feescard-img-wrapper">
                              <img className="lazyload" src={feepaying2_funding} alt="Fee Paying Clients" />
                            </div>
                          </a>
                          </div>
     
                        <div className="jemek__feescard-textsection-wrapper">
                          <div className="jemek__feescard-teaser-text">
                          <h4>Fee-paying Clients</h4>
                            <p>
                            Dedicated to transparent pricing, we offer both fixed and hourly rate options. {" "}
                            </p>
                          </div>
                          <div className="card-content__text-bottom">
                          <div className="jemek__feescard-button">
                            <Link to="/practice-areas?activeButton=Immigration and Asylum Law">
                              <button
                                className="jemek__feescard-button btn btn-custom-transparent btn-xl"
                                type="button"
                                href="#"
                              >
                                Learn More
                              </button>
                            </Link>
                          </div>
                          </div>

                        </div>
                        </div>
                      </div>
                      </div>
                      </div>
                  </div>
                  

      </div>


    </div>
  );
};

export default Index;
