import React from 'react';
import { Sralogo } from '../../components';
import logo from '../../assets/jemek-logo-footer.png';

import './footer.css';
 



const Footer = () => {
  return (
      <div>
  <div className="jemek__footer section__padding" id="footer">
    <div className='jemek__footer-container container'>
      <div className='jemek__footer-grid-items'>
      <div className='jemek__footer-grid_logo'>
      <a href='/landing'>
      <img src={logo} alt="logo"/>
      </a>

    </div>

    <div className='jemek__footer-grid'>
      <h4>Resources</h4>
      <ul className='jemek__list'>
      <li><a href="http://www.barcouncil.org.uk/" target="_blank" rel="noopener noreferrer">The Bar Council </a></li>
<li><a href="http://www.lawsociety.org.uk/choosingandusing/findasolicitor.law" target="_blank" rel="noopener noreferrer">The Law Society</a></li>
<li><a href="http://www.metpolicecareers.co.uk/" target="_blank" rel="noopener noreferrer">Metropolitan Police</a></li>
<li><a href="http://www.bia.homeoffice.gov.uk/" target="_blank" rel="noopener noreferrer">UK Border Agency</a></li>
<li><a href="http://www.sra.org.uk/consumers" target="_blank" rel="noopener noreferrer">Solicitors Regulation Authority</a></li>
<li><a href="http://www.hmcourts-service.gov.uk/" target="_blank" rel="noopener noreferrer">Her Majesty's Courts Service</a></li>
<li><a href="http://www.ait.gov.uk/appeals/appealTypes.htm" target="_blank" rel="noopener noreferrer">Immigration &amp; Asylum Tribunal</a></li>
      </ul>
    </div>
    <div className='jemek__footer-grid'>
      <div className='footer-grid__list-header'>
      <h4>Contact Information</h4>
      </div>
      <div className='footer-grid__list-items'>
      <ul className='jemek__list'>
          <li> (0121) 523-5432 </li>
          <li> info@jemeksolicitors.co.uk </li>
          <li>
            Jemek Solicitors Limited
            <br/>
            Branston Court, Branston Street
            <br/>
            Birmingham
            <br/>
            B18 6BA
          </li>
      </ul>
      </div>

      <Sralogo />
      </div>
      </div>

    </div>
    </div>
      </div>



  );
}

export default Footer;
