import React from 'react';
import './residence.css';

import LegalServicesMenuFour from '../../../components/legalservicesmenufour/Legalservicesmenufour';
import TemplateOne from '../../../components/templateone/Templateone';
import HeaderTemplateOne from '../../../components/headertemplate/Headertemplate';


const Residence = () => {



    return (
      <div className="jemek__residence-content" id="business">
      <div className='jemek__residence-header'>
        <HeaderTemplateOne
        main_header="Residence & Contact" />

        </div>

        <div className='jemek__residence-body container'>
          <TemplateOne 
          

          subheader_text="At Jemek Solicitors Limited, we understand that navigating the complexities of residence and contact disputes can be overwhelming, particularly when it involves the welfare and stability of children. Our skilled and compassionate family law solicitors are dedicated to assisting clients in achieving fair and sustainable outcomes that prioritize the best interests of their children."
            
            
          header_one="Tailored Approach to Residence and Contact Matters"
          text_one="Our experienced team recognizes that each family's situation is unique, requiring a tailored approach to residence and contact matters. We take the time to understand our clients' individual needs and circumstances, providing compassionate support and practical advice throughout the process of negotiating and formalizing child residence and contact arrangements."
  

          header_two="Court Proceedings and Advocacy"
          text_two="In cases where parents are unable to reach an amicable agreement on residence and contact, our skilled solicitors are prepared to represent clients in court proceedings. We advocate for our clients' rights and the best interests of their children, striving to achieve fair and sustainable outcomes in line with the children's welfare and stability."
   
          
          header_three="Modification of Existing Orders"
          text_three="Jemek Solicitors Limited also offers expert guidance and representation for clients seeking to modify existing residence and contact orders. We understand that life circumstances can change, and we are dedicated to helping clients navigate these transitions and secure arrangements that continue to serve the best interests of the children involved."


          header_four="Exceptional Service and Personalized Support"
          text_four="At Jemek Solicitors Limited, we pride ourselves on providing exceptional service and personalized support in all matters related to residence and contact. Our commitment to prioritizing the welfare of children and the needs of our clients has made us a trusted partner in addressing these complex and sensitive legal issues."
      

          header_five=""
          text_five=""

          
          />

        </div>



        <LegalServicesMenuFour 
        main_header="More Child Care Law Content"
        
        menuItemId1={1}
        title_one="Child Maintenance Agreements"
        link_one="/practice-areas/child-care-law/child-maintenance" 
        description_one="We provide expert legal services focused on securing fair and sustainable child maintenance agreements for the financial support of children following the separation or divorce of their parents. We offer comprehensive support in negotiating, formalizing, and modifying child maintenance arrangements, with a commitment to personalized and compassionate guidance."
        
        menuItemId2={2}
        title_two="Care & Protection of Children"
        link_two="/practice-areas/child-care-law/care-and-protection-of-children"
        description_two="We provide expert legal services focused on safeguarding the well-being of children in various circumstances, including divorce, custody disputes, adoption, and care proceedings, with a commitment to personalized and compassionate support."
        
        enuItemId3={3}
        title_three="Adoptions"
        link_three="/practice-areas/child-care-law/adoptions" 
        description_three="We specialize in adoption matters, offering personalized and comprehensive legal support to clients pursuing adoptions. Our dedicated team of adoption specialists provides expert representation and guidance to ensure a seamless and positive experience for all parties involved."
        
        menuItemId4={4}
        title_four="Separation Agreements"
        link_four="/practice-areas/child-care-law/separation-agreements" 
        description_four="Jemek Solicitors Limited offers personalized and compassionate legal support for clients seeking separation agreements. Our experienced solicitors work closely with clients to identify and address key issues that need to be resolved. We provide comprehensive legal support throughout the process, expertly negotiating and drafting separation agreements."
        

        />




        </div>

    );
  };
  
  export default Residence;