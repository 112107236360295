import React, {useState} from "react";
import "./reachus.css";

import HeaderTemplateOne from "../../components/headertemplate/Headertemplate";

import {BsTelephone} from 'react-icons/bs';

import {RxEnvelopeClosed} from 'react-icons/rx';

import {GiRotaryPhone} from 'react-icons/gi';

import { IoLocationOutline } from "react-icons/io5";

import useIntersectionObserver from "../../components/intersectionobserver/Intersectionobserver";



const ReachUs = () => {

  const [show, setShow] = useState(false);
  const refOne = useIntersectionObserver(setShow);
  const refTwo = useIntersectionObserver(setShow);
  const refThree = useIntersectionObserver(setShow);

  return (
    <div className="jemek__contactpage-wrapper">
      <section className="jemek__contactpage-title">
        <HeaderTemplateOne main_header="Reach Us"/>
      </section>
      <section className="jemek__contactpage-header section__padding page-section" ref={refOne}>
        <div className="jemek__contactpage-header-container container">
          <div className="jemek__contactpage-header-row row">
            <div className="jemek__contactpage-columnone col-xl-6 col-xl-6 mb-4">
              <div className="jemek__contactpage-column-item">
                <div className="jemek__contactpage-map">
                  <div className="jemek__contactpage-map map_main">
                    <div className="jemek__contactpage-map-responsive map-responsive">
                      <iframe
                      title="responsive google map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2429.340934233247!2d-1.9131239841600436!3d52.49106784612531!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4870bcebedafcc81%3A0x79761281828b0fba!2sJemek%20Solicitors!5e0!3m2!1sen!2suk!4v1680884382673!5m2!1sen!2suk"
                        width="1200"
                        height="800"
                        allowFullScreen={false}
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="jemek__contactpage-columntwo col-xl-6 col-xl-6 mb-4">
              <div className="jemek__contactpage-column-contents">
                <b>Address</b>
                <p>Branston Court, Branston St, Birmingham B18 6BA</p>
                <p>
                  <b><GiRotaryPhone
                  size={24}/> : </b>
                     0121 523 5432
                  <br />
                  <b className="fs-16"><BsTelephone 
                    size={24}/> : </b>
                  +44 7478 245540
                  <br />
                  <b className="fs-16"><RxEnvelopeClosed
                  size={24} /> : </b>
                  info@jemeksolicitors.co.uk
                  <br />
                </p>
                <b>Opening Hours</b>
                <table className="table table-sm table-borderless">
                  <tbody>
                    <tr>
                      <td>Monday - Friday</td>
                      <td>9:00 - 17:00</td>
                    </tr>
                    <tr>
                      <td>Saturday - Sunday</td>
                      <td>Closed</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="jemek__contactpage-locations page-section section__padding" ref={refTwo}>
        <div className="jemek__contactpage-locations-container container">

  
        <div className="jemek__contactpage-location-header">
          <h2>Nearby Places</h2>
        </div>
        <div className="jemek__contactpage-location-wrapper">
          <div className="jemek__contactpage-location-items">
            <div className="jemek__contactpage-location">
              <IoLocationOutline size={44} color="#5997e9" />
              <div className="jemek__location-text">
                <div className="jemek__contactpage-location-text">
                  Jewellery Quarter
                </div>
                <div className="jemek__contactpage-location-texttwo">
                  0.1 miles
                </div>
              </div>
            </div>
          </div>

          <div className="jemek__contactpage-location-items">
            <div className="jemek__contactpage-location">
              <IoLocationOutline size={44} color="#5997e9" />
              <div className="jemek__location-text">
                <div className="jemek__contactpage-location-text">
                  Snow Hill Station
                </div>
                <div className="jemek__contactpage-location-texttwo">
                  0.8 miles
                </div>
              </div>
            </div>
          </div>

          <div className="jemek__contactpage-location-items">
            <div className="jemek__contactpage-location">
              <IoLocationOutline size={44} color="#5997e9" />
              <div className="jemek__location-text">
                <div className="jemek__contactpage-location-text">
                  The University Of Law
                </div>
                <div className="jemek__contactpage-location-texttwo">
                  0.2 miles
                </div>
              </div>
            </div>
          </div>

          <div className="jemek__contactpage-location-items">
            <div className="jemek__contactpage-location">
                <IoLocationOutline size={44} color="#5997e9" />

              <div className="jemek__location-text">
                <div className="jemek__contactpage-location-text">
                  St. Chad's Cathedral
                </div>
                <div className="jemek__contactpage-location-texttwo">
                  0.7 miles
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>

      <section className="jemek__contactpage-findus section__padding page-section" ref={refThree}>
        <div className="jemek__contactpage-findus-container container">


        <div className="jemek__contactpage-findus-header">
          <h2> Find Us</h2>
        </div>
        <div className="jemek__contactpage-finds-items">
          <h3>Car</h3>
          <p>
            If you are using a sat nav, just endter your post code B18 6BA into
            your satnav. There is on-street parking available outside our
            offices on Vyse Street, with a two-hour time limit.
          </p>
          <p>
            Alternatively, there is alot a pay multi-storey car park at
            Jewellery Quarter Multi Storey which is a 6 minute walk away from
            our office.
          </p>
        </div>
        <div className="jemek__contactpage-finds-items">
          <h3>Train and Tram</h3>
          <p>
            Jewellery quarter train and tram station is a four minute walk waway
            from our office.
          </p>
        </div>

        <div className="jemek__contactpage-finds-items">
          <h3>Bus</h3>
          <p>
            Bus stop MS8a is just outside our office on Vyse street and the
            buses 16 and 74 stop here. Buses can also be caught on Colmore Row
            and directly Ouside the Birmingham Street Market.
          </p>
        </div>

        <div className="jemek__contactpage-finds-items">
          <h3>Bicycle</h3>
          <p>
            Our Offices are within cycling distance via Newhall Street cycling
            route or via the B4100. It takes approzimately 10minutes to cycle to
            our offices via these routes from New Street station. On-street
            parking is available on Vyse Street close ot the train and tram
            station.{" "}
          </p>
        </div>
        </div>
      </section>
    </div>
  );
};

export default ReachUs;
