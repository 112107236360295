import React, { useState, useEffect } from "react";
import "./navbar.css";

import {
  MainMobMenu,
  AboutUsMobMenu,
  PracticeAreasMobMenu,
  ImmigrationMobMenu,
  FamilyLawMobMenu,
  ChildCareLawMobMenu,
  FundingYourCaseMobMenu,
} from "../mobnavbar/Mobnavbar";

import { RiCloseLine } from "react-icons/ri";
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineRight } from "react-icons/ai";
import logo from "../../assets/jemek-logo.png";
import BreadCrumbs from "../breadcrumbs/Breadcrumbs";
import _ from "lodash";

const NavBar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);

  const [thirdLevelSubMenuActive, setThirdLevelSubMenuActive] = useState(false);

  const [toggleDropDownOverlay, setToggleDropDownOverlay] = useState(false);

  const [toggleAboutUsSubmenu, setToggleAboutUsSubMenu] = useState(false);
  const [togglePracticeAreasSubMenu, setTogglePracticeAreasSubMenu] =
    useState(false);
  const [toggleImmigrationAsylumSubMenu, setToggleImmigrationAsylumSubMenu] =
    useState(false);
  const [toggleFamilyLawSubMenu, setToggleFamilyLawSubMenu] = useState(false);
  const [toggleChildCareLawSubMenu, setToggleChildCareLawSubMenu] =
    useState(false);
  const [toggleTeaser, setToggleTeaser] = useState(false);

  //Mobile Nav useState code //

  const [currentMobMenu, setCurrentMobMenu] = useState("MainMobMenu");

  const [toggleMobMenu, setToggleMobMenu] = useState(false);
  const [toggleMobNavMainSubMenu, setToggleMobNavMainSubMenu] = useState(false);

  useEffect(() => {
    const handleNavScrollState = _.debounce(() => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    }, 70);

    window.addEventListener("scroll", handleNavScrollState);
    return () => {
      window.removeEventListener("scroll", handleNavScrollState);
    };
  }, []);

  useEffect(() => {
    if (!toggleMenu) {
      setIsHovered(false);
      setToggleAboutUsSubMenu(false);
      setTogglePracticeAreasSubMenu(false);
      setToggleImmigrationAsylumSubMenu(false);
      setToggleFamilyLawSubMenu(false);
      setToggleTeaser(false);
    }
  }, [toggleMenu]);

  const handleDropDownOverlay = (visible) => {
    setToggleDropDownOverlay(visible);
  };

  const handleTeaserHover = (teaserId) => (e) => {
    e.stopPropagation();
    e.preventDefault();
    setToggleTeaser(teaserId);
    setToggleFamilyLawSubMenu(false);
    setToggleImmigrationAsylumSubMenu(false);
    setToggleChildCareLawSubMenu(false); // stops the child care law sidemenu from displaying when the teaser is active
    setToggleDropDownOverlay(false);
  };

  const handleMouseEnter = (hoverId) => (e) => {
    setIsHovered(hoverId);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleTeaserAndMouseEnter = (teaserId, hoverId) => (e) => {
    if (!thirdLevelSubMenuActive) {
      if (e.target.tagName !== "svg" && e.target.tagName !== "path") {
        handleTeaserHover(teaserId)(e);
        handleMouseEnter(hoverId)(e);
        setToggleDropDownOverlay(false);
      }
    }
  };

  const handleAboutUsSubMenuClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setToggleAboutUsSubMenu(!toggleAboutUsSubmenu);
    setTogglePracticeAreasSubMenu(false); // Stops the display of the "Practice Areas" submenu  when "About Us" is clicked. Stops the display of both items simultaneously.
    setToggleDropDownOverlay(false);
    if (!toggleAboutUsSubmenu) {
      setToggleTeaser(1); // Set the toggleTeaser state to 3 when opening the "Practice Areas" submenu
      setIsHovered(3);
    } else {
      setToggleDropDownOverlay(true);
      setToggleTeaser(false); // Reset the toggleTeaser state when closing the "Practice Areas" submenu
    }
  };

  const handlePracticeAreasSubMenuClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setTogglePracticeAreasSubMenu(!togglePracticeAreasSubMenu);
    setToggleAboutUsSubMenu(false); // Stops the display of the "About Us" submenu when "Practice Areas" is clicked. Stops the display of both items simultaneously.
    setToggleDropDownOverlay(false);
    if (!togglePracticeAreasSubMenu) {
      setToggleTeaser(3); // Set the toggleTeaser state to 3 when opening the "Practice Areas" submenu
      setIsHovered(6);
      setToggleImmigrationAsylumSubMenu(false); // The point of this line is to stop the Submenus from displaying after Practice Areas has been opened, closed and reopened. Take it off and see."
      setToggleFamilyLawSubMenu(false);
      setToggleChildCareLawSubMenu(false);
    } else {
      setToggleDropDownOverlay(true);
      setToggleTeaser(false); // Reset the toggleTeaser state when closing the "Practice Areas" submenu.
    }
  };

  const handleImmigrationAsylumSubMenuClick = (e) => {
    e.preventDefault(toggleTeaser);
    e.stopPropagation();
    setToggleImmigrationAsylumSubMenu(!toggleImmigrationAsylumSubMenu);
    setToggleFamilyLawSubMenu(false);
    setToggleChildCareLawSubMenu(false);
    setToggleTeaser(false);
    setThirdLevelSubMenuActive(!toggleImmigrationAsylumSubMenu);
  };

  const handleFamilyLawSubMenuClick = (e) => {
    e.preventDefault(toggleTeaser);
    e.stopPropagation();
    setToggleFamilyLawSubMenu(!toggleFamilyLawSubMenu);
    setToggleImmigrationAsylumSubMenu(false);
    setToggleChildCareLawSubMenu(false);
    setToggleTeaser(false);
  };

  const handleChildCareLawSubMenuClick = (e) => {
    e.preventDefault(toggleTeaser);
    e.stopPropagation();
    setToggleImmigrationAsylumSubMenu(false);
    setToggleTeaser(false);
    setToggleFamilyLawSubMenu(false);
    setToggleChildCareLawSubMenu(!toggleChildCareLawSubMenu);
  };



  return (
    <div className={`jemek__navbar ${isScrolled ? "scrolled" : ""}`}>
      <div className="jemek__navbar-links">
        <div className="jemek__navbar-links_logo">
          <a href="/home">
            <img src={logo} alt="logo" />
          </a>
        </div>

        <div
          className="jemek__navbar-menu element.style"
          style={{ width: "5vmin", height: "7vmin" }}
        >
          {toggleMenu && toggleMobMenu ? (
            <RiCloseLine
              color="#000"
              size={32}
              style={{ width: "100%", height: "auto" }}
              onClick={() => {
                setToggleMenu(false);
                setToggleMobMenu(false);
                handleDropDownOverlay(false);
                setCurrentMobMenu("MainMobMenu");
              }}
            />
          ) : (
            <RxHamburgerMenu
              color="#000"
              size={32}
              style={{ width: "100%", height: "auto" }}
              onClick={() => {
                setToggleMenu(true);
                setToggleMobMenu(true);
                setToggleMobNavMainSubMenu(true);
                handleDropDownOverlay(true);
                setIsHovered(false);
              }}
            />
          )}
          {toggleMenu && (
            <div className="jemek__navbar-container scale-up-center">
              <nav className="jemek__navbar-header lv-0">
                <div className="jemek__navbar-item lv-0">
                  <a
                    href="/home"
                    className="jemek__navbar-item__link"
                    onMouseEnter={handleMouseEnter(1)}
                    onMouseLeave={handleMouseLeave}
                    style={{
                      color: isHovered === 1 ? "grey" : "black",
                    }}
                  >
                    Home
                  </a>
                </div>

                <div className="jemek__navbar-item lv-0">
                  <button
                    className="jemek__navbar-item__link"
                    onClick={handleAboutUsSubMenuClick}
                    onMouseEnter={handleMouseEnter(2)}
                    onMouseLeave={handleMouseLeave}
                    style={{
                      color: toggleAboutUsSubmenu
                        ? "grey"
                        : isHovered === 2
                        ? "grey"
                        : "black",

                      background: "none",
                      border: "none",
                    }}
                  >
                    About Us{" "}
                    <i className="jemek__navbar-iconright">
                      <AiOutlineRight />
                    </i>
                  </button>

                  {toggleAboutUsSubmenu && (
                    <nav className="jemek__navbar-header lv-1">
                      <div className="jemek__navbar-header__inner">
                        <div className="jemek__navbar-item lv-1">
                          <a
                            href="/who-we-are"
                            className="jemek__navbar-item__link"
                            onMouseEnter={handleTeaserAndMouseEnter(1, 3)}
                            onMouseLeave={handleMouseLeave}
                            style={{
                              color: isHovered === 3 ? "grey" : "black",
                            }}
                          >
                            Overview
                          </a>
                        </div>
                        {toggleTeaser === 1 && (
                          <nav className="jemek__navbar-header lv-2">
                            <div className="jemek__navbar-submenu_teaser">
                              <div className="jemek__navbar-submenu_teaser-bg">
                                <h1> Overview </h1>
                                <p>
                                  At Jemek Solicitors Limited, we take a
                                  client-centred and personal approach to the
                                  practice of law. We want to get to know you,
                                  your business and your family, in order to
                                  best serve you.{" "}
                                </p>
                              </div>
                            </div>
                          </nav>
                        )}

                        <div className="jemek__navbar-item lv-1">
                          <a
                            href="/our-team"
                            className="jemek__navbar-item__link"
                            onMouseEnter={handleTeaserAndMouseEnter(2, 4)}
                            onMouseLeave={handleMouseLeave}
                            style={{
                              color: isHovered === 4 ? "grey" : "black",
                            }}
                          >
                            Our Team
                          </a>
                        </div>
                        {toggleTeaser === 2 && (
                          <nav className="jemek__navbar-header lv-2">
                            <div className="jemek__navbar-submenu_teaser">
                              <div className="jemek__navbar-submenu_teaser-bg">
                                <h1> Our Team </h1>
                                <p>
                                  At Jemek Solicitors Limited, we take a
                                  client-centred and personal approach to the
                                  practice of law. We want to get to know you,
                                  your business and your family, in order to
                                  best serve you.
                                </p>
                              </div>
                            </div>
                          </nav>
                        )}
                      </div>
                    </nav>
                  )}
                </div>

                <div className="jemek__navbar-item lv-0">
                  <button
                    className="jemek__navbar-item__link"
                    onClick={handlePracticeAreasSubMenuClick}
                    onMouseEnter={handleMouseEnter(5)}
                    onMouseLeave={handleMouseLeave}
                    style={{
                      color: togglePracticeAreasSubMenu
                        ? "grey"
                        : isHovered === 5
                        ? "grey"
                        : "black",
                      background: "none",
                      border: "none",
                    }}
                  >
                    Practice Areas{" "}
                    <i className="jemek__navbar-iconright">
                      <AiOutlineRight />
                    </i>
                  </button>
                </div>

                {togglePracticeAreasSubMenu && (
                  <nav className="jemek__navbar-header lv-1">
                    <div className="jemek__navbar-header__inner">
                      <div className="jemek__navbar-item lv-1">
                        <a
                          href="/practice-areas"
                          className="jemek__navbar-item__link"
                          onMouseEnter={handleTeaserAndMouseEnter(3, 6)}
                          onMouseLeave={handleMouseLeave}
                          style={{
                            color: isHovered === 6 ? "grey" : "black",
                          }}
                        >
                          Overview
                        </a>
                      </div>

                      {toggleTeaser === 3 && (
                        <nav className="jemek__navbar-header lv-2">
                          <div className="jemek__navbar-submenu_teaser">
                            <div className="jemek__navbar-submenu_teaser-bg">
                              <h1> Overview </h1>
                              <p>
                                At Jemek Solicitors Limited, we take a
                                client-centred and personal approach to the
                                practice of law. We want to get to know you,
                                your business and your family, in order to best
                                serve you.
                              </p>
                            </div>
                          </div>
                        </nav>
                      )}

                      <div className="jemek__navbar-item lv-1">
                        <button
                          className="jemek__navbar-item__link"
                          onClick={handleImmigrationAsylumSubMenuClick}
                          onMouseEnter={handleTeaserAndMouseEnter(4, 7)}
                          onMouseLeave={handleMouseLeave}
                          style={{
                            color: toggleImmigrationAsylumSubMenu
                              ? "grey"
                              : isHovered === 7
                              ? "grey"
                              : "black",
                            background: "none",
                            border: "none",
                          }}
                        >
                          Immigration Law{" "}
                          <i className="jemek__navbar-iconright">
                            <AiOutlineRight />
                          </i>
                        </button>
                      </div>
                      {toggleTeaser === 4 && (
                        <nav className="jemek__navbar-header lv-2">
                          <div className="jemek__navbar-submenu_teaser">
                            <div className="jemek__navbar-submenu_teaser-bg">
                              <h1> Immigration and Asylum Law </h1>
                              <p>
                                Our experienced and highly ranked team advises
                                both corporate and private clients, including
                                major international organisations, entrepreneurs
                                and investors coming to live and work in the UK,
                                on the routes of immigration available to them
                                and on the UK’s Points Based System.
                              </p>
                            </div>
                          </div>
                        </nav>
                      )}
                      {toggleImmigrationAsylumSubMenu && (
                        <nav className="jemek__navbar-header lv-2">
                          <div className="jemek__navbar-header__inner">
                            <div className="jemek__navbar-item lv-2">
                              <a
                                href="/practice-areas/immigration-law/business-immigration"
                                className="jemek__navbar-item__link"
                                onMouseEnter={handleMouseEnter(8)}
                                onMouseLeave={handleMouseLeave}
                                style={{
                                  color: isHovered === 8 ? "grey" : "black",
                                }}
                              >
                                Business Immigration
                              </a>
                            </div>

                            <div className="jemek__navbar-item lv-2">
                              <a
                                href="/practice-areas/immigration-law/asylum-and-human-rights"
                                className="jemek__navbar-item__link"
                                onMouseEnter={handleMouseEnter(9)}
                                onMouseLeave={handleMouseLeave}
                                style={{
                                  color: isHovered === 9 ? "grey" : "black",
                                }}
                              >
                                Asylum & Human Rights
                              </a>
                            </div>

                            <div className="jemek__navbar-item lv-2">
                              <a
                                href="practice-areas/immigration-law/personal-and-family"
                                className="jemek__navbar-item__link"
                                onMouseEnter={handleMouseEnter(10)}
                                onMouseLeave={handleMouseLeave}
                                style={{
                                  color: isHovered === 10 ? "grey" : "black",
                                }}
                              >
                                Personal & family Immigration
                              </a>
                            </div>

                            <div className="jemek__navbar-item lv-2">
                              <a
                                href="/practice-areas/immigration-law/eea-citizens-and-family"
                                className="jemek__navbar-item__link"
                                onMouseEnter={handleMouseEnter(11)}
                                onMouseLeave={handleMouseLeave}
                                style={{
                                  color: isHovered === 11 ? "grey" : "black",
                                }}
                              >
                                EEA Citizens & Family
                              </a>
                            </div>

                            <div className="jemek__navbar-item lv-2">
                              <a
                                href="practice-areas/immigration-law/deportation-and-detention"
                                className="jemek__navbar-item__link"
                                onMouseEnter={handleMouseEnter(12)}
                                onMouseLeave={handleMouseLeave}
                                style={{
                                  color: isHovered === 12 ? "grey" : "black",
                                }}
                              >
                                Deportation & Detention
                              </a>
                            </div>
                          </div>
                        </nav>
                      )}

                      <div className="jemek__navbar-item lv-1">
                        <button
                          className="jemek__navbar-item__link"
                          onClick={handleFamilyLawSubMenuClick}
                          onMouseEnter={handleTeaserAndMouseEnter(5, 13)}
                          onMouseLeave={handleMouseLeave}
                          style={{
                            color: toggleFamilyLawSubMenu
                              ? "grey"
                              : isHovered === 13
                              ? "grey"
                              : "black",
                            background: "none",
                            border: "none",
                          }}
                        >
                          {" "}
                          Family and Divorce Law{" "}
                          <i className="jemek__navbar-iconright">
                            <AiOutlineRight />
                          </i>
                        </button>
                      </div>
                      {toggleTeaser === 5 && (
                        <nav className="jemek__navbar-header lv-2">
                          <div className="jemek__navbar-submenu_teaser">
                            <div className="jemek__navbar-submenu_teaser-bg">
                              <h1> Family and Divorce Law </h1>
                              <p>
                                Be it divorce, dissolution of civil partnership,
                                financial provision, matters concerning
                                children, Non Molestation Injunctions, or any
                                other Family Law related matter, we offer
                                detailed, considerate and helpful advice.
                              </p>
                            </div>
                          </div>
                        </nav>
                      )}
                      {toggleFamilyLawSubMenu && (
                        <nav className="jemek__navbar-header lv-2">
                          <div className="jemek__navbar-header__inner">
                            <div className="jemek__navbar-item lv-2">
                              <a
                                href="/practice-areas/family-law/divorce-and-separation"
                                className="jemek__navbar-item__link"
                                onMouseEnter={handleMouseEnter(14)}
                                onMouseLeave={handleMouseLeave}
                                style={{
                                  color: isHovered === 14 ? "grey" : "black",
                                }}
                              >
                                Divorce and Separation
                              </a>
                            </div>

                            <div className="jemek__navbar-item lv-2">
                              <a
                                href="/practice-areas/family-law/financial-settlements"
                                className="jemek__navbar-item__link"
                                onMouseEnter={handleMouseEnter(15)}
                                onMouseLeave={handleMouseLeave}
                                style={{
                                  color: isHovered === 15 ? "grey" : "black",
                                }}
                              >
                                Financial Settlement
                              </a>
                            </div>

                            <div className="jemek__navbar-item lv-2">
                              <a
                                href="/practice-areas/family-law/spousal-maintenance"
                                className="jemek__navbar-item__link"
                                onMouseEnter={handleMouseEnter(16)}
                                onMouseLeave={handleMouseLeave}
                                style={{
                                  color: isHovered === 16 ? "grey" : "black",
                                }}
                              >
                                Spousal Maintenance
                              </a>
                            </div>

                            <div className="jemek__navbar-item lv-2">
                              <a
                                href="/practice-areas/family-law/financial-division"
                                className="jemek__navbar-item__link"
                                onMouseEnter={handleMouseEnter(17)}
                                onMouseLeave={handleMouseLeave}
                                style={{
                                  color: isHovered === 17 ? "grey" : "black",
                                }}
                              >
                                Financial Division
                              </a>
                            </div>

                            <div className="jemek__navbar-item lv-2">
                              <a
                                href="/practice-areas/family-law/civil-partnerships-and-dissolution"
                                className="jemek__navbar-item__link"
                                onMouseEnter={handleMouseEnter(18)}
                                onMouseLeave={handleMouseLeave}
                                style={{
                                  color: isHovered === 18 ? "grey" : "black",
                                }}
                              >
                                Civil Partnerships & Dissolution
                              </a>
                            </div>

                            <div className="jemek__navbar-item lv-2">
                              <a
                                href="/practice-areas/family-law/financial-claims"
                                className="jemek__navbar-item__link"
                                onMouseEnter={handleMouseEnter(19)}
                                onMouseLeave={handleMouseLeave}
                                style={{
                                  color: isHovered === 19 ? "grey" : "black",
                                }}
                              >
                                Financial Claims (Unmarried)
                              </a>
                            </div>
                            <div className="jemek__navbar-item lv-2">
                              <a
                                href="/practice-areas/family-law/prenuptial-agreements"
                                className="jemek__navbar-item__link"
                                onMouseEnter={handleMouseEnter(20)}
                                onMouseLeave={handleMouseLeave}
                                style={{
                                  color: isHovered === 20 ? "grey" : "black",
                                }}
                              >
                                Pre-nuptial Agreements
                              </a>
                            </div>
                            <div className="jemek__navbar-item lv-2">
                              <a
                                href="/practice-areas/family-law/domestic-abuse"
                                className="jemek__navbar-item__link"
                                onMouseEnter={handleMouseEnter(21)}
                                onMouseLeave={handleMouseLeave}
                                style={{
                                  color: isHovered === 21 ? "grey" : "black",
                                }}
                              >
                                Domestic Abuse
                              </a>
                            </div>

                            <div className="jemek__navbar-item lv-2">
                              <a
                                href="/practice-areas/family-law/co-habitation"
                                className="jemek__navbar-item__link"
                                onMouseEnter={handleMouseEnter(22)}
                                onMouseLeave={handleMouseLeave}
                                style={{
                                  color: isHovered === 22 ? "grey" : "black"
                                }}
                              >
                                Cohabitation
                              </a>
                            </div>
                          </div>
                        </nav>
                      )}

                      <div className="jemek__navbar-item lv-1">
                        <button
                          className="jemek__navbar-item__link"
                          onClick={handleChildCareLawSubMenuClick}
                          onMouseEnter={handleTeaserAndMouseEnter(6, 23)}
                          onMouseLeave={handleMouseLeave}
                          style={{
                            color: toggleChildCareLawSubMenu
                              ? "grey"
                              : isHovered === 23
                              ? "grey"
                              : "black",
                            background: "none",
                            border: "none",
                          }}
                        >
                          Child Care Law{" "}
                          <i className="jemek__navbar-iconright">
                            <AiOutlineRight />
                          </i>
                        </button>
                      </div>

                      {toggleTeaser === 6 && (
                        <nav className="jemek__navbar-header lv-2">
                          <div className="jemek__navbar-submenu_teaser">
                            <div className="jemek__navbar-submenu_teaser-bg">
                              <h1> Child Care Law </h1>
                              <p>
                                At Jemek Solicitors, we provide expert advice
                                and representation to individuals and families
                                involved in child protection, custody, and care
                                proceedings. Our dedicated team priorities the
                                welfare of children, offering comprehensive
                                legal support to help navigate the complexities
                                involved with their individual cases.
                              </p>
                            </div>
                          </div>
                        </nav>
                      )}
                      {toggleChildCareLawSubMenu && (
                        <nav className="jemek__navbar-header lv-2">
                          <div className="jemek__navbar-header__inner">
                            <div className="jemek__navbar-item lv-2">
                              <a
                                href="/practice-areas/child-care-law/care-and-protection-of-children"
                                className="jemek__navbar-item__link"
                                onMouseEnter={handleMouseEnter(24)}
                                onMouseLeave={handleMouseLeave}
                                style={{
                                  color: isHovered === 24 ? "grey" : "black",
                                }}
                              >
                                Care & Protection of Children
                              </a>
                            </div>

                            <div className="jemek__navbar-item lv-2">
                              <a
                                href="/practice-areas/child-care-law/child-maintenance"
                                className="jemek__navbar-item__link"
                                onMouseEnter={handleMouseEnter(25)}
                                onMouseLeave={handleMouseLeave}
                                style={{
                                  color: isHovered === 25 ? "grey" : "black",
                                }}
                              >
                                Child Maintenance Agreements
                              </a>
                            </div>

                            <div className="jemek__navbar-item lv-2">
                              <a
                                href="/practice-areas/child-care-law/residence-and-contact"
                                className="jemek__navbar-item__link"
                                onMouseEnter={handleMouseEnter(26)}
                                onMouseLeave={handleMouseLeave}
                                style={{
                                  color: isHovered === 26 ? "grey" : "black",
                                }}
                              >
                                Residence & Contact
                              </a>
                            </div>

                            <div className="jemek__navbar-item lv-2">
                              <a
                                href="/practice-areas/child-care-law/adoptions"
                                className="jemek__navbar-item__link"
                                onMouseEnter={handleMouseEnter(27)}
                                onMouseLeave={handleMouseLeave}
                                style={{
                                  color: isHovered === 27 ? "grey" : "black",
                                }}
                              >
                                Adoptions
                              </a>
                            </div>

                            <div className="jemek__navbar-item lv-2">
                              <a
                                href="/practice-areas/child-care-law/separation-agreements"
                                className="jemek__navbar-item__link"
                                onMouseEnter={handleMouseEnter(28)}
                                onMouseLeave={handleMouseLeave}
                                style={{
                                  color: isHovered === 28 ? "grey" : "black",
                                }}
                              >
                                Separation Agreements
                              </a>
                            </div>
                          </div>
                        </nav>
                      )}
                    </div>
                  </nav>
                )}

                <div className="jemek__navbar-item lv-0">
                  <a
                    href="/funding-your-case"
                    className="jemek__navbar-item__link"
                    onMouseEnter={handleMouseEnter(29)}
                    onMouseLeave={handleMouseLeave}
                    style={{
                      color: isHovered === 29 ? "grey" : "black",
                    }}
                  >
                    Funding Your Case
                  </a>
                </div>

                <div className="jemek__navbar-item lv-0">
                  <a
                    href="/reach-us"
                    className="jemek__navbar-item__link"
                    onMouseEnter={handleMouseEnter(30)}
                    onMouseLeave={handleMouseLeave}
                    style={{
                      color: isHovered === 30 ? "grey" : "black",
                    }}
                  >
                    Reach Us
                  </a>
                </div>
                {/* 
                <div className="jemek__navbar-item__external">
                <div className="jemek__navbar-item external lv-0">
                  
                  <a
                    href="/contact-page"
                    className="jemek__navbar-item__link external"
                    onMouseEnter={handleMouseEnter(31)}
                    style={{
                      color: isHovered === 31 ? "grey" : "black",
                    }}
                  >
                    <img className="jemek__navbar-item__img"src={lexcel}></img>
                    Lexcel 
                  </a>
               
              </div>

              <div className="jemek__navbar-item external lv-0">
                  
                  <a
                    href="/contact-page"
                    className="jemek__navbar-item__link external"
                    onMouseEnter={handleMouseEnter(32)}
                    style={{
                      color: isHovered === 32 ? "grey" : "black",
                    }}
                  >
                    Sra
                  </a>
               
              </div>
                </div>
                  */}
              </nav>

              {toggleDropDownOverlay && (
                <div className="jemek__navbar-submenu_teaser-conditions">
                  <div className="jemek__navbar-submenu_teaser-main">
                    <div className="jemek__navbar-submenu_teaser-bg0"></div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        {toggleMobMenu && (
          <aside className="jemek__mobile-navbar scale-up-center">
            <div className="jemek__mobile-navbar__inner">
              <div className="jemek__mobile-navbar__top">
                {/*
                  <div className="jemek__mobile-navbar__top-button-container">
                    <button
                    className="jemek__mobile-navbar__top-button"
                    onClick={handleMobNavButton}>
                     <AiOutlineLeft className="jemek__mobile-navbar__top-icon-left"/> Back
                    </button>
                  </div>
                  */}
              </div>
              <div className="jemek__mobile-navbar__body">
                <nav className="jemek__mobile-nav">
                  {currentMobMenu === "MainMobMenu" && (
                    <MainMobMenu changeMenu={setCurrentMobMenu} />
                  )}
                  {currentMobMenu === "AboutUsMobMenu" && (
                    <AboutUsMobMenu changeMenu={setCurrentMobMenu} />
                  )}
                  {currentMobMenu === "PracticeAreasMobMenu" && (
                    <PracticeAreasMobMenu changeMenu={setCurrentMobMenu} />
                  )}
                  {currentMobMenu === "ImmigrationMobMenu" && (
                    <ImmigrationMobMenu changeMenu={setCurrentMobMenu} />
                  )}
                  {currentMobMenu === "FamilyLawMobMenu" && (
                    <FamilyLawMobMenu changeMenu={setCurrentMobMenu} />
                  )}
                  {currentMobMenu === "ChildCareLawMobMenu" && (
                    <ChildCareLawMobMenu changeMenu={setCurrentMobMenu} />
                  )}
                  {currentMobMenu === "FundingYourCaseMobMenu" && (
                    <FundingYourCaseMobMenu changeMenu={setCurrentMobMenu} />
                  )}
                </nav>
              </div>
            </div>
          </aside>
        )}

        {toggleMenu && (
          <div
            className="jemek__navbar-menu_overlay"
            onClick={() => setToggleMenu(false)}
          >
            <div
              className="jemek__navbar-menu_overlay-content"
              onClick={(e) => e.stopPropagation()}
            ></div>
          </div>
        )}

        {isScrolled && (
          <div className="jemek__navbar-breadcrumbs">
            <BreadCrumbs></BreadCrumbs>
          </div>
        )}

        <div className="jemek__navbar-sign">
          <button
            className={`jemek__navbar-sign ${
              isScrolled ? "scrolled-button" : ""
            }`}
            type="button"
            onClick={() => {
              setToggleMenu(false);
              setToggleMobMenu(false);
              window.location = "#contact-form";
            }}
          >
            Consult a Solicitor
          </button>
        </div>
      </div>

      {!isScrolled && !toggleMenu && (
        <>
          <div className="jemek__navbar__bottom-wrapper">
            <div className="jemek__navbar-extracontent">
              <div className="jemek__navbar-breadcrumbs-hidden">
                <BreadCrumbs></BreadCrumbs>
              </div>
            </div>
            <div className="jemek__navbar-item__cookies-policy">
              <a
                href="/policy-and-terms"
                className="jemek__navbar-cookies__link"
              >
                Learn About Our Use of Cookies
              </a>
            </div>
          </div>
        </>
      )}
      {/*
      {!isScrolled && !toggleMenu && toggleMobMenu && (
                <><div className="jemek__navbar-extracontent">
                <div className="jemek__navbar-breadcrumbs-hidden">
                  <BreadCrumbs></BreadCrumbs>
                </div>
              </div><div className="jemek__navbar-item__cookies-policy">
                  <a href="/policy-and-terms" className="jemek__navbar-cookies__link">
                    Learn About Our Use of Cookies
                  </a>
                </div></>
      )}
       */}
    </div>
  );
};

export default NavBar;
