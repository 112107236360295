import {React, useEffect, useRef} from 'react';
import './intersectionobserver.css';
import 'intersection-observer'

function useIntersectionObserver(setShow) {
    const ref =useRef();

    useEffect (() => {
        
        let currentRef = ref.current;
        if ('IntersectionObserver' in window) {
            const observer = new IntersectionObserver (
                ([entry]) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("show");
                        setShow(entry.isIntersecting);
                        observer.unobserve(entry.target)
                    } else {
                        entry.target.classList.remove("show");
                    }
                  
                },
                {
                    root: null,
                    rootMargin: "0px",
                    threshold: 0.1,
                }
        );
    
        if(currentRef) {
            observer.observe(currentRef);
        }
    
    
        return () => { 
            observer.unobserve(currentRef);
        };
    } else {
        if (currentRef) {
            currentRef.classList.add("show");
            setShow(true)
        }
    }
    
        }, [setShow]);
    
        

    return ref;
}

export default useIntersectionObserver;