import React from "react";
import './termsofbusiness.css'

const TermsOfBusiness = () => {
    return (
        <div className="jemek__terms-of-business">
            
        </div>

    )
}

export default TermsOfBusiness