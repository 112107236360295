import React from 'react';
import './cookiesinfo.css';



const CookiesInfo = () => {

    return (
        <div className='jemek__cookies-info'>
            <section className='jemek__cookies-info__section-header section__padding'>
                <div className='jemek__cookies-info__container container'>
                    <div className='jemek__cookies-info__header'></div>
                </div>
            </section>
            <section className='jemek__cookies-info__section-body section__padding'>
                <div className='jemek__cookies-info__body-container container'>
                <div className='jemek__cookies-info__body-row row'>

                <div className='jemek__cookies-info__content'>
                    <section className='jemek__cookies-info__content-wrapper'>
                    <div className='jemek__cookies-info__title'>
                        <h2 className='jemek__cookies-info__h2'>
                            Privacy Policy
                            </h2>
                    </div>
                    <div className='jemek__cookies-info__paragraph'>
                        <p className='jemek__cookies-info__p'>
                        How We Respect Your Privacy and Handle Your Personal Information
                        </p>
                        <p className='jemek__cookies-info__p'>
                        This Privacy Policy applies to information that we, Jemek Solicitors, collect about individuals who interact with our organisation. It details the personal information we collect and how we utilise it. Should you have any comments or questions about this notice, please feel free to contact us at info@jemeksolicitors.co.uk.
                        </p>
                    </div>
                    </section>

                    
                    <section className='jemek__cookies-info__content-wrapper'>
                    <div className='jemek__cookies-info__subheader'>
                        <h3 className='jemek__cookies-info__h3'>
                        Personal Data We Process
                        </h3>
                    </div>
                    <div className='jemek__cookies-info__paragraph'>
                        <p className='jemek__cookies-info__p'>
                        The following table clarifies the types of data we collect and the legal basis, in accordance with current data protection legislation, upon which this data is processed.
                        </p>
                    </div>
                    <div className='jemek__cookies-info__table'>
                        <table className='table table-lg table-bordered'>
                            <thead>
                                <tr>
                                    <th>Purpose</th>
                                    <th>Data (Key Elements)</th>
                                    <th>Basis</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Enquiring about our organisation and its work</td>
                                    <td>Name, email, phone number, message</td>
                                    <td>Legitimate interests -  It is necessary for us to read and store your message so we can respond to your legal inquiries in the manner you would expect.</td>
                                </tr>
                                <tr>
                                    <td>Subscribing to email updates about our work</td>
                                    <td>Name, email</td>
                                    <td>Consent - You have actively given your consent to receive updates and news about our legal services, changes in laws that might affect your interests, and other relevant updates from our law firm.</td>
                                </tr>
                                <tr>
                                    <td>Website functionality</td>
                                    <td>Website activity collected through cookies</td>
                                    <td>Legitimate interests - We need to store a small amount of information, usually through cookies, to provide the expected functionality, such as remembering your preferences when you previously interacted with our website or used our legal services portal.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </section>

                    <section className='jemek__cookies-info__content-wrapper'>
                    <div className='jemek__cookies-info__subheader'>
                        <h3 className='jemek__cookies-info__h3'>
                            How We Use Your Data
                            </h3>
                    </div>
                    <div className='jemek__cookies-info__paragraph'>
                        <p className='jemek__cookies-info__p'>
                        We will only use your data in a manner consistent with the basis on which that data was collected, as outlined in the table above. We may use your personal information to:
                        </p>
                        <ul className='jemek__cookies-info__ul'>
                            <li>Respond to the enquiries you send to us.</li>
                            <li>Address inquiries or other requests initiated by you.</li>
                            <li> Send you marketing communications relating to our work which may be of interest to you, given your specific consent.</li>
                        </ul>
                    </div>
                        </section>
                    
                        <section className='jemek__cookies-info__content-wrapper'>
                    <div className='jemek__cookies-info__subheader'>
                        <h3 className='jemek__cookies-info__h3'>
                        When We Share Your Data
                            </h3>
                    </div>
                    <div className='jemek__cookies-info__paragraph'>
                        <p className='jemek__cookies-info__p'>
                        We only share your data with third parties under the following circumstances:
                        </p>
                        <ul className='jemek__cookies-info__ul'>
                            <li>You have explicitly consented to us passing your data to a named third party.</li>
                            <li>We are employing a third party solely for the purpose of data processing on our behalf, and we have a data processing agreement with that third party that meets our legal obligations concerning the use of third-party data processors.</li>
                            <li>We are legally required to share your data.</li>
                        </ul>
                        <p className='jemek__cookies-info__p'>
                        Additionally, we only transfer data to third parties outside of the EU when appropriate safeguards are in place, as defined by Article 46 of the General Data Protection Regulation.
                        </p>
                    </div>
                        </section>

                        <section className='jemek__cookies-info__content-wrapper'>
                    <div className='jemek__cookies-info__subheader'>
                        <h3 className='jemek__cookies-info__h3'>
                        Data Retention Period
                            </h3>
                    </div>
                    <div className='jemek__cookies-info__paragraph'>
                        <p className='jemek__cookies-info__p'>
                        We adhere strictly to the principles of data minimisation and deletion. Our internal policies ensure we only ever request the minimum amount of data needed for the associated purpose and delete that data promptly once it is no longer necessary.
                        </p>
                        <p className='jemek__cookies-info__p'>
                        When data is collected based on consent, we seek renewal of that consent at least every three years.
                        </p>
                    </div>
                        </section>

                        <section className='jemek__cookies-info__content-wrapper'>
                    <div className='jemek__cookies-info__subheader'>
                        <h3 className='jemek__cookies-info__h3'>
                        Your Rights Over Your Data
                            </h3>
                    </div>
                    <div className='jemek__cookies-info__paragraph'>
                        <p className='jemek__cookies-info__p'>
                        Your rights over your data include the following:
                        </p>
                        <ul className='jemek__cookies-info__ul'>
                            <li>You can revoke consent-based data processing at any time, and we will make revocation as easy as possible (e.g., by placing 'unsubscribe' links at the bottom of all our marketing emails).</li>
                            <li>You can request rectification and/or deletion of your information.</li>
                            <li>You have the right to access your information.</li>
                            <li>You can lodge a complaint with the Information Commissioner if you feel your rights have been violated.</li>
                        </ul>
                        <p className='jemek__cookies-info__p'>
                        A comprehensive summary of your legal rights over your data is available on the <a className="jemek__cookies-info__link" href='https://ico.org.uk/' target='_external'>Information Commissioner’s website.</a>
                        </p>
                        <p className='jemek__cookies-info__p'>
                        Should you wish to exercise the above rights, or any other legal rights you have over your data under current legislation, please contact us.
                        </p>
                        <p className='jemek__cookies-info__p'>
                        Please be aware that exercising some rights, such as the right to delete your data, may prevent us from providing some services to you. However, we will always strive to maximise your rights while continuing to deliver as many services to you as possible.
                        </p>
                    </div>
                        </section>

                        <section className='jemek__cookies-info__content-wrapper'>
                    <div className='jemek__cookies-info__subheader'>
                        <h3 className='jemek__cookies-info__h3'>
                        Cookies and Usage Tracking
                            </h3>
                    </div>
                    <div className='jemek__cookies-info__paragraph'>
                        <p className='jemek__cookies-info__p'>
                        A cookie is a small file of letters and numbers that your computer downloads when you visit a website. Cookies, used by many websites, perform various tasks such as remembering your preferences, logging your shopping basket contents, and counting the number of website visitors.
                        </p>
                        <p className='jemek__cookies-info__p'>
                        We list the purposes for which cookies collect personal data in the above section, along with other personal data we collect. We also employ some cookies that don't collect personal information but help us gather anonymous data about how people use our website. 
                        </p>
                        <p className='jemek__cookies-info__p'>
                        For this purpose, we use Google Analytics, which generates statistical and other information about website usage via cookies stored on users' computers. The data Google Analytics collects about our website usage is anonymous and not personally identifiable. 
                        </p>
                        <p className='jemek__cookies-info__p'>
                        Google stores the data, which we use to generate reports about website usage. Read more about <a className="jemek__cookies-info__link"  href='http://www.google.com/privacypolicy.html' target='_external'>Google's Privacy Policy.</a>
                        </p>
                    </div>
                        </section>

                        <section className='jemek__cookies-info__content-wrapper'>
                    <div className='jemek__cookies-info__subheader'>
                        <h3 className='jemek__cookies-info__h3'>
                        Modifications
                            </h3>
                    </div>
                    <div className='jemek__cookies-info__paragraph'>
                        <p className='jemek__cookies-info__p'>
                        We may periodically update this Privacy Policy and will publish the most current version on our website. If an update significantly reduces your rights, we will notify individuals whose personal data we hold and may be affected.
                        </p>

                    </div>
                        </section>

                        <section className='jemek__cookies-info__content-wrapper'>
                    <div className='jemek__cookies-info__subheader'>
                        <h3 className='jemek__cookies-info__h3'>
                        Links to Other Websites
                            </h3>
                    </div>
                    <div className='jemek__cookies-info__paragraph'>
                        <p className='jemek__cookies-info__p'>
                        Our website may contain links to external sites. However, once you use these links to leave our site, we do not have control over those other websites. 
                        </p>

                        <p className='jemek__cookies-info__p'>
                        Consequently, we cannot be responsible for the protection and privacy of any information you provide while visiting such sites, as this privacy statement does not govern them. We advise caution and recommend you review the privacy statement applicable to the website in question.
                        </p>

                        

                    </div>
                        </section>


                </div>
                
                {/* <div className={`jemek__cookies-info__legend ${isFixed ? "fixed-position" : ""}`}>
                <a href='#' className='jemek__cookies-info__legend-links'>
                    dfsdfs
                </a>
                </div>
    */}
    
                </div>

                </div>
            </section>
        </div>
    )
}

export default CookiesInfo;