import React from 'react';
import './child_maintenance.css';

import LegalServicesMenuFour from '../../../components/legalservicesmenufour/Legalservicesmenufour';
import TemplateOne from '../../../components/templateone/Templateone';
import HeaderTemplateOne from '../../../components/headertemplate/Headertemplate';


const ChildMaintenance = () => {



    return (
      <div className="jemek__childmaintenance-content" id="business">
      <div className='jemek__childmaintenance-header'>
        <HeaderTemplateOne
        main_header="Child Maintenance Agreements" />

        </div>

        <div className='jemek__childmaintenance-body container'>
          <TemplateOne 
          

          subheader_text="Child maintenance agreements are essential for ensuring the financial support of children following the separation or divorce of their parents. At Jemek Solicitors Limited, we understand the importance of securing fair and sustainable child maintenance agreements."
            
            
          header_one="Assessing Unique Circumstances"
          text_one="Our experienced family law solicitors work diligently to assess each client's unique circumstances and consider all relevant factors, such as the children's needs, parents' incomes, and other financial elements. We understand the importance of creating child maintenance agreements that safeguard the best interests of the children and ensure their ongoing welfare and stability."


          header_two="Voluntary Agreements"
          text_two="Our skilled team guides clients through the process of calculating appropriate child maintenance payments and reaching voluntary agreements between parents. However, we understand that in some cases, parents cannot come to a voluntary agreement. In such situations, we are prepared to represent our clients in court proceedings, providing robust representation to ensure a fair outcome that aligns with the best interests of the child."

          header_three="Modifications to Agreements"
          text_three="We also offer expert advice and assistance to parents who have existing child maintenance agreements that may require modification due to changes in circumstances. We work with clients to review and amend agreements as needed, ensuring that child maintenance arrangements continue to meet the evolving needs of the children involved."


          header_four="Personalized Support"
          text_four="Jemek Solicitors Limited is committed to delivering exceptional service and personalized support in all matters related to child maintenance agreements. We aim to provide our clients with the knowledge, guidance, and legal expertise necessary to navigate these complex issues and secure the best possible outcome for the children and families we represent."
      

          
          />

        </div>



        <LegalServicesMenuFour 
        main_header="More Child Care Law Content"
        
        menuItemId1={1}
        title_one="Care & Protection of Children"
        link_one="/practice-areas/child-care-law/care-and-protection-of-children"
        description_one="We provide expert legal services focused on safeguarding the well-being of children in various circumstances, including divorce, custody disputes, adoption, and care proceedings, with a commitment to personalized and compassionate support."
        
        menuItemId2={2}
        title_two="Residence & Contact"
        link_two="/practice-areas/child-care-law/residence-and-contact"
        description_two="We provide expert legal services focused on residence and contact matters, offering tailored support and expert representation to clients in negotiating, formalizing, and modifying arrangements that prioritize the best interests of the children involved."
        
        enuItemId3={3}
        title_three="Adoptions"
        link_three="/practice-areas/child-care-law/adoptions" 
        description_three="We specialize in adoption matters, offering personalized and comprehensive legal support to clients pursuing adoptions. Our dedicated team of adoption specialists provides expert representation and guidance to ensure a seamless and positive experience for all parties involved."
        
        menuItemId4={4}
        title_four="Separation Agreements"
        link_four="/practice-areas/child-care-law/separation-agreements" 
        description_four="Jemek Solicitors Limited offers personalized and compassionate legal support for clients seeking separation agreements. Our experienced solicitors work closely with clients to identify and address key issues that need to be resolved. We provide comprehensive legal support throughout the process, expertly negotiating and drafting separation agreements."
        

        />




        </div>

    );
  };
  
  export default ChildMaintenance;