import React, { useState } from "react";

import MasonryCard from "../../components/masonrycard/Masonrycard";

import useIntersectionObserver from "../../components/intersectionobserver/Intersectionobserver";


import {
  feestructureimg,
  feestructurepdf,
  whoweare_masonry,
  contactus_masonry,
  funding_masonry,
  practiceareas_masonry,
} from "./imports";

import "./feepayingclients.css";



const FeePayingClients = () => {
  const [show, setShow] = useState(false)
  const refOne = useIntersectionObserver(setShow);
  const refTwo = useIntersectionObserver(setShow);
  const refThree = useIntersectionObserver(setShow);

  

  const openPdf = (event) => {
    event.preventDefault();
  const newWindow = window.open(feestructurepdf, "_blank");
  if (newWindow) newWindow.opener = null
  };

  const [onHover, setOnHover] = useState(false);

  return (
    <div className="jemek__feepaying">
      <section className="jemek__feepaying-header-wrapper section__padding page-section" ref={refOne}>
        <div className="jemek__feepaying-header-container container">
          <div className="jemek__feepaying-header-row row">
            <div className="jemek__feepaying-column col-lg-6 col-lg-6">
              <div className="jemek__feepaying-header-texts">
                <div className="jemek__feepaying-header-subheader">
                  <h1>Our Fee Structure at Jemek Solicitors</h1>
                </div>
                <div className="jemek__feepaying-header-paragraph">
                  <p>
                    In all cases, we aim to offer upfront transparent pricing.
                    We are happy to provide a quote for a fixed fee or hourly
                    rate billing.{" "}
                  </p>
                </div>
              </div>
              <div className="jemek__fee-paying__link">
                <button
                  className="jemek__fee-paying__button"
                  type="button"
                  onClick={openPdf}
                >
                  Click here to view our Fee Structure
                </button>
              </div>
            </div>
            <div className="jemek__feepaying-column col-lg-6 col-lg-6">
              <div className={`jemek__feepaying-header-item ${onHover ? "jemek__feepaying-header-item_hover" : ""}`}
              onMouseEnter={() => setOnHover(true)}
              onMouseLeave={()=> setOnHover(false)}>
                <a
                  href={feestructurepdf}
                  className="jemek__feepaying-header-item-link"
                  onClick={openPdf}
                >
                  <div className="jemek__feepaying-header-item_hover">
                    <img
                      className="jemek__fee-paying__image"
                      src={feestructureimg}
                      alt="Fee Structure"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="jemek__feepaying-bodyone section__padding page-section" ref={refTwo}>
        <div className="jemek__feepaying-bodyone-container container">
          <div className="jemek__feepaying-bodyone-text text-center">
            <h2>Our Approach</h2>
            <p className="text-center">
              {" "}
              At Jemek Solicitors Limited, we understand that legal fees are an
              important consideration for our clients. That's why we are proud
              to offer proactive, efficient, and cost-effective delivery of
              legal services, driven by professionalism, integrity, and service
              excellence. We strive to provide transparent and fair pricing for
              our services while maintaining high standards of quality and
              personalized attention to each client's unique needs.
            </p>
          </div>
        </div>
      </section>
      
      <section className="jemek__masonry-card section__padding page-section" ref={refThree}>
          <MasonryCard
          masonry_card_header="Explore"
          masonry_card_span="More"

          //card-one
          card_one_link="/funding-your-case"
          card_one_image={funding_masonry}
          card_one_alt="funding your case page image"
          card_one_header={[
            {text: 'Funding Your', highlight: false},
            {text:'Case', highlight: true}
          ]}
          card_one_text='Figuring out how to fund your case has never been easier.'
          card_one_button={[
            {text: 'Learn More', highlight: true}
          ]}

          //card-two
          card_two_link="/who-we-are"
          card_two_image={whoweare_masonry}
          card_two_alt="about us page image"
          card_two_header={[
            {text: 'Who', highlight: false},
            {text: ' We', highlight: false},
            {text: 'Are', highlight: true}
          ]}
          card_two_text="Learn about our core principles and values."
          card_two_button={[
            {text: 'Learn More', highlight: true}
          ]}

          //card-three
          card_three_link="/practice-areas"
          card_three_image={practiceareas_masonry}
          card_three_alt="practice areas page image"
          card_three_header={[
            {text: 'Practice', highlight: false},
            {text: 'Areas', highlight: true}
          ]}
          card_three_text="We specialize in various areas of the law. We are here to be of service to you."
          card_three_button={[
            {text: 'Learn More', highlight: true}
          ]}

          //card-four
          card_four_link="/contact-page"
          card_four_image={contactus_masonry}
          card_four_alt="contact us page image"
          card_four_header={[
            {text: 'Contact', highlight: false},
            {text: 'Us', highlight: true}
          ]}
          card_four_text="For More Information on where  to find us and how to reach us."
          card_four_button={[
      
            {text: 'Learn More', highlight: true}
          ]}


  
          />
        </section>
{/*
      <section className="jemek__feepaying-bodytwo section__padding page-section">
          <div className="jemekk__feepaying-bodytwo-container container">
            <div className="jemek__feepaying-bodytwo-header">
              <h2>Explore More</h2>
            </div>
            <div className="jemek__masonrycard-items-container container">
              <div className="jemek__masonrycard-items">

                <div className="jemek__masonrycard-item-wrapper">
                <div className="jemek__masonrycard-one">
                  <a href="/funding-your-case">
                    <div className="jemek__masonrycard-img">
                      <img src={funding_masonry} alt="" />
                    </div>
                    <div className="jemek__masonrycard-text">
                      <h3>Funding Your Case</h3>
                      <p>
                        Figuring out how to fund your case has never been
                        easier.
                      </p>
                      <div className="jemek__masonry-learnmore">
                        <p>Learn More</p>
                      </div>
                    </div>
                  </a>
                </div>
                </div>

                <div className="jemek__masonrycard-item-wrapper">
                <div className="jemek__masonrycard-two">
                  <a href="/who-we-are">
                  <div className="jemek__masonrycard-img">
                    <img src={whoweare_masonry} alt="" />
                    </div>

                    <div className="jemek__masonrycard-text">
                      <h3>Who We Are</h3>
                      <p>Learn about our core principles and values.</p>
                      <div className="jemek__masonry-learnmore">
                        <p>Learn More</p>
                      </div>
                    </div>
                  </a>
                </div>
                </div>

                <div className="jemek__masonrycard-item-wrapper">
                <div className="jemek__masonrycard-three">
                  <a href="/practice-areas">
                  <div className="jemek__masonrycard-img">
                    <img src={practiceareas_masonry} alt="" />
                    </div>

                    <div className="jemek__masonrycard-text">
                      <h3>Practice Areas</h3>
                      <p>
                        We specialize in various areas of the law. We are here
                        to be of service to you.
                      </p>
                      <div className="jemek__masonry-learnmore">
                        <p>Learn More</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div className="jemek__masonrycard-item-wrapper">
                <div className="jemek__masonrycard-four">
                  <a href="/contact-page">
                  <div className="jemek__masonrycard-img">
                    <img src={contactus_masonry} alt="" />
                    </div>

                    <div className="jemek__masonrycard-text">
                      <h3>Contact Us</h3>
                      <p>
                        For more information on where to find us and how to
                        reach us.
                      </p>
                      <div className="jemek__masonry-learnmore">
                        <p>Learn More</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              </div>
            </div>
          </div>
        </section>
  */}
    </div>
  );
};

export default FeePayingClients;
