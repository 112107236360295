import React from "react";
import { Members, ScrollBar } from '../../components'

const MemberThree = () => {
    return (
        <div>
            <div className="jemek__memberthree-content">

        <Members
        member_name="Navpreet Kaur" 
        member_position="Solicitor"
        member_department="Immigration & Asylum"
        hometown_heading=""
        member_hometown=""
        education_heading=""
        member_qualifications=""
        interests_heading=""
        member_interests=""
        span_text_one=""
        member_text_one=""
        span_text_two=""
        member_text_two=""
        span_text_three=""
        member_text_three=""
        
        />
        
            </div>

            <div className='jemek__scrollbar'>
          <ScrollBar 
            scrollbar_heading="Explore More"
            scrollbar_text_one="Home"
            scrollbar_text_two="Who We Are"
            scrollbar_text_three="Practice Areas"
            scrollbar_text_four="Funding Your Case"
            scrollbar_text_five="Business Immigration"
            scrollbar_text_six="Divorce & Separation"
            scrollbar_text_seven="Child Care Law"/>
        </div>
        </div>
    )
}

export default MemberThree;