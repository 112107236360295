import React from 'react';
import './partnerships.css';


import HeaderTemplateOne from '../../../components/headertemplate/Headertemplate';
import TemplateOne from '../../../components/templateone/Templateone';

import LegalServicesMenu from '../../../components/legalservicesmenu/Legalservicesmenu';



const Partnerships = () => {



    return (
      <div className='jemek__partnerships' id="divorce">
      <div className='jemek__partnerships-header'>
        <HeaderTemplateOne 
        main_header="Civil Partnerships and Dissolution"/>
      </div>

      <div className='jemek__partnerships-body container'>
        <TemplateOne

          subheader_text="Civil partnerships are a legal relationship between two individuals of the same sex, with both parties holding rights and responsibilities towards each other. However, when things go wrong, the dissolution process can be challenging, and it is essential to have expert guidance."
          
          
          header_one="Expert Legal Advice"
          text_one="Our experienced team can advise and represent you in ending your legal relationship through dissolution, and provide guidance on ancillary matters such as child arrangements and financial claims. We can handle the dissolution process on your behalf, with a fixed fee for a straightforward and undefended dissolution, conducted through the post."

          header_two="Financial Matters"
          text_two="One or both parties may need to make an application to the court if they cannot reach an agreement on the financial division between partners. Our team can advise you on your claims and negotiate a settlement on your behalf, hopefully without involving the court. However, we can represent you in court applications as needed, with the court having the power to make various orders, including maintenance, lump sum, transfer of property, and pension sharing."
          
          header_three="Proving Irretrievable Breakdown"
          text_three="To prove that the relationship has irretrievably broken down, one or more of the following facts must be established: unreasonable behavior, living apart for 2 years with mutual agreement, living apart for 5 years, or desertion for 2 years. Our team can guide you through this process, advising on the best approach for your situation."

          header_four="Approving the Agreement"
          text_four="The court can approve an agreement only after the conditional agreement has been made. However, collaborative law or mediation can be an alternative to court proceedings, allowing both parties to negotiate an agreement. In most cases, the court would seal the agreement reached, but on occasions, it may ask for further information to be provided."

          header_five="Legal Aid"
          text_five="Legal aid is available to deal with several aspects of family and children work. If you do not qualify for legal aid, we offer high-quality advice and representation on a privately funded basis at reasonable rates, including a reduced fixed fee in dissolution proceedings."
          

        />

      </div>


      <LegalServicesMenu 
        main_header="More Family Law Content"
        

        menuItemId1={1}
        title_one="Pre-nuptial Agreements"
        link_one="/practice-areas/family-law/prenuptial-agreements" 
        description_one="Pre-nuptial agreements, once exclusive to the wealthy, have gained broader acceptance and can provide a framework for managing finances during marriage. Careful drafting is essential to ensure fairness and address unique individual circumstances."
        
        menuItemId2={2}
        title_two="Divorce & Separation"
        link_two="/practice-areas/family-law/divorce-and-separation"
        description_two="Our team is dedicated to providing practical advice and support throughout the entire process, ensuring the best possible outcome for all parties involved. We work with our clients to help them make informed decisions and provide a range of services that address their individual needs."
        

        menuItemId3={3}
        title_three="Spousal Maintenance"
        link_three="/practice-areas/family-law/spousal-maintenance"
        description_three="At Jemek Solicitors Limited, we offer expert guidance on spousal maintenance, working to ensure fair and appropriate calculations for both recipients and payers. We understand that adjustments may be necessary due to changes in financial circumstances, and our team can assist with those as well. We are committed to achieving the best possible outcome for our clients."
        
        menuItemId4={4}
        title_four="Financial Division"
        link_four="/practice-areas/family-law/financial-division"
        description_four="Our financial division at Jemek Solicitors Limited focuses on promoting settlements and providing cost-effective services in cases involving asset redistribution. We work closely with related professionals to ensure the best possible outcome for our clients, providing honest and experienced representation."

        menuItemId5={5}
        title_five="Financial Settlements"
        link_five="/practice-areas/family-law/financial-settlements"
        description_five="When it comes to divorce proceedings, financial settlements can be complex and require full disclosure of finances, negotiation, and potentially a final hearing. A Consent Order can be used to formalize agreements, becoming binding once sealed by the court. Our team at Jemek Solicitors Limited can provide expert guidance on this process and help achieve equitable results."
        
        menuItemId6={6}
        title_six="Financial Claims For Unmarried Couples"
        link_six="/practice-areas/family-law/financial-claims"
        description_six="Unmarried couples face unique challenges when it comes to addressing financial claims, as they must rely on trust laws and different legal remedies. Our team at Jemek Solicitors Limited provides expert guidance to navigate these complex situations and achieve equitable results."

        menuItemId7={7}
        title_seven="Domestice Abuse"
        link_seven="/practice-areas/family-law/domestic-abuse"
        description_seven="Domestic abuse is a pervasive issue that can manifest in various forms. Our firm is committed to providing compassionate support and guidance to help victims navigate their options and seek the protection they deserve. At Jemek Solicitors Limited, we take this issue seriously and work tirelessly to ensure our clients' safety."
        
        menuItemId8={8}
        title_eight="Cohabitation"
        link_eight="/practice-areas/family-law/co-habitation"
        description_eight="Unmarried couples face unique legal challenges when it comes to parental responsibility and property division. Seeking expert legal advice can help to protect the rights and interests of both partners and ensure they are treated fairly under the law."
        


      />
      </div>

    );
  };
  
  export default Partnerships;