import React, { useState } from 'react';
import './whoweare.css';
import {meets, mission_whoweare} from './imports';

import useIntersectionObserver from '../../components/intersectionobserver/Intersectionobserver';


const WhoWeAre = () => {
  const [show, setShow] = useState(false);

  const refOne = useIntersectionObserver(setShow)
  const refTwo = useIntersectionObserver(setShow)



    return (
      
        <><div className='jemek__whoweare-header section-padding page-section' id="whoweare">
        <div className='jemek__whoweare-header-container container'>
          <div className='jemek__whoweare-header-container-text text-center'>
            <h1>Who We Are</h1>
          </div>


        </div>

      </div>
      
      <div className='jemek__whoweare-contentone page-section'>
          <div className='jemek__whoweare-contentone-container container'>
            <div className='jemek__whoweare-contentone-text_one text-center'>
              <p> Jemek Solicitors Limited is a long standing firm that delivers legal services to large and diversified client base that range from private individuals to multinational corporations.</p>
            </div>
            <div className='jemek__whoweare-contentone-text_two text-center'>
              <p>Founded in the early 2010s, the firm is located in the prestigious Jewellrey Quarter in the city of Birmingham.</p>
            </div>

            </div>

            <div className='jemek__whoweare-sectiontwo section__padding page-section' ref={refOne}>
              <div className='jemek__whoweare-sectiontwo-container container'>
              <div className='jemek__whoweare-contentone-text_three row'>
              <div className='jemek__whoweare-contentone-text_three-item col-xl-6 col-lx-6'>
              <div className='jemek__whoweare-contentone-image'>
                <a href='/contact-page'>
                <img src={mission_whoweare} alt='whoweareimage'/>
                </a>
              </div>
              </div>
              <div className='jemek__whoweare-contentone-text_three-item-two col-xl-6 col-xl-6'>
                <div className='jemek-content-area__container'>
                <div className='jemek__whoweare-contentone-header'>
                <h2>Our Mission Statement.</h2>
                </div>
                <div className='jemek__whoweaere-contentone-text'>
                <p> To inspire, lead and Innovate.{"\n"}
                  We aspire to provide excellent legal services to our clients and {"\n"}
                  endaevour to uncomplicate the Law to the best of our ability. We believe access to Legal assistance should not be difficult nor complicated and{"\n"}
                  should rather be Integrous, Decent, Honorable and Virtuous.</p>
                </div>
                <div className='jemek__whoweare-contentone-button'>
                  <a href='/reach-us'>
                  <button className='jemek__whoweare-button btn btn-custom-transparent btn-xl'>
                    Reach Us
                  </button>
                  </a>
                </div>
                </div>

              </div>

              </div>
              </div>

            </div>



            
 
            <div className='jemek__whoweare-contentone-text_four text-center'>

              <p> We are a firm made up of individuals who care, people who get real job satisfaction from helping our clients, whilst demonstrating real talent in our specialised areas of the Law.</p>
            </div>

            <div className='jemek__whoweare-sectionthree-container container'>
            <div className='jemek__whoweare-contentone-text_five text-center'>
              <div className='jemek__content-section__image-container' ref={refTwo}>
              <img className="jemek__content-section__image" src={meets} alt="heroimage" />
              </div>

              <h3>Our Culture and Ethics</h3>
              <p>We pride ourselves on our high level of client care. We keep our clients fully informed,
                responding quickly to their queries and consulting them at every stage of their proceedings.
                From the most straightforward matter to a life-changing events, our clients receive a personalised level of care, dedication and expertise from our legal specialists.  </p>


            </div>

            <div className='jemek__whoweare-contentone-text_seven text-center'>
              <p>No assumptions, no one size-fits all approach, just the very best support from a team that delivers tangible results.</p>
            </div>



            <div className='jemek__whoweare-contentone-text_eight text-center'>
              <h3>Legal Expertise That Sets Us Apart</h3>
              <p>Our areas of specialisation make us a leading force in the legal practice market and The majority of our clients have been introduced to us by other
                legal professionals, personal endorsements, and ties with local businesses and communities.</p>
            </div>

            <div className='jemek__whoweare-contentone-text_ten text-center'>
              <p>The trust and confidence placed in our services by both former
                and current clients further contribute to our growing reputation. </p>
            </div>
            <div className='jemek__whoweare-contentone-text_eleven text-center'>
              <p>Our comprehensive range of legal services encompasses various areas of specialization,
                such as Immigration, Family and Divorce Law, and Child Care Law. Our dedicated team of experienced professionals are committed to providing our clients
                with exceptional legal counsel and support tailored to their individual needs in these domains. </p>
            </div>


            <div className='jemek__whoweare-contentone-text_twelve text-center'>
              <a href='/practice-areas'>Find Out More</a>
            </div>

          </div>

        </div></>
      


    );
  };
  
  export default WhoWeAre;