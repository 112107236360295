import React from 'react'
import './membertwo.css'
import { Members, ScrollBar } from '../../components'


const MemberTwo = () => {

    return (
        <div>
                <div className='jemek__membertwo-content'>
        <Members

        member_name="Glory Oyerior-Apata" 
        member_position="Partner"
        member_department="Immigration & Asylum Solicitor"
        hometown_heading="Hometown"
        member_hometown="Benin, Nigeria"
        education_heading="Education"
        member_qualifications="LL.B (Hons), Barrister-at-Law"
        interests_heading="Interests"
        member_interests="-----"
        span_text_one="Mrs. Glory Oyerior-Apata, is a distinguished professional with dual qualifications"
        member_text_one="as a Barrister in Nigeria and as a solicitor in the United Kingdom since 2010. She has garnered a reputation as an immigration expert, boasting over a decade of invaluable experience as a senior caseworker and supervisor of the Immigration Department at Jemek Solicitors Ltd. Mrs. Oyerior-Apata's clientele spans a diverse range, from high-net-worth individuals seeking corporate immigration assistance to those dealing with deportation, asylum seeking, and humanitarian protection issuesas a Barrister in Nigeria and as a solicitor in the United Kingdom since 2010. She has garnered a reputation as an immigration expert, boasting over a decade of invaluable experience as a senior caseworker and supervisor of the Immigration Department at Jemek Solicitors Ltd. Mrs. Oyerior-Apata's clientele spans a diverse range, from high-net-worth individuals seeking corporate immigration assistance to those dealing with deportation, asylum seeking, and humanitarian protection issues."
        span_text_two="As the head of the Immigration department"
        member_text_two=", Mrs. Oyerior-Apata is not only a highly skilled immigration specialist but also a mentor to new talents within the firm. She is a member of the Institute of Chartered Secretaries and Administrators of Nigeria and takes immense pride in her personal involvement in each case. Her innovative and creative solutions to complex legal challenges set her apart in the field."
        span_text_three="If you require assistance in securing your immigration status"
        member_text_three=", don't hesitate to reach out to Mrs. Glory Oyerior-Apata. Her expertise and dedication to her clients ensure that your case will be handled with the utmost care and professionalism."
        
        />
        
      </div>

      <div className='jemek__scrollbar'>
          <ScrollBar 
            scrollbar_heading="Explore More"
            scrollbar_text_one="Home"
            scrollbar_text_two="Who We Are"
            scrollbar_text_three="Practice Areas"
            scrollbar_text_four="Funding Your Case"
            scrollbar_text_five="Business Immigration"
            scrollbar_text_six="Divorce & Separation"
            scrollbar_text_seven="Child Care Law"/>
        </div>

       
      </div>
    
        
        
       
        
    )
}

export default MemberTwo;