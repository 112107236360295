import React from 'react';
import './claims.css';


import HeaderTemplateOne from '../../../components/headertemplate/Headertemplate';
import TemplateOne from '../../../components/templateone/Templateone';

import LegalServicesMenu from '../../../components/legalservicesmenu/Legalservicesmenu';



const Claims = () => {



    return (
      <div className='jemek__finclaims' id="divorce">
      <div className='jemek__finclaims-header'>
        <HeaderTemplateOne 
        main_header="Financial Claims For Unmarried Couples"/>
      </div>

      <div className='jemek__finclaims-body container'>
        <TemplateOne

          subheader_text="ur team of legal experts understands the unique financial challenges that unmarried couples face when their relationships break down. It is important to dispel the myth of the 'common law wife,' and recognize that financial claims and obligations depend on marital status. "
          
          
          header_one="Claiming an Interest in a Jointly Owned Property"
          text_one="Unmarried couples who own a home together may need to rely on trust laws to make claims for an interest in the property. This can be a complex issue, particularly when one partner is not on the legal title. Our team can advise on constructive and resulting trusts that can give rise to a beneficial interest, explain promissory estoppel, and assist in negotiating a transfer or sale and the subsequent division of proceeds."

          header_two="Child Support and Lump Sum Payments"
          text_two="For unmarried couples with children, child support is typically governed by the Child Support Agency, unless one party resides outside the jurisdiction or the absent parent's income is exceptionally high.  In addition to child support, the parent with care of a dependent child may claim a lump sum for essential purchases or providing a home under Schedule 1 of the Children Act 1989."
          text_important_two="Our team can assist in navigating the Child Support Agency and handling court matters if necessary."

          header_three="Experience Representing Both Sides"
          text_three="Our team has experience representing both sides in financial claims between unmarried couples. These cases can be just as difficult to resolve as disputes between married couples, particularly because there is no consolidation of legal remedies for these families. We provide expert guidance to ensure fair outcomes and to make the process as stress-free as possible for all parties involved."

          header_four="Navigating Complex Situations"
          text_four="Understanding and addressing the financial claims of unmarried couples requires specialized knowledge and experience. Our team can guide you through the complex legal framework, explain your options, and provide expert representation when necessary. We recognize that each situation is unique and strive to find the best possible outcome for our clients."


        />

      </div>

      <LegalServicesMenu 
        main_header="More Family Law Content"
        

        menuItemId1={1}
        title_one="Pre-nuptial Agreements"
        link_one="/practice-areas/family-law/prenuptial-agreements" 
        description_one="Pre-nuptial agreements, once exclusive to the wealthy, have gained broader acceptance and can provide a framework for managing finances during marriage. Careful drafting is essential to ensure fairness and address unique individual circumstances."
        
        menuItemId2={2}
        title_two="Divorce & Separation"
        link_two="/practice-areas/family-law/divorce-and-separation"
        description_two="Our team is dedicated to providing practical advice and support throughout the entire process, ensuring the best possible outcome for all parties involved. We work with our clients to help them make informed decisions and provide a range of services that address their individual needs."
        

        menuItemId3={3}
        title_three="Spousal Maintenance"
        link_three="/practice-areas/family-law/spousal-maintenance"
        description_three="At Jemek Solicitors Limited, we offer expert guidance on spousal maintenance, working to ensure fair and appropriate calculations for both recipients and payers. We understand that adjustments may be necessary due to changes in financial circumstances, and our team can assist with those as well. We are committed to achieving the best possible outcome for our clients."
        
        menuItemId4={4}
        title_four="Financial Division"
        link_four="/practice-areas/family-law/financial-division"
        description_four="Our financial division at Jemek Solicitors Limited focuses on promoting settlements and providing cost-effective services in cases involving asset redistribution. We work closely with related professionals to ensure the best possible outcome for our clients, providing honest and experienced representation."

        menuItemId5={5}
        title_five="Civil Partnerships and Dissolution"
        link_five="/practice-areas/family-law/civil-partnerships-and-dissolution"
        description_five="We offer expert guidance in civil partnership dissolution, addressing finances and children. Our team at Jemek Solicitors Limited understands the complexities of partnership law, and we are committed to providing the best possible outcome for our clients."
        
        menuItemId6={6}
        title_six="Financial Settlements"
        link_six="/practice-areas/family-law/financial-settlements"
        description_six="When it comes to divorce proceedings, financial settlements can be complex and require full disclosure of finances, negotiation, and potentially a final hearing. A Consent Order can be used to formalize agreements, becoming binding once sealed by the court. Our team at Jemek Solicitors Limited can provide expert guidance on this process and help achieve equitable results."

        menuItemId7={7}
        title_seven="Domestice Abuse"
        link_seven="/practice-areas/family-law/domestic-abuse"
        description_seven="Domestic abuse is a pervasive issue that can manifest in various forms. Our firm is committed to providing compassionate support and guidance to help victims navigate their options and seek the protection they deserve. At Jemek Solicitors Limited, we take this issue seriously and work tirelessly to ensure our clients' safety."
        
        menuItemId8={8}
        title_eight="Cohabitation"
        link_eight="/practice-areas/family-law/co-habitation"
        description_eight="Unmarried couples face unique legal challenges when it comes to parental responsibility and property division. Seeking expert legal advice can help to protect the rights and interests of both partners and ensure they are treated fairly under the law."
        

      />
      </div>

    );
  };
  
  export default Claims;