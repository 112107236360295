import React, { useState } from 'react';
import './fundingyourcase.css';
import useIntersectionObserver from '../../components/intersectionobserver/Intersectionobserver';


import HeaderTemplateOne from '../../components/headertemplate/Headertemplate';
import TemplateTwo from '../../components/templatetwo/Templatetwo';

import {
        feepaying2_funding,
        legalaid_funding } from './imports';

const FundingYourCase = () => {

  const [show, setShow] = useState(false);
  const refOne = useIntersectionObserver(setShow);
  const refTwo = useIntersectionObserver(setShow)
 


    return (
        <div className="jemek__fundingyourcase-content">
        <div className='jemek__fundingyourcase-header'>
          <HeaderTemplateOne
          main_header="Funding Your Case" />
  
          </div>
          <div className='jemek__fundingyourcase-body'>
            <TemplateTwo 
            
  
            subheader_text="At Jemek Solicitors Limited, we understand that funding your legal case can be a source of concern and uncertainty. Our team of legal experts are committed to providing clear and transparent information about our fees and funding options, ensuring that our clients are fully informed and able to make informed decisions about how to proceed with their case."
              />
          <section className=' jemek__fundingyourcase-itemone-section page-section section__padding' ref={refOne}>
          <div className='jemek__fundingyourcase-itemone'>
            <div className='jemek__fundingyourcase-itemone-row row'>
              <div className='jemek__fundingyourcase-columnone_image col-xl-6 col-xl-6 mb-4'>
                <div className='jemek__fundingyourcase-column-items'>
                  <div className='jemek__fundingyourcase-image'>
                    <img src={legalaid_funding} alt=""/>
                  </div>
                </div>
              </div>


              <div className='jemek__fundingyourcase-columntwo_right col-xl-6 col-xl-6 mb-4'>
              <div className='jemek__fundingyourcase-column-items'>
                <div className='jemek__fundingyourcase-column-header'>
                  <h3>Legal <span className='fundingyourcase-text-highlight'>Aid</span></h3>
                </div>
                <div className='jemek__fundingyourcase-column-text'>
                  <p>We recognize that legal services can be expensive, and we strive to make our services accessible to as many people as possible. For those who are financially eligible, Legal Aid may be available to provide legal services free of charge in areas such as Immigration and Asylum Law, Family Law, and Child Care Law.</p>
                </div>
                <div className='jemek__fundingyourcase-column-cta'>
                  <a href='/funding-your-case/legal-aid'>
                  <p>Learn More</p>
                  </a>

                </div>
              </div>
                
                </div>

            </div>
          </div>
          </section>


          <section className=' jemek__fundingyourcase-itemtwo-section page-section section__padding' ref={refTwo}>
          <div className='jemek__fundingyourcase-itemtwo'>
            <div className='jemek__fundingyourcase-itemtwo-row row'>
              <div className='jemek__fundingyourcase-columnone_left col-xl-6 col-xl-6 mb-4'>
                <div className='jemek__fundingyourcase-column-items'>
                <div className='jemek__fundingyourcase-column-header'>
                  <h3><span className='fundingyourcase-text-highlight'>Fee</span> Paying Clients</h3>
                </div>
                <div className='jemek__fundingyourcase-column-text'>
                  <p>We aim to provide upfront and transparent pricing to all of our clients, whether they are paying a fixed fee or hourly rate billing. When you first contact us, we will connect you with a solicitor experienced in the relevant field to help you determine the most appropriate and cost-effective way to move forward with your case.</p>
                </div>
                <div className='jemek__fundingyourcase-column-cta'>
                  <a href='/funding-your-case/fee-paying-clients'>
                  <p>Learn More</p>
                  </a>

                </div>
                </div>
              </div>

              
              <div className='jemek__fundingyourcase-columntwo_image col-xl-6 col-xl-6 mb-4'>
              <div className='jemek__fundingyourcase-column-items'>
              <div className='jemek__fundingyourcase-image'>
                    <img src={feepaying2_funding} alt=""/>
                  </div>
              </div>
                
                </div>

            </div>
          </div>
          </section>

          </div>
          <section className='jemek__legalservicescard section__padding page-section'>
            <div className='jemek__legalservicescard-header'>
              <h3 className='text-center'> Explore Our Practice<span className='span-text-highlight'> Areas</span></h3>
            </div>
            <div className='jemek__legalservicescard-text'>
              <p className='text-center'>Find out about the legal services available to you at Jemek Solicitors. We Specialize in Immigration, Family and Child Care Law with a litany of services under each umbrella of the law. </p>
            </div>
            <div className='jemek__legalservicescard-button text-center'>
              <a href='/practice-areas'>
                <button className='jemek__legalservicescard-btn'>Learn About Our Practice Areas</button>
              </a>
            </div>
          </section>
          
  

  
  
  
  
          </div>

    );
  };
  
  export default FundingYourCase;