import React from 'react';
import './child_protection.css';
import TemplateOne from '../../../components/templateone/Templateone';
import HeaderTemplateOne from '../../../components/headertemplate/Headertemplate';

import LegalServicesMenuFour from '../../../components/legalservicesmenufour/Legalservicesmenufour';


const ChildProtection = () => {



    return (
      <div className='jemek__childprotection-content' id="business">
      <div className='jemek__childprotection-header'>
        <HeaderTemplateOne
        main_header="Care & Protection of Children" />

        </div>

        <div className='jemek__childprotection-body container'>
          <TemplateOne 
          

          subheader_text="At Jemek Solicitors Limited, we recognize the significance of ensuring the care and protection of children during legal proceedings. Our experienced team is dedicated to safeguarding children's welfare and rights in various circumstances, such as divorce, separation, custody disputes, adoption, and care proceedings."
            
            
          header_one="Tailored Solutions for Children's Welfare"
          text_one="Our compassionate and skilled family law solicitors work closely with clients to understand their unique situations and develop tailored solutions to meet the best interests of the children involved. We navigate the complexities of the legal system on your behalf, providing guidance on issues such as custody, visitation, child support, and other matters related to the well-being of your children."
   
          header_two="Expert Guidance on Adoption"
          text_two="In cases of adoption, our expert team at Jemek Solicitors Limited can guide prospective adoptive parents through the legal process, ensuring compliance with relevant regulations and requirements. We assist clients in navigating the complexities of both domestic and international adoptions, aiming to create stable, loving homes for children in need."

          
          header_three="Diligent Representation in Care Proceedings"
          text_three="When it comes to care proceedings, our solicitors understand the emotional challenges faced by families and work diligently to protect the welfare of the children involved. We represent parents, guardians, and other parties in cases concerning child protection, providing robust legal support and advocating for the best interests of the child."


          header_four="Commitment to Professional and Personalized Service"
          text_four="Jemek Solicitors Limited is committed to delivering the highest level of professional service and personal attention in all matters related to the care and protection of children. We collaborate with clients, social services, and other professionals to ensure the best possible outcomes for the children and families we represent."
      

          
          />

        </div>


        <LegalServicesMenuFour 
        main_header="More Child Care Law Content"
        
        menuItemId1={1}
        title_one="Child Maintenance Agreements"
        link_one="/practice-areas/child-care-law/child-maintenance" 
        description_one="Jemek Solicitors Limited provides expert legal services focused on securing fair and sustainable child maintenance agreements for the financial support of children following the separation or divorce of their parents. We offer comprehensive support in negotiating, formalizing, and modifying child maintenance arrangements, with a commitment to personalized and compassionate guidance."
        
        menuItemId2={2}
        title_two="Residence & Contact"
        link_two="/practice-areas/child-care-law/residence-and-contact"
        description_two="We provide expert legal services focused on residence and contact matters, offering tailored support and expert representation to clients in negotiating, formalizing, and modifying arrangements that prioritize the best interests of the children involved."
        
        enuItemId3={3}
        title_three="Adoptions"
        link_three="/practice-areas/child-care-law/adoptions" 
        description_three="We specialize in adoption matters, offering personalized and comprehensive legal support to clients pursuing adoptions. Our dedicated team of adoption specialists provides expert representation and guidance to ensure a seamless and positive experience for all parties involved."
        
        menuItemId4={4}
        title_four="Separation Agreements"
        link_four="/practice-areas/child-care-law/separation-agreements" 
        description_four="Jemek Solicitors Limited offers personalized and compassionate legal support for clients seeking separation agreements. Our experienced solicitors work closely with clients to identify and address key issues that need to be resolved. We provide comprehensive legal support throughout the process, expertly negotiating and drafting separation agreements."
        

        />




        </div>

    );
  };
  
  export default ChildProtection;