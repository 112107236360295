import React from "react";
import './policyandterms.css';
import {
    termsofbusinesspdf,
} from './imports'

const PolicyAndTerms = () => {
    const openPdf = (event) => {
    event.preventDefault()
    const newWindow = window.open(termsofbusinesspdf, "_blank");
    if (newWindow) newWindow.opener = null;
      };

    return (
        <div className="jemek__policy-and-terms">
        <section className="jemek__policy-and-terms__section-header section__padding">
            <div className="jemek__policy-and-terms__container container">
                <div className="jemek__policy-and-terms__header">
                </div>
            </div>
        </section>
        <section className="jemek__policy-and-terms__section-body section__padding">
            <div className="jemek__policy-and-terms__container container">
                <div className="jemek__policy-and-terms__contents">
                    <div className="jemek__policy-and-terms__title">
                        <h2 className="jemek__policy-and-terms__h2">
                            Our Use of Cookies and Information about our Legal Compliance
                        </h2>
                    </div>
                    <div className="jemek__policy-and-terms__paragraph">
                        <a href="/policy-and-terms/privacy-and-cookies" className="jemek__policy-and-terms__link">Click here to learn about our Privacy and Cookies </a>
                    </div>
                    <div className="jemek__policy-and-terms__paragraph">
                        <a href={termsofbusinesspdf} style={{"cursor":"pointer"}}className="jemek__policy-and-terms__link" onClick={openPdf}>Click here to view our Terms Of Business</a>
                    </div>
                    <div className="jemek__policy-and-terms__paragraph">
                        <a href="/policy-and-terms/complaints-procedure" className="jemek__complaints-procedure__link">Click here to view our Complaints Procedure</a>
                    </div>
                </div>
            </div>
        </section>
        </div>
    )
}

export default PolicyAndTerms;