import React from 'react';
import './business.css';
import TemplateOne from '../../../components/templateone/Templateone';
import HeaderTemplateOne from '../../../components/headertemplate/Headertemplate';
import LegalServicesMenuFour from '../../../components/legalservicesmenufour/Legalservicesmenufour';

const Business = () => {



    return (
      <div className="jemek__businessimmigration-content" id="business">
      <div className='jemek__businessimmigration-header'>
        <HeaderTemplateOne
        main_header="Business Immigration" />

        </div>

        <div className='jemek__businessimmigration-body container'>
          <TemplateOne 
          

          subheader_text="The introduction of the Points Based System (PBS) has had far-reaching effects on UK businesses, significantly impacting the way companies hire and manage international talent."
            
            
          header_one="Business Immigration Compliance"
          text_one="The PBS has made it easier for businesses to sponsor skilled foreign workers, but also requires stricter compliance measures to avoid civil penalties. Our team can help businesses navigate the complex UKBA compliance standards and provide guidance to ensure compliance and avoid legal repercussions."
          text_important_one="Our team at Jemek Solicitors Limited can help you navigate the complexities of pre-nuptial agreements and ensure that your interests are protected."

          header_two="Immigration Law Update Briefings"
          text_two="Keeping up-to-date with the latest changes and requirements in the immigration landscape is crucial for businesses to maintain compliance. Our tailored in-house legal update briefings provide corporate organizations and businesses with the necessary knowledge and tools to stay compliant in a constantly evolving legal environment. "
          text_important_two="These briefings cover topics such as sponsorship licences, resident labour market tests, and reporting and record-keeping duties."
          
          header_three="Sponsorship Licence Support"
          text_three=" To recruit overseas workers, businesses are required to obtain a sponsorship licence from the Home Office. Our team can assist with the application process and ensure that all resident labour market tests are satisfied before workers can be recruited from overseas. "
          text_important_three=" We also provide guidance to businesses on their additional reporting and record-keeping duties as a sponsoring employer to ensure that they remain compliant."

          header_four="Pre-Audit Compliance Checks"
          text_four="UKBA may plan a compliance visit to a sponsoring employer to ensure compliance with all necessary regulations. Our team can undertake pre-audit compliance checks to ensure that businesses are prepared and in adherence to all regulations."
          text_important_four="This proactive approach can save businesses from potential penalties, reputational damage, or other negative consequences."

          header_five="Entry Clearance and Leave to Remain Applications"
          text_five="Our team of experienced professionals can also draft entry clearance and leave to remain applications for workers in your employment, helping you navigate the complexities of hiring and maintaining a diverse and skilled international workforce while remaining fully compliant with all legal requirements."
          text_important_five="We understand the importance of having a diverse and skilled workforce and offer services to help businesses maximize the potential of their international employees."
          
          />
        </div>



        <LegalServicesMenuFour 
        main_header="More Immigration Law Content"
        
        menuItemId1={1}
        title_one="Asylum & Human Rights"
        link_one="/practice-areas/immigration-law/asylum-and-human-rights"
        description_one="Applying for asylum or seeking protection under human rights laws can be a complex and emotional process. Our team of legal experts provides compassionate and dedicated support to individuals seeking asylum or applying for human rights claims."
        
        menuItemId2={2}
        title_two="Personal & Family Immigration"
        link_two="/practice-areas/immigration-law/personal-and-family"
        description_two="Our team provides expert assistance with a wide range of personal and family immigration matters, including visa and nationality applications. We understand the importance of family reunification and work diligently to help individuals and families navigate the complexities of the immigration system. We offer a range of services, from initial consultation to application preparation and representation at appeals, to ensure the best possible outcome for our clients."
        
        menuItemId3={3}
        title_three="EEA Citizens & Family Visa"
        link_three="/practice-areas/immigration-law/eea-citizens-and-family" 
        description_three="We understand that the immigration process can be overwhelming, especially for those who are not familiar with the system. As a result, we offer simplified entry processes for EEA citizen family members, including a range of documentation options. Our team is dedicated to providing comprehensive and efficient services for EEA citizens, helping them navigate the immigration process with ease."
        
        menuItemId4={4}
        title_four="Deportation & Detention"
        link_four="/practice-areas/immigration-law/deportation-and-detention" 
        description_four="Being detained or facing deportation can be a traumatic and overwhelming experience. Our team provides comprehensive legal services for deportation and detention cases, including appeals and detainee release. We understand the importance of a swift and compassionate response, and work diligently to ensure that our clients are provided with the best possible representation and support throughout the entire process."
        

        />
        </div>

    );
  };
  
  export default Business;