import React from "react";
import "./headertemplate.css";

const HeaderTemplateOne = ({main_header}) => {
    return (
        <div className='jemek__templateone-header section-padding page-section'>
        <div className='jemek__templateone-header-container container'>
              <div className='jemek__templateone-header-container-text text-center'>
                {main_header && <h1>{main_header}</h1>}
              </div>
            </div>
            </div>
    )
}


export default HeaderTemplateOne;