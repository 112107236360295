import React from "react";
import './templatetwo.css';



const TemplateTwo = ({main_header, header_one, header_two,header_three, header_four, header_five, header_break, subheader_text, 
  text_break, quote_one, text_one, text_two, text_three, text_four, text_five, link_one, link_one_text, link_two, link_two_text
  }) => {

  




    return (
      <div className='jemek__templatetwo-wrapper'>



          
          <div className='jemek__templatetwo-content-one-main page-section'>
            <div className='jemek__templatetwo-content-one-container container'>
              <div className='jemek__templatetwo-content-one-textbox text-center'>
            {subheader_text && <p> {subheader_text}</p>}
              </div>
            </div>
          </div>

          <section className='jemek__templatetwo-content-two-main page-section'>
            <div className='jemek__templatetwo-content-two-container-one container'>
              <div className='jemek__templatetwo-content-two-text_one text-center'>


            <div className='jemek__template-two__content-items text-center'>
              {header_one &&  <h2>
                {header_one}
                </h2>}
                {text_one && <p>
                {text_one}
                </p>}
                <div className='jemek__eea-content-text-link'>
                  <a href={link_one}>
                  {link_one_text}
                 </a>
                </div>
              </div>




            </div>
            </div>

            <div className='jemek__template-two__content-items text-center'>
                {header_two && <h2>
                {header_two}
                </h2>}
                {text_two && <p>
                  {text_two}
                </p>}
                <div className='jemek__eea-content-text-link'>
                  <a href={link_two}>
                  {link_two_text}
                 </a>
                </div>
              </div>

              








          </section>

        </div>
        );

        };


export default TemplateTwo;