import React from 'react';
import './fin_settlement.css';

import HeaderTemplateOne from '../../../components/headertemplate/Headertemplate';
import TemplateOne from '../../../components/templateone/Templateone';

import LegalServicesMenu from '../../../components/legalservicesmenu/Legalservicesmenu';


const FinSettlement = () => {



    return (
      <div className='jemek__settlements' id="divorce">
      <div className='jemek__settlements-header'>
        <HeaderTemplateOne 
        main_header="Financial Settlements"/>
      </div>

      <div className='jemek__settlements-body container'>
        <TemplateOne

          subheader_text="Divorce can be a challenging and emotional experience, especially when it comes to dividing finances. Applying for a financial settlement after filing for divorce can be a complex process, which requires careful guidance and expertise from experienced legal professionals. "
          
          
          header_one="Timetable for financial settlements"
          text_one="Once a divorce proceeding is filed, either party can apply for a financial settlement. After submission, the court imposes a timetable to ensure the process is efficient and effective. This timetable requires full disclosure of each party's financial situation, leading to negotiation aimed at reaching a fair settlement."

          header_two="Resolution of disputes"
          text_two="If negotiation fails, the case proceeds to a final hearing in front of a judge, who will render a decision. It is important to note that the court's decision is binding, and penalties may be imposed on uncooperative parties."
          
          header_three="Consent Order"
          text_three="In cases where both parties agree on the division of finances and need to formalize their agreement, a Consent Order can be submitted to the court. This document outlines the agreed-upon terms and, once reviewed and sealed by the court, becomes a binding order."
          

          header_four="Experienced legal professionals"
          text_four="By working with experienced legal professionals, you can ensure that your financial settlement is handled with care and precision. Our team will guide you through the process, advocating for your best interests and striving for a fair and equitable resolution."


        />

      </div>


      <LegalServicesMenu 
        main_header="More Family Law Content"
        

        menuItemId1={1}
        title_one="Pre-nuptial Agreements"
        link_one="/practice-areas/family-law/prenuptial-agreements" 
        description_one="Pre-nuptial agreements, once exclusive to the wealthy, have gained broader acceptance and can provide a framework for managing finances during marriage. Careful drafting is essential to ensure fairness and address unique individual circumstances."
        
        menuItemId2={2}
        title_two="Divorce & Separation"
        link_two="/practice-areas/family-law/divorce-and-separation"
        description_two="Our team is dedicated to providing practical advice and support throughout the entire process, ensuring the best possible outcome for all parties involved. We work with our clients to help them make informed decisions and provide a range of services that address their individual needs."
        

        menuItemId3={3}
        title_three="Spousal Maintenance"
        link_three="/practice-areas/family-law/spousal-maintenance"
        description_three="At Jemek Solicitors Limited, we offer expert guidance on spousal maintenance, working to ensure fair and appropriate calculations for both recipients and payers. We understand that adjustments may be necessary due to changes in financial circumstances, and our team can assist with those as well. We are committed to achieving the best possible outcome for our clients."
        
        menuItemId4={4}
        title_four="Financial Division"
        link_four="/practice-areas/family-law/financial-division"
        description_four="Our financial division at Jemek Solicitors Limited focuses on promoting settlements and providing cost-effective services in cases involving asset redistribution. We work closely with related professionals to ensure the best possible outcome for our clients, providing honest and experienced representation."

        menuItemId5={5}
        title_five="Civil Partnerships and Dissolution"
        link_five="/practice-areas/family-law/civil-partnerships-and-dissolution"
        description_five="We offer expert guidance in civil partnership dissolution, addressing finances and children. Our team at Jemek Solicitors Limited understands the complexities of partnership law, and we are committed to providing the best possible outcome for our clients."
        
        menuItemId6={6}
        title_six="Financial Claims For Unmarried Couples"
        link_six="/practice-areas/family-law/financial-claims"
        description_six="Unmarried couples face unique challenges when it comes to addressing financial claims, as they must rely on trust laws and different legal remedies. Our team at Jemek Solicitors Limited provides expert guidance to navigate these complex situations and achieve equitable results."

        menuItemId7={7}
        title_seven="Domestice Abuse"
        link_seven="/practice-areas/family-law/domestic-abuse"
        description_seven="Domestic abuse is a pervasive issue that can manifest in various forms. Our firm is committed to providing compassionate support and guidance to help victims navigate their options and seek the protection they deserve. At Jemek Solicitors Limited, we take this issue seriously and work tirelessly to ensure our clients' safety."
        
        menuItemId8={8}
        title_eight="Cohabitation"
        link_eight="/practice-areas/family-law/co-habitation"
        description_eight="Unmarried couples face unique legal challenges when it comes to parental responsibility and property division. Seeking expert legal advice can help to protect the rights and interests of both partners and ensure they are treated fairly under the law."
        


      />
      </div>

    );
  };
  
  export default FinSettlement;