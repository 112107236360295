import React , { useState } from 'react';
import './eea_citizen.css';


import {RiArrowRightSLine, RiArrowDownSLine } from "react-icons/ri"

const EeaCitizen = () => {


  const [toggleLegalServicesSubMenu, setToggleLegalServicesSubmenu] = useState(false);
  

  

  const handleLegalServicesHover = (hoverId) => (e) => {
    setToggleLegalServicesSubmenu(hoverId);
  }



    return (
      <div className='jemek__eea-wrapper'>
        <div className='jemek__eea-header section-padding page-section'>
        <div className='jemek__eea-header-container container'>
              <div className='jemek__eea-header-container-text text-center'>
                <h1>EEA Citizens & Family Visa</h1>
              </div>
            </div>
            </div>
          
          <div className='jemek__eea-content-one-main page-section'>
            <div className='jemek__eea-content-one-container container'>
              <div className='jemek__eea-content-one-textbox text-center'>
              <p>At Jemek Solicitors, we understand the importance of reuniting families and bringing loved ones together. 
                As such, we provide guidance and support on the rights of admission to the UK for family members of EEA citizens. </p>
              </div>
            </div>
          </div>

          <section className='jemek__eea-content-two-main page-section'>
            <div className='jemek__eea-content-two-container-one container'>
              <div className='jemek__eea-content-two-text_one text-center'>
                <h3 className='text-center'>
                Admission Rights for Family Members of EEA Citizens in the UK
                </h3>
                <p> Under current immigration laws, family members of EEA citizens have the right of admission to the UK without leave to enter or remain as long as they can prove their relationship to an EEA citizen.
                   This is normally done through a passport, an EEA family permit, family residence card, or permanent residence card.</p>

                   <div className='jemek__eea-content-two-text_one-sub text-center'>
                <h5>We recognise that obtaining the relevant documents to prove a family relationship can be challenging. </h5>
                <div className='jemek__eea-content-text-important'>
                <p>At Jemek Solicitors, we understand the importance of a family unity and can provide expert guidance on the requirements for admission.</p>
                </div>
             </div>
    



                <div className='jemek__eea-content-two-text_two text-center'>
                <h3>
                Reasonable Opportunities for Obtaining Relevant Documents
                </h3>
                <p>
                Member States are compelled to give a person claiming to be a family member every reasonable opportunity to obtain the necessary documents. 
                At Jemek Solicitors, our team of experienced immigration lawyers can guide you through the process, providing you with expert advice and support to ensure that you have every opportunity to obtain the necessary documentation or prove your relationship by other means.
                </p>
              </div>
            </div>
            </div>

            <div className='jemek__eea-content-two-text-three_wrapper'>
            <div className='jemek__eea-content-two-text_three text-center'>
              <h2>
              Family Unity as a Priority
              </h2>
              <p>
              At Jemek Solicitors, we understand the importance of family unity and strive to provide expert guidance to ensure that family members can exercise their right of admission to the UK. 
              Our experienced team can provide assistance with obtaining necessary documents, guidance on initial residence requirements, application for a family permit, and advice and assistance for extended family members.
              </p>

            </div>
              </div>



          <div className='jemek__eea-content-two-container-two container'>
            <div className='jemek__eea-content-two-text_four text-center'>
                <h3> Initial Residence Rights for EEA Citizen and Their Family Members</h3>
                <p> 
                Family members of EEA citizens have the same right of initial residence in the UK for a period of three months. At Jemek Solicitors, we can provide expert guidance on the requirements for initial residence and help you to navigate the complex immigration laws.
                </p>
                <div className='jemek__eea-content-text-important'>
                <p>
                We can provide guidance on the initial residence requirements to ensure that family members are aware of their rights and obligations while in the UK.
                </p>
                </div>
              </div>


              <div className='jemek__eea-content-two-text_five text-center'>
                <h3>
                Family Permit Application for Easier Passage
                </h3>
                <p>
                A family member who wishes to travel to the UK can apply to an Entry Clearance Officer (ECO) for a family permit, which will considerably ease passage into the UK. 
                The application is free, and the permit must be issued as soon as possible if the person qualifies. 
                </p>
                <div className='jemek__eea-content-text-important'>
                  <p>
                  At Jemek Solicitors, we provide expert guidance on the application process to ensure that family members are able to obtain the necessary permit.
                 </p>
                </div>
              </div>


              <div className='jemek__eea-content-two-text_six text-center'>
                <h3>
                Extensive Examination for Extended Family Members
                </h3>
                <p>
                An extended family member is subject to an extensive examination by the ECO, whereas an immediate family member need only furnish evidence of the qualifying relationship with the qualified person and of the fact that the qualified person is or will soon be entitled to reside in the UK under the terms of the Directive. 
                </p>
                <div className='jemek__eea-content-text-important'>
                <p>
                We can provide expert advice and assistance to ensure that all necessary evidence is provided for the successful application of extended family members.
                </p>
                </div>
              </div>




              </div>




          </section>



          <section className='jemek__legalservices-explore_wrapper'>
            <div className='jemek__legalservices-explore-container container'>
              <div className='jemek__legalservices-links-header text-center'>
                <h2>More Immigration Law Content </h2>
              </div>
              <div className='jemek__legalservices-links'>
                <div className='jemek__legalsevices-item-one'>
                  <div className='jemek__legalservices-item-wrapper'>
                  <a href="/practice-areas/immigration-law/business-immigration"
                  className= {`jemek__legalservices-links-icon-animation${toggleLegalServicesSubMenu === 1 ? "active" : ""}`} 
                  onMouseEnter={handleLegalServicesHover(1)}
                  onMouseDown={handleLegalServicesHover(false)}>
                    <span className='jemek__legalservices-text'>
                    Business Immigration
                    {toggleLegalServicesSubMenu === 1 ? (
                        <RiArrowDownSLine
                        className='jemek__legalservices-icon'
                        color='#000' />
                      ) : (
                        <RiArrowRightSLine
                        className='jemek__legalservices-icon'
                        color='#000'
                        />
                    )}
                    </span>

                  </a>

                  {toggleLegalServicesSubMenu === 1 && (
                    <div className='jemek__legalservices-dropdown_container container'>
                      <div className='jemek__legalservices-dropdown_content'>
                        <p>
                        We offer expert guidance on UK business immigration, including comprehensive support for sponsorship license applications and resident labor market tests. We also provide legal updates and in-house briefings to keep your organization up-to-date with the latest changes in the immigration landscape, helping you maintain compliance and avoid costly legal repercussions.
                        </p>
                      </div>
                    </div>
                  )}
                                    </div>


                </div>
                <div className='jemek__legalservices-item-two'>
                  <div className='jemek__legalservices-item-wrapper-two'>
                <a href="/practice-areas/immigration-law/asylum-and-human-rights"
                  className= {`jemek__legalservices-links-icon-animation${toggleLegalServicesSubMenu === 2 ? "active" : ""}`} 
                  onMouseEnter={handleLegalServicesHover(2)}
               >
                     <span className='jemek__legalservices-text'>
                    Asylum and Human Rights
                    {toggleLegalServicesSubMenu === 2 ? (
                        <RiArrowDownSLine
                        className='jemek__legalservices-icon'
                        color='#000' />
                      ) : (
                        <RiArrowRightSLine
                        className='jemek__legalservices-icon'
                        color='#000'
                        />
                    )}
                    </span>
                  </a>

                  {toggleLegalServicesSubMenu === 2 && (
                    <div className='jemek__legalservices-dropdown_container container'>
                      <div className='jemek__legalservices-dropdown_content'>
                        <p>Applying for asylum or seeking protection under human rights laws can be a complex and emotional process. Our team of legal experts provides compassionate and dedicated support to individuals seeking asylum or applying for human rights claims.</p>
                      </div>
                    </div>
                  )}
                  </div>
                </div>


                <div className='jemek__legalservices-item-three'>
                <div className='jemek__legalservices-item-wrapper-two'>
                <a href="/practice-areas/immigration-law/personal-and-family"
                  className= {`jemek__legalservices-links-icon-animation${toggleLegalServicesSubMenu === 3 ? "active" : ""}`} 
                  onMouseEnter={handleLegalServicesHover(3)}
               >
                     <span className='jemek__legalservices-text'>
                  Personal and Family Immigration
                    {toggleLegalServicesSubMenu === 3 ? (
                        <RiArrowDownSLine
                        className='jemek__legalservices-icon'
                        color='#000' />
                      ) : (
                        <RiArrowRightSLine
                        className='jemek__legalservices-icon'
                        color='#000'
                        />
                    )}
                    </span>
                  </a>
                    
                  {toggleLegalServicesSubMenu === 3 && (
                    <div className={`jemek__legalservices-dropdown_container container${toggleLegalServicesSubMenu === false ? `jemek__legalservices-dropdown_content-exit` : ""}`}>
                      <div className='jemek__legalservices-dropdown_content'>
                        <p>
                      We understand the importance of family reunification and work diligently to help individuals and families navigate the complexities of the immigration system. We offer a range of services, from initial consultation to application preparation and representation at appeals, to ensure the best possible outcome for our clients.                        </p>
                      </div>
                    </div>
                  )}
                  </div>


                </div>

                <div className='jemek__legalservices-item-four'>
                <div className='jemek__legalservices-item-wrapper-two'>
                <a href="/practice-areas/immigration-law/deportation-and-detention" 
                  className= {`jemek__legalservices-links-icon-animation${toggleLegalServicesSubMenu === 4 ? "active" : ""}`} 
                  onMouseEnter={handleLegalServicesHover(4)}
                >
                  <span className='jemek__legalservices-text'>
                    Deportation and Detention
                    {toggleLegalServicesSubMenu === 4 ? (
                        <RiArrowDownSLine 
                        className='jemek__legalservices-icon'
                        color='#000' />
                      ) : (
                        <RiArrowRightSLine
                        className='jemek__legalservices-icon'
                        color='#000'
                        />
                    )}
                    </span>
                  </a>

                  {toggleLegalServicesSubMenu === 4 && (
                    <div className='jemek__legalservices-dropdown_container container'>
                      <div className='jemek__legalservices-dropdown_content'>
                        <p>
                        Being detained or facing deportation can be a traumatic and overwhelming experience. Our team provides comprehensive legal services for deportation and detention cases, including appeals and detainee release. We understand the importance of a swift and compassionate response, and work diligently to ensure that our clients are provided with the best possible representation and support throughout the entire process.                        </p>
                      </div>
                    </div>
                  )}

                </div>
                </div>


              </div>
            </div>
          </section>




        </div>

    );
  };
  
  export default EeaCitizen;