import React from 'react';
import './footerlow.css';
import { termsofbusinesspdf } from './imports';

const FooterLow = () => {

  const openPdf = (event) => {
    event.preventDefault()
    const newWindow = window.open(termsofbusinesspdf, "_blank");
    if (newWindow) newWindow.opener = null
  }
  return (
    <div className='jemek__footer-lower section__padding'>
      <div className='jemek__footer-lower-container container'>
        <div className='jemek__footer-lower-row row'>
          <div className="jemek__footer-lower-column ">
            <div className='jemek-bottom-footer-item__copyright'>
              <p> Copyright &copy; Jemek Solicitors 2023</p>
            </div>
          </div>
          <div className='jemek__footer-lower-items'>
            <div className='jemek-bottom-footer-item__misc'>
                           <div className='jemek__footer-lower-item'>
             <a className='jemek__footer-lower-lower-items-link_one link-dark text-decoration-none me-3' href="/policy-and-terms/privacy-and-cookies">Privacy Policy</a>
             </div>
             <div className='jemek__footer-lower-item'>
             <a href={termsofbusinesspdf} style={{"cursor" : "pointer"}} className='jemek__footer-lower-items-link_two link-dark text-decoration-none' onClick={openPdf}>Terms of Business</a>
              </div>
              <div className='jemek__footer-lower-item'>
              <a className='jemek__footer-lower-items-link_three link-dark text-decotration-none' href="policy-and-terms/complaints-procedure">Complaints Procedure</a>
              </div>
            </div>




          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterLow;