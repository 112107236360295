import React from 'react';
import './fin_division.css';

import HeaderTemplateOne from '../../../components/headertemplate/Headertemplate';
import TemplateOne from '../../../components/templateone/Templateone';

import LegalServicesMenu from '../../../components/legalservicesmenu/Legalservicesmenu';




const FinDivision = () => {



    return (
      <div className='jemek__findivision' id="divorce">
      <div className='jemek__findivision-header'>
        <HeaderTemplateOne 
        main_header="Financial Division"/>
      </div>

      <div className='jemek__findivision-body container'>
        <TemplateOne

          subheader_text="Financial Divison can be an arduos and stressful process for couples going through separation. Our team of experinced solicitors are here to guide you through it, providing you with a proven legal frame work to ensure your needs are met."
          
          
          header_one="Promoting Settlements"
          text_one="Whatever the value of assets involved, our policy is always to promote settlement following voluntary disclosure and negotiations where possible. We can alternatively support mediation or act on a collaborative basis. If court proceedings are required then we will represent you thoughtfully and diligently."

          header_two="The Court's Discretion"
          text_two="The court has a wide discretion to redistribute income and assets on marriage breakdown so it is never possible to predict precisely the award a court would order. To reduce costs and conflict it is vital that the issues are narrowed as much as possible, that a constructive approach is adopted and that a compromise is sought."
          
          header_three="Honest Advice and Cost-Effective Services"
          text_three="We will not foster false hope nor unrealistic expectation and nor do we shy from giving honest advice even if when we know it will be unpalatable. We will keep you fully appraised of the costs of the proceedings and at all times keep in mind the costs compared to the benefits. Our aim is always to provide a cost-effective service."

          header_four="Diverse Team and Professionals"
          text_four="Our team range from trainee solicitors to senior solicitor and partners who have practiced for more than 30 years. We will attempt to match you with the solicitor best equipped to deal with your case and on an affordable fee structure. We very much work as a team, sharing knowledge and expertise."
          text_important_four="We have also developed close relationships with other related professionals such as accountants, actuaries and IFAs who can give invaluable specialist advice on related matters such as tax implications and pension sharing."


        />

      </div>

      <LegalServicesMenu 
        main_header="More Family Law Content"
        

        menuItemId1={1}
        title_one="Pre-nuptial Agreements"
        link_one="/practice-areas/family-law/prenuptial-agreements" 
        description_one="Pre-nuptial agreements, once exclusive to the wealthy, have gained broader acceptance and can provide a framework for managing finances during marriage. Careful drafting is essential to ensure fairness and address unique individual circumstances."
        
        menuItemId2={2}
        title_two="Divorce & Separation"
        link_two="/practice-areas/family-law/divorce-and-separation"
        description_two="Our team is dedicated to providing practical advice and support throughout the entire process, ensuring the best possible outcome for all parties involved. We work with our clients to help them make informed decisions and provide a range of services that address their individual needs."
        

        menuItemId3={3}
        title_three="Spousal Maintenance"
        link_three="/practice-areas/family-law/spousal-maintenance"
        description_three="At Jemek Solicitors Limited, we offer expert guidance on spousal maintenance, working to ensure fair and appropriate calculations for both recipients and payers. We understand that adjustments may be necessary due to changes in financial circumstances, and our team can assist with those as well. We are committed to achieving the best possible outcome for our clients."
        
        menuItemId4={4}
        title_four="Financial Settlements"
        link_four="/practice-areas/family-law/financial-settlements"
        description_four="When it comes to divorce proceedings, financial settlements can be complex and require full disclosure of finances, negotiation, and potentially a final hearing. A Consent Order can be used to formalize agreements, becoming binding once sealed by the court. Our team at Jemek Solicitors Limited can provide expert guidance on this process and help achieve equitable results."

        menuItemId5={5}
        title_five="Civil Partnerships and Dissolution"
        link_five="/practice-areas/family-law/civil-partnerships-and-dissolution"
        description_five="We offer expert guidance in civil partnership dissolution, addressing finances and children. Our team at Jemek Solicitors Limited understands the complexities of partnership law, and we are committed to providing the best possible outcome for our clients."
        
        menuItemId6={6}
        title_six="Financial Claims For Unmarried Couples"
        link_six="/practice-areas/family-law/financial-claims"
        description_six="Unmarried couples face unique challenges when it comes to addressing financial claims, as they must rely on trust laws and different legal remedies. Our team at Jemek Solicitors Limited provides expert guidance to navigate these complex situations and achieve equitable results."

        menuItemId7={7}
        title_seven="Domestice Abuse"
        link_seven="/practice-areas/family-law/domestic-abuse"
        description_seven="Domestic abuse is a pervasive issue that can manifest in various forms. Our firm is committed to providing compassionate support and guidance to help victims navigate their options and seek the protection they deserve. At Jemek Solicitors Limited, we take this issue seriously and work tirelessly to ensure our clients' safety."
        
        menuItemId8={8}
        title_eight="Cohabitation"
        link_eight="/practice-areas/family-law/co-habitation"
        description_eight="Unmarried couples face unique legal challenges when it comes to parental responsibility and property division. Seeking expert legal advice can help to protect the rights and interests of both partners and ensure they are treated fairly under the law."
        


      />
      </div>

    );
  };
  
  export default FinDivision;
