import React from 'react';
import './collaborative.css';

const Collaborative = () => {



    return (
      <div className='jemek__prenuptials section-padding' id="prenuptials">
        <div className='jemek__prenuptials-content'>

            <h1>Prenuptials</h1>

            <p>This is the conent</p>


        </div>

        </div>

    );
  };
  
  export default Collaborative;