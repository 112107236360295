import React from 'react';
import './memberone.css';
import { Members, ScrollBar } from '../../components';









const MemberOne = () => {


  
  
  return (
    <div>
      <div className='jemek__memberone-content'>
        <Members
    
        member_name="Euchaira Duru" 
        member_position="Senior Partner"
        member_department="Family & Immigration"
        hometown_heading="Hometown"
        member_hometown="Kano, Nigeria"
        education_heading="Education"
        member_qualifications="LL.B (Hons), LL.M, Barrister-at-Law"
        interests_heading="Interests"
        member_interests="Hiking, Reading, Music"
        span_text_one="Mrs. Ogechi E. Duru is the distinguished principal partner of our firm"
        member_text_one=", who brings over 30 years of legal expertise to the table. 
        As a globally trained and accomplished attorney, Ogechi holds licenses to practice in both Nigeria and 
        the United Kingdom, showcasing her exceptional versatility."
        span_text_two="At the helm of our Family Law Practice"
        member_text_two=", Mrs. Ogechi E. Duru is a devoted member of the Law Society Family Panel. She is a passionate advocate for parents, grandparents, and foster carers, recognized for her unwavering dedication to clients' needs. Ogechi's expertise lies in securing out-of-court settlements and skillfully guiding clients through court proceedings from beginning to end. Her clear communication and empathy when addressing sensitive issues make her a top legal advocate who consistently puts her clients first."
        span_text_three="Mrs. Duru began her legal career in Nigeria as a Barrister"
        member_text_three="and Solicitor in 1989 before qualifying as a solicitor in England and Wales in 2010. A recipient of the prestigious British Chevening Scholarship Award, her commitment to learning and excellence is evident. Apart from her success in family law, Mrs. Ogechi E. Duru has earned a stellar reputation as an accredited immigration and asylum senior case worker and supervisor. Her proficiency in Immigration, Human Rights, and Asylum claims has made her a trusted advisor within the Kurdish community. With Mrs. Duru by your side, rest assured that you are in the most capable hands."
        
        />
        
      </div>
      

        <div className='jemek__scrollbar'>
          <ScrollBar 
            scrollbar_heading="Explore More"
            scrollbar_text_one="Home"
            scrollbar_text_two="Who We Are"
            scrollbar_text_three="Practice Areas"
            scrollbar_text_four="Funding Your Case"
            scrollbar_text_five="Business Immigration"
            scrollbar_text_six="Divorce & Separation"
            scrollbar_text_seven="Child Care Law"/>
        </div>



      </div>
    


  );
};

export default MemberOne;
