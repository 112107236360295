import React from "react";
import './templateone.css';



const TemplateOne = ({main_header, header_one, header_two,header_three, header_four, header_five, header_break, subheader_text, 
  text_break, quote_one, text_one, text_two, text_three, text_four, text_five, text_important_one, text_important_two,
  text_important_three, text_important_four, text_important_five
  }) => {

  




    return (
      <div className='jemek__templateone-wrapper'>



          
          <div className='jemek__templateone-content-one-main page-section'>
            <div className='jemek__templateone-content-one-container container'>
              <div className='jemek__templateone-content-one-textbox text-center'>
            {subheader_text && <p> {subheader_text}</p>}
              </div>
            </div>
          </div>

          <section className='jemek__templateone-content-two-main page-section'>
            <div className='jemek__templateone-content-two-container-one container'>
              <div className='jemek__templateone-content-two-text_one text-center'>


            <div className='jemek__template-one__content-items text-center'>
              {header_one && <h3>
                {header_one}
                </h3>}
                {text_one && <p>
                {text_one}
                </p>}
                <div className='jemek__template-one__summ-paragraph'>
                {text_important_one && <p>
                {text_important_one}
                </p>}
                </div>
              </div>




            </div>
            </div>

            <div className='jemek__template-one__content-items text-center'>
                {header_two && <h3>
                {header_two}
                </h3>}
                {text_two && <p>
                  {text_two}
                </p>}
                <div className='jemek__template-one__summ-paragraph'>
                  {text_important_two && <p>
                  {text_important_two}
                 </p>}
                </div>
              </div>
              
          


          <div className='jemek__templateone-content-two-container-two container'>
          <div className='jemek__template-one__content-items text-center'>
                {header_three && <h3>
                {header_three}
                </h3>}
                {text_three && <p>
                  {text_three}
                </p>}
                <div className='jemek__template-one__summ-paragraph'>
                  {text_important_three && <p>
                  {text_important_three}
                 </p>}
                </div>
              </div>


              <div className='jemek__template-one__content-items text-center'>
                {header_four && <h3>
                {header_four}
                </h3>}
                {text_four && <p>
                  {text_four}
                </p>}
                <div className='jemek__template-one__summ-paragraph'>
                  {text_important_four && <p>
                  {text_important_four}
                 </p>}
                </div>
              </div>


              <div className='jemek__template-one__content-items text-center'>
                {header_five && <h3>
                {header_five}
                </h3>}
                {text_five && <p>
                  {text_five}
                </p>}
                <div className='jemek__template-one__summ-paragraph'>
                  {text_important_five && <p>
                  {text_important_five}
                 </p>}
                </div>
              </div>


              </div>





          </section>

        </div>
        );

        };


export default TemplateOne;