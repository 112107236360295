import React from 'react';
import './members.css';


const Members = ({main_header, member_name, member_position, member_department, hometown_heading, member_hometown, education_heading, member_qualifications, interests_heading, member_interests,
span_text_one, member_text_one, span_text_two, member_text_two, span_text_three, member_text_three}) => {
    return (
        <div>

      <div className='jemek__member-header section-padding page-section' id="ourteam">
        <div className='jemek__member-header-container container'>
          <div className='jemek__member-header-text text-center'>
            {main_header && <h1>{main_header}</h1>}
          </div>
        </div>
      </div>

        <div className='jemek__member-content page-section section__padding'>
          <div className='jemek__member-content__container container'>
        <div className='jemek__member-content__item'>
          <div className='jemek__member-content__header'>
            
            {member_name && <h2>{member_name}</h2>}
            {member_position && <h3>{member_position}</h3>}
            {member_department && <p>{member_department}</p>}
            </div>
            <div className='jemek__member-content__row-one'>
              <div className='jemek__member-content__column-one col-lg-4 col-lg-6 mb-4'>
                {hometown_heading && <h3>{hometown_heading}</h3>}
                {member_hometown && <p>{member_hometown}</p>}
               </div>
              <div className='jemek__member-content__column-two col-lg-4 col-lg-6 mb-4'>
                {education_heading && <h3>{education_heading}</h3>}
                {member_qualifications && <p>{member_qualifications}</p>}

                </div>
              <div className='jemek__member-content__column-three col-lg-4 col-lg-6 mb-4'>
                {interests_heading && <h3>{interests_heading}</h3>}
                {member_interests && <p>{member_interests}</p>}
                </div>

            </div>
            <div className='jemek__member-content__row-two'>
            <div className='jemek__member-content__paragraph-one'>
              <p><span className='jemek_ourteam-content-span'>{span_text_one}</span>{member_text_one}</p>
            </div>
            <div className='jemek__member-content__paragraph-two'>
              <p><span className='jemek_ourteam-content-span'>{span_text_two}</span>{member_text_two}</p>
            </div>
            <div className='jemek__member-content__paragraph-three'>
              <p><span className='jemek_ourteam-content-span'>{span_text_three}</span> {member_text_three}</p>
            </div>
            </div>
            </div>
            </div>

          </div>

        </div>
    )
}

export default Members;