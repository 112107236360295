import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './ourpractice.css'

import {
  business_immg,
  deportation_immg,
  asylum_immg,
  family_immg,
  eea_immg,} from './imports';


import {
  cohabitation_family,
  domestic_family,

  financial_family,
  spousal_family,
  settlement_family,
  prenup_family,
  divorce_family,
  claims_family,
  partnership_family,
} from './imports';

import {
  childprotection_childcare,
  maintenance_childcare,
  residence_childcare,
  separation_childcare,
  adoption_family,
} from './imports';


import {RiArrowRightSLine, RiArrowDownSLine } from "react-icons/ri"


const OurPractice = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const activeButton = searchParams.get("activeButton");

    useEffect(() => {
      if (activeButton === "Immigration and Asylum Law") {
        setToggleImmigrationContent(true);
        setToggleFamilyLawContent(false);
        setToggleChildCareContent(false);
      } 
      else 
      
      if (activeButton === "Family Law") {
        setToggleImmigrationContent(false);
        setToggleFamilyLawContent(true);
        setToggleChildCareContent(false);
      }
      else

      if (activeButton === "Child Care Law") {
        setToggleImmigrationContent(false);
        setToggleFamilyLawContent(false);
        setToggleChildCareContent(true);
      }
      else {
        setToggleImmigrationContent(true);
        setToggleFamilyLawContent(false);
        setToggleChildCareContent(false);
      }

      return () => {
        setToggleImmigrationContent(false);
        setToggleFamilyLawContent(false);
        setToggleChildCareContent(false);
      }
      
    }, [activeButton]);

    const [toggleImmigrationContent, setToggleImmigrationContent] = useState(true);
    const  [toggleFamilyLawContent, setToggleFamilyLawContent ] = useState(false);
    const [toggleChildCareContent, setToggleChildCareContent ] = useState(false);


    const handleImmigrationContentClick = () => {
      setToggleFamilyLawContent(false);
      setToggleChildCareContent(false);
      setToggleImmigrationContent(true);
      
    }
    
    const handleFamilyLawContentClick =  () => {
      setToggleImmigrationContent(false);
      setToggleChildCareContent(false);
      setToggleFamilyLawContent(true);
    }
    const handleChildCareLawContent = () => {
      setToggleFamilyLawContent(false);
      setToggleImmigrationContent(false);
      setToggleChildCareContent(true);
    }



    return (
        <div className='jemek__ourpractice-wrapper'>
          <div className='jemek__ourpractice-header section__padding page-section'>
            <div className='jemek__ourpractice-header-container container'>
              <div className='jemek__ourpractice-header-container-text text-center'>
                <h1>Practice Areas</h1>
              </div>
            </div>
          </div>

        <div className='jemek__ourpractice-content-one page-section'>
        <div className='jemek__ourpractice-content-one-container container'>
          <div className='jemek__ourpractice-content-one-textbox text-center'>
            <p>At Jemek Solicitors Limited, we prioritize a client-centered and personalized approach to practicing law. Our goal is to develop a deep understanding of you, your business, and your family to provide the most effective service.
               We are committed to maintaining constant accessibility for our clients and ensure prompt responses to all phone calls and emails.</p>
          </div>
        </div>
        </div>

        <div className='jemek__ourpractice-data-container page-section'>

          <div className='jemek__ourpractice-components'>

        <div className='jemek__ourpractice-component-one'>
        <button 
        
        className={`jemek__ourpractice-component-one-label${toggleImmigrationContent ? " active" : toggleFamilyLawContent || toggleChildCareContent ? " inactive" : ""}`}
        onClick={handleImmigrationContentClick}>
        
            <span>
              Immigration and Asylum Law 
            {toggleImmigrationContent ? (
            <RiArrowDownSLine
              color="#fff" />
            ) : (
              <RiArrowRightSLine 
              color='#fff'
              />
            )}  
            
                        </span>

          </button>
        </div>

        <div className='jemek__ourpractice-component-two'>
          <button 
className={`jemek__ourpractice-component-two-label${toggleFamilyLawContent ? " active" : toggleImmigrationContent || toggleChildCareContent ? " inactive" : ""}`}
          onClick={handleFamilyLawContentClick}>
          
            <span>
              Family Law
               {toggleFamilyLawContent ? (
                <RiArrowDownSLine 
                color='#fff'/>
               ) : (
                <RiArrowRightSLine 
                color='#fff'
                />
               
               )}
            </span>
          </button>
        </div>

        <div className='jemek__ourpractice-component-three'>
          <button 
  className={`jemek__ourpractice-component-three-label${toggleChildCareContent ? " active" : toggleImmigrationContent || toggleFamilyLawContent ? " inactive" : ""}`}
          onClick={handleChildCareLawContent}>

            <span>
              Child Care Law
              {toggleChildCareContent ? ( 
                <RiArrowDownSLine 
                color='#fff' />
              ) : (
                <RiArrowRightSLine
                color='#fff'
                />
              
              )}
            </span> 
          </button>
        </div>
        </div>
        <div className='jemek__ourpractice-components-cards '>
        <section className="jemek__ourpractice-cards-wrapper">
                <div className="jemek__ourpractice-immigration-components">
                  {toggleImmigrationContent && (
                    <div className="jemek__ourpractice-immigration-contents">
                      <div className="jemek__ourpractice-cards-grid">
                        <div className="jemek__ourpractice-first-card card-animation">
                          <div className="jemek__card-image">
                            <a href="/practice-areas/immigration-law/business-immigration" className="jemek__card-link">
                              <div className="jemek__card-img-wrapper">
                                <img src={business_immg} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a href="/practice-areas/immigration-law/business-immigration" className="jemek__card-link">
                                <h3 className="jemek__card-data-title">
                                  Business Immigration
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Expert guidance on UK business immigration,
                                  including UKBA compliance and sponsorship
                                  licences.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/immigration-law/business-immigration"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="jemek__ourpractice-second-card card-animation">
                          <div className="jemek__card-image">
                            <a href="/practice-areas/immigration-law/asylum-and-human-rights" className="jemek__card-link">
                              <div className="jemek__card-img-wrapper">
                                <img src={asylum_immg} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a href="/practice-areas/immigration-law/asylum-and-human-rights"  className="jemek__card-link">
                                <h3 className="jemek__card-data-title">
                                  Asylum and Human Rights
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Expert legal support for asylum and human
                                  rights claims, including judicial review and
                                  ECHR-based applications.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/immigration-law/asylum-and-human-rights" 
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="jemek__ourpractice-third-card card-animation">
                          <div className="jemek__card-image">
                            <a href="/practice-areas/immigration-law/personal-and-family"  className="jemek__card-link">
                              <div className="jemek__card-img-wrapper">
                                <img src={family_immg} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a href="/practice-areas/immigration-law/personal-and-family" className="jemek__card-link">
                                <h3 className="jemek__card-data-title">
                                  Personal and Family Immigration
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Expert assistance in personal and family
                                  immigration, covering a wide range of visa and
                                  nationality applications.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/immigration-law/personal-and-family" 
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="jemek__ourpractice-fourth-card card-animation">
                          <div className="jemek__card-image">
                            <a href="/practice-areas/immigration-law/eea-citizens-and-family"  className="jemek__card-link">
                              <div className="jemek__card-img-wrapper">
                                <img src={eea_immg} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a href="/practice-areas/immigration-law/eea-citizens-and-family"  className="jemek__card-link">
                                <h3 className="jemek__card-data-title">
                                  EEA Citizens
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Simplified entry process for EEA citizen
                                  family members with a range of documentation
                                  options.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/immigration-law/eea-citizens-and-family" 
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="jemek__ourpractice-fifth-card card-animation">
                          <div className="jemek__card-image">
                            <a href="/practice-areas/immigration-law/deportation-and-detention"  className="jemek__card-link">
                              <div className="jemek__card-img-wrapper">
                                <img src={deportation_immg} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a href="/practice-areas/immigration-law/deportation-and-detention" className="jemek__card-link">
                                <h3 className="jemek__card-data-title">
                                  Deportation and Detention
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Comprehensive legal services for deportation
                                  and detention cases, including appeals and
                                  detainee release.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/immigration-law/deportation-and-detention"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="jemek__ourpractice-family-components">
                  {toggleFamilyLawContent && (
                    <div className="jemek__ourpractice-family-contents">
                      <div className="jemek__ourpractice-cards-grid">
                        <div className="jemek__ourpractice-first-card card-animation">
                          <div className="jemek__card-image">
                            <a href="/practice-areas/family-law/divorce-and-separation" className="jemek__card-link">
                              <div className="jemek__card-img-wrapper">
                                <img src={divorce_family} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a href="/practice-areas/family-law/divorce-and-separation" className="jemek__card-link">
                                <h3 className="jemek__card-data-title">
                                  Divorce and Separation
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Comprehensive divorce and separation support
                                  with sensitivity and expertise for optimal
                                  outcomes.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/family-law/divorce-and-separation"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="jemek__ourpractice-second-card card-animation">
                          <div className="jemek__card-image">
                            <a href="/practice-areas/family-law/financial-settlements" className="jemek__card-link">
                              <div className="jemek__card-img-wrapper">
                                <img src={settlement_family} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a href="/practice-areas/family-law/financial-settlements" className="jemek__card-link">
                                <h3 className="jemek__card-data-title">
                                  Financial Settlement
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Financial settlements in divorce proceedings
                                  involve full disclosure of finances,
                                  negotiation, and a final hearing.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/family-law/financial-settlements"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="jemek__ourpractice-third-card card-animation">
                          <div className="jemek__card-image">
                            <a href="/practice-areas/family-law/spousal-maintenance" className="jemek__card-link">
                              <div className="jemek__card-img-wrapper">
                                <img src={spousal_family} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a href="/practice-areas/family-law/spousal-maintenance" className="jemek__card-link">
                                <h3 className="jemek__card-data-title">
                                  Spousal Maintenance
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  We offer expert guidance on spousal
                                  maintenance, ensuring fair and appropriate
                                  calculations for both recipients and payers.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/family-law/spousal-maintenance"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="jemek__ourpractice-fourth-card card-animation">
                          <div className="jemek__card-image">
                            <a href="/practice-areas/family-law/financial-division" className="jemek__card-link">
                              <div className="jemek__card-img-wrapper">
                                <img src={financial_family} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a href="/practice-areas/family-law/financial-division" className="jemek__card-link">
                                <h3 className="jemek__card-data-title">
                                  Financial Divison
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  We focus on promoting settlements and
                                  providing cost-effective services in cases
                                  involving asset redistribution.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/family-law/financial-division"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="jemek__ourpractice-fifth-card card-animation">
                          <div className="jemek__card-image">
                            <a href="/practice-areas/family-law/civil-partnerships-and-dissolution" className="jemek__card-link">
                              <div className="jemek__card-img-wrapper">
                                <img src={partnership_family} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a href="/practice-areas/family-law/civil-partnerships-and-dissolution" className="jemek__card-link">
                                <h3 className="jemek__card-data-title">
                                  Civil Partnerships and Dissolution
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Expert guidance in civil partnership
                                  dissolution, addressing finances and children.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/family-law/civil-partnerships-and-dissolution"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="jemek__ourpractice-sixth-card card-animation">
                          <div className="jemek__card-image">
                            <a href="/practice-areas/family-law/financial-claims" className="jemek__card-link">
                              <div className="jemek__card-img-wrapper">
                                <img src={claims_family} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a href="/practice-areas/family-law/financial-claims" className="jemek__card-link">
                                <h3 className="jemek__card-data-title">
                                  Financial Claims For Unmarried Couples
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Guiding unmarried couples through complex
                                  financial claims for fair resolutions.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/family-law/financial-claims"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="jemek__ourpractice-seventh-card card-animation">
                          <div className="jemek__card-image">
                            <a href="/practice-areas/family-law/prenuptial-agreements" className="jemek__card-link">
                              <div className="jemek__card-img-wrapper">
                                <img src={prenup_family} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a href="/practice-areas/family-law/prenuptial-agreements" className="jemek__card-link">
                                <h3 className="jemek__card-data-title">
                                  Pre-nuptial Agreements
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Pre-nuptial agreements offer financial
                                  management for marriages, requiring tailored
                                  drafting.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/family-law/prenuptial-agreements"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className='jemek__ourpractice-eighth-card card-animation'>
                            <div className='jemek__card-image'>
    <a href='#' className='jemek__card-link'>
      <div className='jemek__card-img-wrapper'>
        <img src={collaborative_family} alt='#'>

        </img>
      </div>
    </a>
    </div>
    <div className='jemek__data-container'>
    <div className='jemek__card-data'>

    <a href='#' className='jemek__card-link'>
      <h3 className='jemek__card-data-title'>Collaborative Law</h3>
    </a>
    <div className='jemek__card-data-description'>
      <p>
        We are dedicated to providing an innovative approach for more amicable family dispute resolutions.
      </p>
      </div>
      </div>
      <div className='jemek__cards-data-bottom'>
        <div className='jemek__cards-data-bottom-redirect'>
          <div className='jemek__cards-data-redirect-link-wrapper'>
            <a href='#' className='jemek__cards-data-link'>
              <span className='jemek__cards-data-redirect-link'>Learn More</span>
            </a>
          </div>
          </div>
        </div>

        </div>
        
            </div> */}

                        <div className="jemek__ourpractice-nineth-card card-animation">
                          <div className="jemek__card-image">
                            <a href="/practice-areas/family-law/domestic-abuse" className="jemek__card-link">
                              <div className="jemek__card-img-wrapper">
                                <img src={domestic_family} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a href="/practice-areas/family-law/domestic-abuse" className="jemek__card-link">
                                <h3 className="jemek__card-data-title">
                                  Domestic Abuse
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Compassionate assistance for domestic abuse
                                  victims, exploring options and ensuring
                                  protection.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/family-law/domestic-abuse"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="jemek__ourpractice-tenth-card card-animation">
                          <div className="jemek__card-image">
                            <a href="/practice-areas/family-law/co-habitation" className="jemek__card-link">
                              <div className="jemek__card-img-wrapper">
                                <img src={cohabitation_family} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a href="/practice-areas/family-law/co-habitation" className="jemek__card-link">
                                <h3 className="jemek__card-data-title">
                                  Co-habitation
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Our team offers legal guidance for
                                  cohabitation, covering property disputes,
                                  financial claims, and child support.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/family-law/co-habitation"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="jemek__ourpractice-childcare-components">
                  {toggleChildCareContent && (
                    <div className="jemek__ourpractice-childcare-contents">
                      <div className="jemek__ourpractice-cards-grid">
                        <div className="jemek__ourpractice-first-card card-animation">
                          <div className="jemek__card-image">
                            <a href="/practice-areas/child-care-law/care-and-protection-of-children" className="jemek__card-link">
                              <div className="jemek__card-img-wrapper">
                                <img
                                  src={childprotection_childcare}
                                  alt="#"
                                ></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a href="/practice-areas/child-care-law/care-and-protection-of-children" className="jemek__card-link">
                                <h3 className="jemek__card-data-title">
                                  Care and Protection of Children
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Expert guidance on child protection and care
                                  matters, including adoption, and child
                                  abduction cases.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/child-care-law/care-and-protection-of-children"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="jemek__ourpractice-second-card card-animation">
                          <div className="jemek__card-image">
                            <a href="/practice-areas/child-care-law/child-maintenance" className="jemek__card-link">
                              <div className="jemek__card-img-wrapper">
                                <img src={maintenance_childcare} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a href="/practice-areas/child-care-law/child-maintenance" className="jemek__card-link">
                                <h3 className="jemek__card-data-title">
                                  Child Maintenance Agreements
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Specialized, tailored support for child
                                  maintenance agreements prioritizing children's
                                  financial welfare.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/child-care-law/child-maintenance"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="jemek__ourpractice-third-card card-animation">
                          <div className="jemek__card-image">
                            <a href="/practice-areas/child-care-law/residence-and-contact" className="jemek__card-link">
                              <div className="jemek__card-img-wrapper">
                                <img src={residence_childcare} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a href="/practice-areas/child-care-law/residence-and-contact" className="jemek__card-link">
                                <h3 className="jemek__card-data-title">
                                  Residence and contact
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Expert support for residence and contact
                                  matters prioritizing children's best
                                  interests.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/child-care-law/residence-and-contact"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="jemek__ourpractice-fourth-card card-animation">
                          <div className="jemek__card-image">
                            <a href="/practice-areas/child-care-law/adoptions" className="jemek__card-link">
                              <div className="jemek__card-img-wrapper">
                                <img src={adoption_family} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a href="/practice-areas/child-care-law/adoptions"  className="jemek__card-link">
                                <h3 className="jemek__card-data-title">
                                  Adoptions
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Personalized, comprehensive legal support for
                                  adoption clients, ensuring positive outcomes
                                  for all involved.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/child-care-law/adoptions" 
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="jemek__ourpractice-fifth-card card-animation">
                          <div className="jemek__card-image">
                            <a href="/practice-areas/child-care-law/separation-agreements"  className="jemek__card-link">
                              <div className="jemek__card-img-wrapper">
                                <img src={separation_childcare} alt="#"></img>
                              </div>
                            </a>
                          </div>
                          <div className="jemek__data-container">
                            <div className="jemek__card-data">
                              <a href="/practice-areas/child-care-law/separation-agreements" className="jemek__card-link">
                                <h3 className="jemek__card-data-title">
                                  Separation Agreements
                                </h3>
                              </a>
                              <div className="jemek__card-data-description">
                                <p>
                                  Compassionate legal support for separation
                                  agreements, ensuring fair outcomes and stable
                                  futures.
                                </p>
                              </div>
                            </div>
                            <div className="jemek__cards-data-bottom">
                              <div className="jemek__cards-data-bottom-redirect">
                                <div className="jemek__cards-data-redirect-link-wrapper">
                                  <a
                                    href="/practice-areas/child-care-law/separation-agreements"
                                    className="jemek__cards-data-link"
                                  >
                                    <span className="jemek__cards-data-redirect-link">
                                      Learn More
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </section>
        </div>
        </div>

      </div>


    );
};


export default OurPractice;