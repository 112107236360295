import React from 'react';
import './App.css';
 
import { Index, WhoWeAre, OurTeam, MemberOne, MemberTwo, MemberThree, MemberFour, OurPractice, FundingYourCase, FeePayingClients, LegalAid, ContactPage} from './container';
import {Contactus, Footer, NavBar, Footerlow } from './components';
import { Prenuptials, Partnerships, FinDivision, FinSettlement, Claims, SpouseMaintenace, Divorce, Collaborative, Cohabitation, DomesticAbuse } from './legal_services/family';

import { Asylum, Business, Deportation, EeaCitizen, PersonalImmigration } from './legal_services/immigration';

import { ChildMaintenance, ChildProtection, Adoptions, Residence, Separation } from './legal_services/child_care';




import { Route, Routes, Navigate } from 'react-router-dom';



import { PolicyAndTerms, CookiesInfo, TermsOfBusiness, Complaints } from './legalandcompliance ';


const App = () => {

  

  return (
    <div className="App">
      <div className='gradient__bg'>
        <NavBar />
        <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
          <Route path = '/home' element={<Index/>}/>
          <Route path ='who-we-are' element={<WhoWeAre/>} />

<Route path ='our-team' element={<OurTeam/>}/>
<Route path ='our-team/ogechi-profile' element={<MemberOne/>} />
<Route path ='our-team/glory-profile' element={<MemberTwo/>} />
<Route path ='our-team/navpreet-profile' element={<MemberThree/>}/>
<Route path ='our-team/onamerem-profile' element={<MemberFour/>}/> 

<Route path ='reach-us' element={<ContactPage/>}/>

<Route path ='funding-your-case' element={<FundingYourCase/>} />
<Route path ='funding-your-case/fee-paying-clients' element={<FeePayingClients/>}/>
<Route path ='funding-your-case/legal-aid' element={<LegalAid/>}/>



<Route path ='practice-areas' element={<OurPractice/>}/>

<Route path ='practice-areas/immigration-law/asylum-and-human-rights' element={<Asylum/>} />
<Route path='practice-areas/immigration-law/business-immigration' element={<Business/>} />
<Route path ='practice-areas/immigration-law/deportation-and-detention' element={<Deportation/>} />
<Route path='practice-areas/immigration-law/eea-citizens-and-family' element={<EeaCitizen/>} />
<Route path ='practice-areas/immigration-law/personal-and-family' element={<PersonalImmigration/>} />


<Route path ='practice-areas/family-law/prenuptial-agreements' element={<Prenuptials/>} />
<Route path='practice-areas/family-law/civil-partnerships-and-dissolution' element={<Partnerships/>} />
<Route path ='practice-areas/family-law/financial-division' element={<FinDivision/>} />
<Route path='practice-areas/family-law/financial-settlements' element={<FinSettlement/>} />
<Route path ='practice-areas/family-law/financial-claims' element={<Claims/>} />
<Route path='practice-areas/family-law/spousal-maintenance' element={<SpouseMaintenace/>} />
<Route path ='practice-areas/family-law/divorce-and-separation' element={<Divorce/>} />
<Route path='practice-areas/family-law/collaborative' element={<Collaborative/>} />
<Route path ='practice-areas/family-law/co-habitation' element={<Cohabitation/>} />
<Route path='practice-areas/family-law/domestic-abuse' element={<DomesticAbuse/>} />


<Route path ='practice-areas/child-care-law/child-maintenance' element={<ChildMaintenance/>} />
<Route path='practice-areas/child-care-law/care-and-protection-of-children' element={<ChildProtection/>} />
<Route path ='practice-areas/child-care-law/adoptions' element={<Adoptions/>} />
<Route path='practice-areas/child-care-law/residence-and-contact' element={<Residence/>} />
<Route path ='practice-areas/child-care-law/separation-agreements' element={<Separation/>} />

<Route path='policy-and-terms' element={<PolicyAndTerms/>}/>
<Route path='policy-and-terms/privacy-and-cookies' element={<CookiesInfo/>}/>
<Route path='policy-and-terms/terms-of-business' element={<TermsOfBusiness/>}/>
<Route path='policy-and-terms/complaints-procedure' element={<Complaints/>}/>



          
          


        </Routes>
        <Contactus/>
        <Footer />
        <Footerlow />

      </div>

    </div>
  );
}

export default App;
