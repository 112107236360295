import React from "react";
import './mobnavbar.css'

import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";

function MainMobMenu({ changeMenu }) {
    

    

    const mobNavContents = [
        { label: "Home", menu: null, link: "/home" },
        { label: "About Us", menu: "AboutUsMobMenu"} ,
        { label: "Practice Areas", menu: "PracticeAreasMobMenu"},
        { label: "Funding Your Case", menu: "FundingYourCaseMobMenu"},
        { label: "Reach Us", link: "/reach-us"}
    ];

    const handleClick = (mobNavContent) => {
        if (mobNavContent.menu) {
            changeMenu(mobNavContent.menu);
        } else if (mobNavContent.link) {
            window.location.href = mobNavContent.link;
        }
        
    };

    return (
        <nav className="jemek__mobile-nav__main-menu">
            <div className="jemek__mobile-nav__container">

            
            
            {mobNavContents.map((mobNavContent, index) => {
                console.log(mobNavContent, index);
                return (
                <button 
                key = {index}
                onClick={() => handleClick(mobNavContent)}
                className="jemek__mobile-nav__button">
                    
                    {mobNavContent.label} 
                    {mobNavContent.menu && <AiOutlineRight className="jemek__mobile-nav-iconright" />}
                </button>
                )
                })}

          </div>      
        </nav>
    );
}


function AboutUsMobMenu({ changeMenu }) {



    const mobNavContents = [
        { label: "Main Menu", menu: "MainMobMenu", isBack: true},
        { label: "Who We Are", menu: null, link: "/who-we-are" },
        { label: "Our Team", menu: null, link: "/our-team"},
        
    ];

    const handleClick = (mobNavContent) => {
        if (mobNavContent.menu) {
            changeMenu(mobNavContent.menu);
        } else if (mobNavContent.link) {
            window.location.href = mobNavContent.link;
        }
        
    };

    return (
        <nav className="jemek__mobile-nav__about-us">
            <div className="jemek__mobile-nav__container">


            {mobNavContents.map((mobNavContent, index) => (
                <button 
                key={index}
                onClick={() => handleClick(mobNavContent)}
                className={`jemek__mobile-nav__button ${mobNavContent.isBack ? 'jemek__mobile-nav__back-button' : ''}`}>
                    {mobNavContent.isBack ? <AiOutlineLeft className="jemek__nav-iconleft" /> : null} 
                    {mobNavContent.label}
                    {mobNavContent.menu && !mobNavContent.isBack ? <AiOutlineRight className="jemek__mobile-nav-iconright" /> : null}
                </button>
            ))}

</div>
        </nav>
    );
}

//Practice areas menu and nested items start //

function PracticeAreasMobMenu({ changeMenu }) {



    const mobNavContents = [
        { label: "Main Menu", menu: "MainMobMenu", isBack: true},
        { label: "Immigration & Asylum", menu:"ImmigrationMobMenu" },
        { label: "Family Law", menu: "FamilyLawMobMenu"},
        { label: "Child Care Law", menu: "ChildCareLawMobMenu"},
        
    ];

    const handleClick = (mobNavContent) => {
        if (mobNavContent.menu) {
            changeMenu(mobNavContent.menu);
        } else if (mobNavContent.link) {
            window.location.href = mobNavContent.link;
        }
        
    };

    return (
        <nav className="jemek__mobile-nav__practice-areas">

            <div className="jemek__mobile-nav__container">
            {mobNavContents.map((mobNavContent, index) => (
                <button 
                key = {index}
                onClick={() => handleClick(mobNavContent)}
                className={`jemek__mobile-nav__button ${mobNavContent.isBack ? 'jemek__mobile-nav__back-button' : ''}`}>
                    {mobNavContent.isBack ? <AiOutlineLeft className="jemek__nav-iconleft" /> : null} 
                    {mobNavContent.label}
                    {mobNavContent.menu && !mobNavContent.isBack ? <AiOutlineRight className="jemek__mobile-nav-iconright" /> : null}
                    
                </button>
            ))}

        </div>
        </nav>
    );
}

function ImmigrationMobMenu({ changeMenu }) {



    const mobNavContents = [
        { label: "Practice Areas", menu: "PracticeAreasMobMenu", isBack: true},
        { label: "Business Immigration", menu : null, link: "/practice-areas/immigration-law/business-immigration"},
        { label: "Asylum & Human Rights", menu: null, link: "/practice-areas/immigration-law/asylum-and-human-rights" },
        { label: "Personal & Family Immigration", menu: null, link: "/practice-areas/immigration-law/personal-and-family"},
        { label: "EEA Citizens & Family", menu: null, link: "/practice-areas/immigration-law/eea-citizens-and-family"},
        { label: "Deportation & Detention", menu: null, link: "/practice-areas/immigration-law/deportation-and-detention"},
        
    ];

    const handleClick = (mobNavContent) => {
        if (mobNavContent.menu) {
            changeMenu(mobNavContent.menu);
        } else if (mobNavContent.link) {
            window.location.href = mobNavContent.link;
        }
        
    };

    

    return (
        <nav className="jemek__mobile-nav__immigration-and-asylum">
            <div className="jemek__mobile-nav__container">
            {mobNavContents.map((mobNavContent, index) => (
                <button 
                key = {index}
                onClick={() => handleClick(mobNavContent)}
                className={`jemek__mobile-nav__button ${mobNavContent.isBack ? 'jemek__mobile-nav__back-button' : ''}`}>
                    {mobNavContent.isBack ? <AiOutlineLeft className="jemek__nav-iconleft" /> : null} 
                    {mobNavContent.label}
                    {mobNavContent.menu && !mobNavContent.isBack ? <AiOutlineRight className="jemek__mobile-nav-iconright" /> : null}
                </button>
            ))}
        
        </div>
        </nav>
    );
}


function FamilyLawMobMenu({ changeMenu }) {
    


    const mobNavContents = [
        { label: "Practice Areas", menu: "PracticeAreasMobMenu", isBack: true},
        { label: "Divorce & Separation", menu : null, link: "/practice-areas/family-law/divorce-and-separation"},
        { label: "Financial Settlements", menu: null, link: "/practice-areas/family-law/financial-settlements" },
        { label: "Spousal Maintenance", menu: null, link: "/practice-areas/family-law/spousal-maintenance"},
        { label: "Financial Division", menu: null, link: "/practice-areas/family-law/financial-division"},
        { label: "Civil Partnerships & Dissolution", menu: null, link: "/practice-areas/family-law/civil-partnerships-and-dissolution"},
        { label: "Financial Claims (Unmarried)", menu: null, link: "/practice-areas/family-law/financial-claims"},
        { label: "Pre-nuptial Agreements", menu: null, link: "/practice-areas/family-law/prenuptial-agreements"},
        { label: "Domestic Abuse", menu: null, link: "/practice-areas/family-law/domestic-abuse"},
        { label: "Cohabitation", menu: null, link: "/practice-areas/family-law/co-habitation"},
        
    ];

    const handleClick = (mobNavContent) => {
        if (mobNavContent.menu) {
            changeMenu(mobNavContent.menu);
        } else if (mobNavContent.link) {
            window.location.href = mobNavContent.link;
        }
        
    };

    return (
        <nav className="jemek__mobile-nav__family">
            <div className="jemek__mobile-nav__container">
            {mobNavContents.map((mobNavContent, index) => (
                <button 
                key = {index}
                onClick={() => handleClick(mobNavContent)}
                className={`jemek__mobile-nav__button ${mobNavContent.isBack ? 'jemek__mobile-nav__back-button' : ''}`}>
                    {mobNavContent.isBack ? <AiOutlineLeft className="jemek__nav-iconleft" /> : null} 
                    {mobNavContent.label}
                    {mobNavContent.menu && !mobNavContent.isBack ? <AiOutlineRight className="jemek__mobile-nav-iconright" /> : null}
                </button>
            ))}

        </div>
        </nav>
    );
}

function ChildCareLawMobMenu({ changeMenu }) {



    const mobNavContents = [
        { label: "Practice Areas", menu: "PracticeAreasMobMenu", isBack: true},
        { label: "Care & Protection of Children", menu : null, link: "/practice-areas/child-care-law/care-and-protection-of-children"},
        { label: "Child Maintenance Agreements", menu: null, link: "/practice-areas/child-care-law/child-maintenance"},
        { label: "Residence & Contact", menu: null, link: "/practice-areas/child-care-law/residence-and-contact"},
        { label: "Adoptions", menu: null, link: "/practice-areas/child-care-law/adoptions"},
        { label: "Civil Separation Agreements", menu: null, link: "/practice-areas/child-care-law/separation-agreements"},
        
    ];

    const handleClick = (mobNavContent) => {
        if (mobNavContent.menu) {
            changeMenu(mobNavContent.menu);
        } else if (mobNavContent.link) {
            window.location.href = mobNavContent.link;
        }
        
    };

    return (
        <nav className="jemek__mobile-nav__child-care">
            <div className="jemek__mobile-nav__container">

            {mobNavContents.map((mobNavContent, index) => (
                <button 
                key = {index}
                onClick={() => handleClick(mobNavContent)}
                className={`jemek__mobile-nav__button ${mobNavContent.isBack ? 'jemek__mobile-nav__back-button' : ''}`}>
                   
                    {mobNavContent.isBack ? <AiOutlineLeft className="jemek__nav-iconleft" /> : null} 
                     {mobNavContent.label}
                    {mobNavContent.menu && !mobNavContent.isBack ? <AiOutlineRight className="jemek__mobile-nav-iconright" /> : null}
                </button>
            ))}

        </div>
        </nav>
    );
}

//Practice areas menu and nested items end //

// Funding Your Case Nested Items Start //

function FundingYourCaseMobMenu({ changeMenu }) {

  

    const mobNavContents = [
        { label: "Funding Your Case", menu: "MainMobMenu", isBack: true},
        { label: "Legal Aid", menu: null, link: "/funding-your-case/legal-aid" },
        { label: "Fee Paying Clients", menu: null, link: "/funding-your-case/fee-paying-clients"},
        
    ];

    const handleClick = (mobNavContent) => {
        if (mobNavContent.menu) {
            changeMenu(mobNavContent.menu);
        } else if (mobNavContent.link) {
            window.location.href = mobNavContent.link;
        }
        
    };

    return (
        <nav className="jemek__mobile-nav__funding-your-case">
            <div className="jemek__mobile-nav__container">
            {mobNavContents.map((mobNavContent, index) => (
                <button 
                key={index}
                onClick={() => handleClick(mobNavContent)}
                className={`jemek__mobile-nav__button ${mobNavContent.isBack ? 'jemek__mobile-nav__back-button' : ''}`}>
                    {mobNavContent.isBack ? <AiOutlineLeft className="jemek__nav-iconleft" /> : null} 
                    {mobNavContent.label}
                </button>
            ))}

        </div>
        </nav>
    );
}


export {MainMobMenu, AboutUsMobMenu, PracticeAreasMobMenu, ImmigrationMobMenu, 
    FamilyLawMobMenu, ChildCareLawMobMenu, FundingYourCaseMobMenu}

