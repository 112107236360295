import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import './contactus.css';
import { negativeWords } from '../negativewords/Negativewords';



const Contactus = () => {
  const csrfTokenRef = useRef('');
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');


  const getCsrfToken = async () => {
    try {
      const response = await axios.get('https://www.emailbackend.jemeksolicitors.co.uk/get_csrf_token');
      csrfTokenRef.current = response.data.csrfToken;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCsrfToken();
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });



  const checkNegativeWords = (input) => {
  for (let word of negativeWords) {
    if (input.toLowerCase().includes(word.toLowerCase())) {
      return true;
    }
  }
  return false;
  }

  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const errors = {};

    if (formData.name.trim() === '' || checkNegativeWords(formData.name)) {
      errors.name = 'Please enter your name';
    }

    if (formData.email.trim() === ''|| checkNegativeWords(formData.email)) {
      errors.email = 'Please enter your email';
    }

    if (formData.phone.trim() === '' || checkNegativeWords(formData.phone)) {
      errors.phone = 'Please enter your phone number';
    }

    if (formData.message.trim() === '' || checkNegativeWords(formData.message)) {
      errors.message = 'Please enter your message';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await axios.post('https://www.emailbackend.jemeksolicitors.co.uk/send_email', formData, {
          headers: {
            'X-CSRFToken': csrfTokenRef.current,
            'Content-Type': 'application/json', 
          },
        });

        if (response.status === 200) {
          setFormData({
            name: '',
            email: '',
            phone: '',
            message: '',
          });
          setFormErrors({});
          setShowMessage(true);
          setErrorMessage('');
          setSuccessMessage('Email sent successfully. We will get back to you as soon as possible.');
          setTimeout(() => {
            setShowMessage(false);
            setErrorMessage('');
      
          }, 13000);
          
          console.log('Email sent successfully');
        } else {
          console.log('Failed to send email. Please try again.');
          if (response.data.errors) {
            setFormErrors(response.data.errors);
            let serverErrors = {};
            for (let field in response.data.errors) {
              serverErrors[field] = response.data.errors[field][0];
            }
            setFormErrors(serverErrors);
          }
          setErrorMessage('Failed to send email, Please try again.')
          setShowMessage(true);
          setSuccessMessage('');

        }
      } catch (error) {
        console.error('Error sending email:', error);
        setErrorMessage('Error sending email. Please try again.'); 
        setSuccessMessage('');
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
          setErrorMessage('');
        }, 6000);
      }
    }
  };

  return (
    <div>
      <div className="jemek__contactus section__padding page-section" id="contact-form">
        <div className="jemek__contactus-container">
          <div className="jemek__contactus-row row">
            <div className="jemek__contactus-column-one col-xl-6 col-xl-6">
              <div className="jemek__contactus-column-one-item">
                <h1 className="text-uppercase">
                  Get in touch <span className="jemek__contactus-text-highlight"> with us</span>
                </h1>
                <p className="text">
                  If you’re looking to visit Jemek Solicitors Limited, please feel free to call us and make an
                  appointment. Alternatively, if you would like to submit a general enquiry, please use our online
                  form and we will contact you to discuss your enquiry.
                </p>
              </div>
            </div>

            <div className={`jemek__contactus-column col-xl-6 col-xl-6 mb-4 ${showMessage ? 'hidden': ''}`}>
              <div className="jemek__contactus-item-form mail_section_1">
                <input
                  type="text"
                  className={`mail_text ${formErrors.name && 'is-invalid'}`}
                  placeholder="Name"
                  name="name"
                  autoComplete="off"
                  value={formData.name}
                  onChange={handleInputChange}
                />
                {formErrors.name && <div className="invalid-feedback">{formErrors.name}</div>}

                <input
                  type="text"
                  className={`mail_text ${formErrors.email && 'is-invalid'}`}
                  placeholder="Email"
                  name="email"
                  autoComplete="off"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                {formErrors.email && <div className="invalid-feedback">{formErrors.email}</div>}

                <input
                  type="text"
                  className={`mail_text ${formErrors.phone && 'is-invalid'}`}
                  placeholder="Phone Number"
                  name="phone"
                  value={formData.phone}
                  autoComplete="off"
                  onChange={handleInputChange}
                  pattern="[0-9]+"
                />
                {formErrors.phone && <div className="invalid-feedback">{formErrors.phone}</div>}

                <textarea
                  className={`message-bt ${formErrors.message && 'is-invalid'}`}
                  placeholder="Message"
                  rows="6"
                  id="comment"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                />
                {formErrors.message && <div className="invalid-feedback">{formErrors.message}</div>}
                
                <div className='jemek__contact-us__fine-print'>
                  <small>Please note that by sending us a message, you are agreeing to our
                  <a className='fine-print' href="/policy-and-terms/privacy-and-cookies"> Privacy Policy.</a></small>
                </div>

                <div className="jemek__contactus-form-btn">
                  <button
                    className="jemek__contactus-formbtn-one btn btn-custom-black btn-xl text-uppercase"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
            {showMessage && (
          <div className={`jemek__contactus-column messages col-xl-6 col-xl-6 mb-4${successMessage ? 'success' : 'error' }`}>
          {successMessage && <div> 
            <p className="success-message">Messsage sent successfully. We will get back to you <span className='contactform-message-highlight'>as soon as possible.</span></p>

            </div>}
          {errorMessage && <div>
            <p className="error-message">Error sending email. Please <span className='contactform-message-highlight'>try again</span> </p>

            </div>}
        </div>
        )}
          </div>
        </div>

      </div>
    </div>
  );
};

export default Contactus;