import React from "react";
import "./ourteam.css";
import {

  meetheteam_placeholder,
} from "./imports";

const OurTeam = () => {
  return (
    <div className="jemek__ourteam">
      <section className="jemek__ourteam-header section__padding">
        <div className="jemek__ourteam-header-container container">
          <div className="jemek__ourteam-header-container-text text-center">
            <h1>Our Team</h1>
          </div>
        </div>
      </section>

      <section className="jemek__ourteam-content-one page-section section__padding">
        <div className="jemek__ourteam-content-one-container container">
          <div className="jemek__ourteam-content-textbox text-center">
            <p>
            Jemek Solicitors Limited is a boutique law firm specializing in Family and Immigration law, leveraging a broad network of experts to cater to our clients' specific needs, led by two principal attorneys.
            </p>
          </div>
        </div>
      </section>



      <section className="jemek__ourteam-content-two section__padding">
        <div className="jemek__ourteam-content-header-container container">
          <h2>Meet Our <span className="span-text-highlight">Team</span></h2>
        </div>
        <div className="jemek__ourteam-content-container container">
          <div className="jemek__ourteam-content-row row">
            <div className="jemek__ourteam-content-item hidden-section">
              <div className="jemek__ourteam-content-column-one col-xl-6 col-xl-6 mb-4">

              </div>
            </div>
            <div className="jemek__ourteam-content-item">
              <div className="jemek__ourteam-content-column-two col-xl-6 col-xl-6 mb-4">
              <a className="jemek__ourteam__a" href="/our-team/ogechi-profile">
                <div className="jemek__ourteam__content-wrapper">
                <div className="jemek__ourteam-content-image">
                  <img src={meetheteam_placeholder} alt="Meet the team" />
                </div>
                <div className="jemek__ourteam-content-text">
                  <div className="jemek__ourteam-highlight">
                  <div className="jemek__ourteam-content-text-header">
                   <h3>Eucharia Duru</h3>
                  </div>
                  <div className="jemek__ourteam-text-items">
                    <h4>Senior Partner</h4>
                  </div>
                  </div>

                  <div className="jemek__ourteam-text-items-two">
                    <p>Family and Immigration Solicitor</p>
                  </div>
                </div>
                </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="jemek__ourteam-content-container container">
          <div className="jemek__ourteam-content-row row">
            <div className="jemek__ourteam-content-item">
              <div className="jemek__ourteam-content-column-two col-xl-6 col-xl-6 mb-4">
                <div className="jemek__ourteam-content-image">
                  <img src={meetheteam_placeholder} alt="placeholder" />
                </div>
                <div className="jemek__ourteam-content-text">
                  <div className="jemek__ourteam-content-text-header">
                  <a href ="/our-team/glory-profile"><h3>Glory Oyerior Apata</h3></a>
                  </div>
                  <div className="jemek__ourteam-text-items">
                    <h4>Partner</h4>
                  </div>
                  <div className="jemek__ourteam-text-items-two">
                    <p>Immigration Solicitor</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="jemek__ourteam-content-item hidden-section"></div>
          </div>
        </div>

        <div className="jemek__ourteam-content-container container">
          <div className="jemek__ourteam-content-row row">
            <div className="jemek__ourteam-content-item hidden-section">
              <div className="jemek__ourteam-content-column-two col-xl-6 col-xl-6 mb-4"></div>
            </div>
            <div className="jemek__ourteam-content-item">
              <div className="jemek__ourteam-content-column-two col-xl-6 col-xl-6 mb-4">
              <div className="jemek__ourteam-content-image">
                  <img src={meetheteam_placeholder} alt="placeholder" />
                </div>
                <div className="jemek__ourteam-content-text">
                  <div className="jemek__ourteam-content-text-header">
                  <h3>Navpreet Kaur</h3>
                  </div>
                  <div className="jemek__ourteam-text-items">
                    <h4>Trainee Solicitor</h4>
                  </div>
                  <div className="jemek__ourteam-text-items-two">
                    <p>Family and Immigration practice</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="jemek__ourteam-content-container container">
          <div className="jemek__ourteam-content-row row">
            <div className="jemek__ourteam-content-item">
              <div className="jemek__ourteam-content-column-two col-xl-6 col-xl-6 mb-4">
              <div className="jemek__ourteam-content-image">
                  <img src={meetheteam_placeholder} alt="placeholder" />
                </div>
                <div className="jemek__ourteam-content-text">
                  <div className="jemek__ourteam-content-text-header">
                  <h3>Onamerem Duru</h3>
                  </div>
                  <div className="jemek__ourteam-text-items">
                    <h4>Paralegal</h4>
                  </div>
                  <div className="jemek__ourteam-text-items-two">
                    <p>Family and Immigration practice</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="jemek__ourteam-content-item hidden-section">
              <div className="jemek__ourteam-content-column-two col-xl-6 col-xl-6 mb-4"></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OurTeam;
