import React from "react";
import "./legalaid.css";
import TemplateOne from "../../components/templateone/Templateone";
import HeaderTemplateOne from "../../components/headertemplate/Headertemplate";
import MasonryCard from "../../components/masonrycard/Masonrycard";

import useIntersectionObserver from "../../components/intersectionobserver/Intersectionobserver";


import {

  whoweare_masonry,
  contactus_masonry,
  funding_masonry,
  practiceareas_masonry,
  gov_image,
} from "./imports";
import { useState } from "react";




const LegalAid = () => {
  const [show, setShow] = useState(false);

  const refOne = useIntersectionObserver(setShow);
  const refTwo = useIntersectionObserver(setShow);

  return (
    <div className="jemek__legalaid-wrapper">
      <section className="jemek__legalaid-header">
        <HeaderTemplateOne main_header="Legal Aid" />
      </section>
      <div className="jemek__legalaid-subheader">
        <TemplateOne subheader_text="We are committed to providing accessible legal services to individuals who may be facing financial constraints. We understand the importance of having access to quality legal representation and if you meet the financial eligibility criteria, you may be entitled to Legal Aid, which can provide you with free legal services. " />
        <section className="jemek__legalaid-sectionone page-section section__padding">

          <div className="jemek__legalaid-sectionone-contents text-center container">
            <div className="jemek__legalaid-content-header">
              <h3>Understanding Legal Aid and Eligibility</h3>
              <div className="jemek__legalaid-content-paragraph">
                <p>
                  Legal Aid is a government-funded program designed to ensure
                  that individuals who cannot afford legal representation still
                  have access to justice. To determine your eligibility for
                  Legal Aid, one of our experienced solicitors will assess your
                  financial circumstances, taking into account factors such as
                  income, assets, and household expenses. This assessment helps
                  us ascertain whether you meet the financial requirements to
                  qualify for Legal Aid.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="jemek__legalaid-sectiontwo page-section section__padding" >
          <div className="jemek__legalaid-content-container container" ref={refOne}>
          <div className="jemek__legal-aid-content__item-header">
                <h3>Legal Aid <span className="jemek__legal-aid-content__item-highlight"> Resources</span></h3>
              </div>
            <div className="jemek__legal-aid-content__items">
              <div className="jemek__legal-aid-content__item-wrapper">
              <div className="jemek__legal-aid-content__item-one">
                <div className="jemek__legal-aid-content__item-image">
                  <a href="https://www.gov.uk/legal-aid" rel="noopener noreferrer" target="_blank">
                  <img src={gov_image} alt="UK Gov website logo"/>
                  </a>
                </div>
                <div className="jemek__legal-aid-content__item-container">

       
                <div className="jemek__legal-aid-content__item-text">
                <div className="jemek__legal-aid-content__item-text_h3">
                <a href="https://www.gov.uk/legal-aid" rel="noopener noreferrer" target="_blank">
                  <h4>Legal Aid <span className="span-text-highlight">Information</span></h4>
                  </a>
                  </div>
                  <div className="jemek__legal-aid-content__item-text_p">
                  <p>Legal aid in the UK supports individuals who can't afford the costs of legal advice, mediation, or court representation for serious issues like abuse and homelessness risks.</p>
                  </div>

                    </div>
                    <div className="jemek__legal-aid-content__item-button">
                    <a href="https://www.gov.uk/legal-aid" rel="noopener noreferrer" target="_blank">
                    <button className="jemek__legal-aid-content__button" type="button">Find Out More</button>
                  </a>
                </div>
                </div>


              </div>
              </div>
              <div className="jemek__legal-aid-content__item-wrapper">
              <div className="jemek__legal-aid-content__item-two">
              <div className="jemek__legal-aid-content__item-image">
                <a href="https://www.gov.uk/check-legal-aid" rel="noopener noreferrer" target="_blank">
                <img src={gov_image} alt="UK Gov website logo"/>
                </a>
                </div>
                <div className="jemek__legal-aid-content__item-container">
                <div className="jemek__legal-aid-content__item-text">
                <div className="jemek__legal-aid-content__item-text_h3">
                  <a href="https://www.gov.uk/check-legal-aid" rel="noopener noreferrer" target="_blank" >
                  <h4>Legal Aid <span className="span-text-highlight">Checker</span></h4>
                  </a>
                  </div>
                  <div className="jemek__legal-aid-content__item-text_p">
                  <p>The UK government's website hosts a tool called the Legal Aid Checker. By entering your financial details, this tool determines your eligibility for Legal Aid.</p>
                    </div>
                    </div>
                    <div className="jemek__legal-aid-content__item-button">
                  <a href="https://www.gov.uk/check-legal-aid" rel="noopener noreferrer" target="_blank">
                    <button className="jemek__legal-aid-content__button" type="button">Find Out More</button>
                  </a>
                </div>
                </div>
           

                  
              </div>
              </div>
              {/*
              <div className="jemek__legal-aid-content__item-wrapper">
                <div className="jemek__legal-aid-content__item-three">
                <div className="jemek__legal-aid-content__item-image">
                  <img src={gov_image} alt="UK Gov website logo"/>
                </div>
                <div className="jemek__legal-aid-content__item-container">
                <div className="jemek__legal-aid-content__item-text">
                <div className="jemek__legal-aid-content__item-text_h3">
                  <h3>Legal Aid Checker</h3>
                  </div>
                  <div className="jemek__legal-aid-content__item-text_p">
                  <p>To determine your eligibility for Legal Aid, you can
                    conveniently use the Legal Service's Legal Aid Checker
                    available on the Legal Services Website. This online tool
                    allows you to input relevant financial information to assess
                    whether you may qualify for public funding.</p>
                    </div>
                    </div>
                    <div className="jemek__legal-aid-content__item-button">
                  <a href="#">
                    <button className="jemek__legal-aid-content__button btn btn-custom-transparent btn-xl" type="button">Find Out More</button>
                  </a>
                </div>
                </div>
          
                </div>
              </div>
  */}

    
            </div>
          </div>
        </section>

        <section className="jemek__legalaid-sectionthree page-section section__padding">
          <div className="jemek__legalaid-sectionthree-contents text-center container">
            <div className="jemek__legalaid-content-header">
              <h3>Our Commitment to Access to Justice</h3>
              <div className="jemek__legalaid-content-paragraph">
                <p>
                  At Jemek Solicitors, we firmly believe in equal access
                  to justice for all individuals, regardless of their financial
                  circumstances. Our dedicated team of solicitors are passionate
                  about helping those in need and ensuring that your rights are
                  protected. If you believe you may be eligible for Legal Aid,
                  we encourage you to reach out to us for a confidential
                  consultation. We are here to support you and guide you through
                  the legal process.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="jemek__masonry-card section__padding page-section" ref={refTwo}>
          <MasonryCard
          masonry_card_header="Explore"
          masonry_card_span="More"

          //card-one
          card_one_link="/funding-your-case"
          card_one_image={funding_masonry}
          card_one_alt="funding your case page image"
          card_one_header={[
            {text: 'Funding Your', highlight: false},
            {text:'Case', highlight: true}
          ]}
          card_one_text='Figuring out how to fund your case has never been easier.'
          card_one_button={[
            {text: 'Learn More', highlight: true}
          ]}

          //card-two
          card_two_link="/who-we-are"
          card_two_image={whoweare_masonry}
          card_two_alt="about us page image"
          card_two_header={[
            {text: 'Who', highlight: false},
            {text: ' We', highlight: false},
            {text: 'Are', highlight: true}
          ]}
          card_two_text="Learn about our core principles and values."
          card_two_button={[
            {text: 'Learn More', highlight: true}
          ]}

          //card-three
          card_three_link="/practice-areas"
          card_three_image={practiceareas_masonry}
          card_three_alt="practice areas page image"
          card_three_header={[
            {text: 'Practice', highlight: false},
            {text: 'Areas', highlight: true}
          ]}
          card_three_text="We specialize in various areas of the law. We are here to be of service to you."
          card_three_button={[
            {text: 'Learn More', highlight: true}
          ]}

          //card-four
          card_four_link="/contact-page"
          card_four_image={contactus_masonry}
          card_four_alt="contact us page image"
          card_four_header={[
            {text: 'Contact', highlight: false},
            {text: 'Us', highlight: true}
          ]}
          card_four_text="For More Information on where  to find us and how to reach us."
          card_four_button={[
      
            {text: 'Learn More', highlight: true}
          ]}


  
          />
        </section>
        {/*<section className="jemek__feepaying-bodytwo section__padding page-section">
          <div className="jemekk__feepaying-bodytwo-container container">
            <div className="jemek__feepaying-bodytwo-header">
              <h2>Explore More</h2>
            </div>
            <div className="jemek__masonrycard-items-container container">
              <div className="jemek__masonrycard-items">

                <div className="jemek__masonrycard-item-wrapper">
                <div className="jemek__masonrycard-one">
                  <a href="/funding-your-case">
                    <div className="jemek__masonrycard-img">
                      <img src={funding_masonry} alt="" />
                    </div>
                    <div className="jemek__masonrycard-text">
                      <h3>Funding Your Case</h3>
                      <p>
                        Figuring out how to fund your case has never been
                        easier.
                      </p>
                      <div className="jemek__masonry-learnmore">
                        <p>Learn More</p>
                      </div>
                    </div>
                  </a>
                </div>
                </div>

                <div className="jemek__masonrycard-item-wrapper">
                <div className="jemek__masonrycard-two">
                  <a href="/who-we-are">
                  <div className="jemek__masonrycard-img">
                    <img src={whoweare_masonry} alt="" />
                    </div>

                    <div className="jemek__masonrycard-text">
                      <h3>Who We Are</h3>
                      <p>Learn about our core principles and values.</p>
                      <div className="jemek__masonry-learnmore">
                        <p>Learn More</p>
                      </div>
                    </div>
                  </a>
                </div>
                </div>

                <div className="jemek__masonrycard-item-wrapper">
                <div className="jemek__masonrycard-three">
                  <a href="/practice-areas">
                  <div className="jemek__masonrycard-img">
                    <img src={practiceareas_masonry} alt="" />
                    </div>

                    <div className="jemek__masonrycard-text">
                      <h3>Practice Areas</h3>
                      <p>
                        We specialize in various areas of the law. We are here
                        to be of service to you.
                      </p>
                      <div className="jemek__masonry-learnmore">
                        <p>Learn More</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div className="jemek__masonrycard-item-wrapper">
                <div className="jemek__masonrycard-four">
                  <a href="/contact-page">
                  <div className="jemek__masonrycard-img">
                    <img src={contactus_masonry} alt="" />
                    </div>

                    <div className="jemek__masonrycard-text">
                      <h3>Contact Us</h3>
                      <p>
                        For more information on where to find us and how to
                        reach us.
                      </p>
                      <div className="jemek__masonry-learnmore">
                        <p>Learn More</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              </div>
            </div>
          </div>
</section> */}
      </div>
    </div>
  );
};

export default LegalAid;
