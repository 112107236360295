import React from "react";
import { useLocation, Link } from "react-router-dom";



let currentLink = ''

const BreadCrumbs = () => {
    const location = useLocation();
    const { pathname } = location;
    console.log(location)

    if (pathname === "/home") {
        // Exclude the breadcrumb on the home page
        return null;
      }

  

    const breadcrumbs = [
        {title: 'Home', path: '/home', clickable: true},
        
        ...pathname
        .split('/')
        .filter(breadcrumb => breadcrumb !== '')
        .map((breadcrumb, idx, arr) => {
            currentLink += `/${breadcrumb}`
            
            const breadcrumbPath = `/${arr.slice(0, idx + 1).join('/')}`;

            const isLastBreadcrumb = idx === arr.length - 1;

            const breadcrumbTitle = getTitleFromBreadcrumb(breadcrumb);

            const isClickable = !['immigration-law', 'family-law', 'child-care-law'].includes(breadcrumb) || isLastBreadcrumb;
    
    
            return {
                title: breadcrumbTitle,
                path: breadcrumbPath,
                isLast: isLastBreadcrumb,
                clickable: isClickable
            };
        }),
    ];
        return (
            <div className="crumbs">
                {breadcrumbs.map((breadcrumb, idx) => 
                <div className="breadcrumbs" key={idx}>

                    {breadcrumb.isLast || !breadcrumb.clickable ? (
                        <bold className="last-breadcrumb">{breadcrumb.title}</bold>
                    ) : (
                        <Link to={breadcrumb.path}>{breadcrumb.title}</Link>
                    )}
                    {!breadcrumb.isLast && <span>{' '} {'/'} {' '}</span>}
                    {!breadcrumb.clickable}
                    </div>
                    )}
            </div>
        );
                    };
                    const getTitleFromBreadcrumb = breadcrumb => {
                        const breadcrumbTitle = {
                          'practice-areas': 'Practice Areas',
                          'who-we-are': 'Wo We Are',
                          'our-team' : 'Our Team',
                          'ogechi-profile' : 'Ogechi Profile',
                          'glory-profile' : 'Glory Profile',
                          'navpreet-profile' : 'Navpreet Profile',
                          'onamerem-profile' : 'Onamerem Profile',
                          'reach-us' : 'Reach Us',
                          'funding-your-case' : 'Funding Your Case',
                          'fee-paying-clients' : 'Fee Paying Clients',
                          'legal-aid' : 'Legal Aid',
                          'immigration-law': 'Immigration Law',
                          'asylum-and-human-rights' : 'Asylum and Human Rights',
                          'business-immigration' : 'Business Immigration',
                          'deportation-and-detention' : 'Deportation and Detention',
                          'eea-citizens-and-family' : 'Eea Citizens and Family',
                          'personal-and-family' : 'Personal and Family',

                          'family-law': 'Family Law',
                          'prenuptial-agreements': 'Prenuptial Agreements',

                        
                          'civil-partnerships-and-dissolution' : 'Civil Partnerships and Dissolution',
                          'financial-division' : 'Financial Division',
                          'financial-settlements' : 'Financial Settlements',
                          'financial-claims' : 'Financial Claims',
                          'spousal-maintenance' : 'Spousal Maintenance',
                          'divorce-and-separation' : 'Divorce and Separation',
                          'co-habitation' : 'Co-habitation',
                          'domestic-abuse' : 'Domestic Abuse',

                          'child-care-law' : 'Child Care Law',
                          'child-maintenance' : 'Child Maintenance',
                          'care-and-protection-of-children' : 'Care and Protection of Children',
                          'adoptions' : 'Adoptions',
                          'residence-and-contact' : 'Residence and Contact',
                          'separation-agreements' : 'Separation Agreements',

                          'policy-and-terms' : 'Policy and Terms',
                          'privacy-and-cookies' : 'Privacy and Cookies',
                          'complaints-procedure' : 'Complaints Procedure'

                          // Add more breadcrumb titles as needed
                        };
                      
                        return breadcrumbTitle[breadcrumb] || breadcrumb;
                      };
                      
                      export default BreadCrumbs;