import React from 'react';
import './scrollbar.css';
import { useRef } from 'react';

import {  explore_landing,
    explore_whoweare,
    explore_practiceareas,
    explore_funding,   
    explore_immigration,
    explore_family,
    explore_childcare } from './imports.js';

    import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

const ScrollBar = ({scrollbar_heading, scrollbar_text_one, scrollbar_text_two, scrollbar_text_three, scrollbar_text_four, scrollbar_text_five, scrollbar_text_six, scrollbar_text_seven}) => {

    const scrollContainerRef = useRef(null);

    const handleScroll  = (scrollDistance) => {
      const scrollContainer = scrollContainerRef.current;
      const currentScrollPosition = scrollContainer.scrollLeft;
      const newScrollPosition = currentScrollPosition + scrollDistance;
  
      scrollContainer.scrollTo({
        left:newScrollPosition,
        behaviour:'smooth'
      });
  
  
    }

    
    return (
        <div>
            <div className='jemek__exploremore page-section section__padding'>
          <div className='jemek__exploremore-header'>
            <h2 className='text-center'>{scrollbar_heading}</h2>
          </div>
          <div className='jemek__exploremore-media-scroller-outer relative flex item-center'>


          <div ref={scrollContainerRef} className='jemek__exploremore-media-scroller snaps-inline'>

            <div className='jemek__exploremore-media-element'>
              <a href='/home'>
              <img className='img-fluid' src={explore_landing} alt='explore_landing' loading='lazy'/>
              <p>{scrollbar_text_one}</p>
              </a>
            </div>
            <div className='jemek__exploremore-media-element'>
            <a href='/who-we-are'>
              <img className='img-fluid' src={explore_whoweare} alt='explore_whoweare' loading='lazy'/>
              <p>{scrollbar_text_two}</p>
              </a>
            </div>

            <div className='jemek__exploremore-media-element'>
            <a href='/practice-areas'>
              <img className='img-fluid' src={explore_practiceareas} alt='explore_practicereas' loading='lazy'/>
              <p>{scrollbar_text_three}</p>
              </a>
            </div>

            <div className='jemek__exploremore-media-element'>
            <a href='funding-your-case'>
              <img className='img-fluid' src={explore_funding} alt='explore_funding' loading='lazy'/>
              <p>{scrollbar_text_four}</p>
              </a>
            </div>

            <div className='jemek__exploremore-media-element'>
            <a href='/practice-areas/immigration-law/business-immigration'>
              <img className='img-fluid' src={explore_immigration} alt='explore_immigration' loading='lazy'/>
              <p>{scrollbar_text_five}</p>
              </a>
            </div>

            <div className='jemek__exploremore-media-element'>
            <a href='/practice-areas/family-law/divorce-and-separation'>
              <img className='img-fluid' src={explore_family} alt='explore_family' loading='lazy'/>
              <p>{scrollbar_text_six}</p>
              </a>
            </div>
            
            <div className='jemek__exploremore-media-element'>
            <a href='/practice-areas/child-care-law/care-and-protection-of-children'>
              <img className='img-fluid' src={explore_childcare} alt='explore_childcare' loading='lazy'/>
              <p>{scrollbar_text_seven}</p>
              </a>
            </div>

          </div>
          
          <div className='jemek__exploremore-icon-container-left'onClick={() => handleScroll(-400)}>
              <MdChevronLeft size={35} />
          </div>

          <div className='jemek__exploremore-icon-container-right' onClick={() => handleScroll(400)}>
              <MdChevronRight size={35} />
          </div>
          </div>
 

        </div>
        </div>
    )
}

export default ScrollBar;