import React from 'react';
import './domestic_abuse.css';

import HeaderTemplateOne from '../../../components/headertemplate/Headertemplate';
import TemplateOne from '../../../components/templateone/Templateone';

import LegalServicesMenu from '../../../components/legalservicesmenu/Legalservicesmenu';


const DomesticAbuse = () => {



    return (
      <div className='jemek__domesticabuse' id="domestic_abuse">
      <div className='jemek__domesticabuse-header'>
        <HeaderTemplateOne 
        main_header="Domestic Abuse"/>
      </div>

      <div className='jemek__domesticabuse-body container'>
        <TemplateOne

          subheader_text="Domestic abuse is a serious issue that can impact anyone, regardless of their background or social status. Our team understands the complexities and sensitivities surrounding this issue, and we are committed to providing support and guidance to victims in need."
          
          
          header_one="Recognizing Domestic Abuse"
          text_one="Domestic abuse can take many forms, not all of which involve physical violence. Our team is experienced in identifying the signs of abuse, and we can help victims understand their options and take necessary steps to protect themselves and their children."

          header_two="Taking Action"
          text_two="In severe cases of abuse, victims may need to take urgent action to protect themselves and their children. Our firm has an emergency rota in place to ensure that someone is always available to offer advice and support. We can help victims obtain protection orders, such as non-molestation and occupation orders, as well as other necessary legal actions.

          "
          
          header_three="Empowering Victims"
          text_three="Victims of abuse often feel helpless and overwhelmed by their situation. We strive to empower victims by providing them with the resources and support they need to take control of their lives. Our team can help victims understand their rights and options, as well as provide them with the emotional support they need to overcome the trauma of abuse."

          header_four="Our Commitment to Compassionate Support"
          text_four="We understand that confronting the issue of domestic abuse can be an incredibly difficult and emotional experience. That's why we are committed to providing compassionate support to victims throughout the entire process. Our team is here to listen, offer guidance, and provide the necessary resources to help victims move forward with their lives."


        />

      </div>
      
      <LegalServicesMenu 
        main_header="More Family Law Content"
        

        menuItemId1={1}
        title_one="Pre-nuptial Agreements"
        link_one="/practice-areas/family-law/prenuptial-agreements" 
        description_one="Pre-nuptial agreements, once exclusive to the wealthy, have gained broader acceptance and can provide a framework for managing finances during marriage. Careful drafting is essential to ensure fairness and address unique individual circumstances."
        
        menuItemId2={2}
        title_two="Divorce & Separation"
        link_two="/practice-areas/family-law/divorce-and-separation"
        description_two="Our team is dedicated to providing practical advice and support throughout the entire process, ensuring the best possible outcome for all parties involved. We work with our clients to help them make informed decisions and provide a range of services that address their individual needs."
        

        menuItemId3={3}
        title_three="Spousal Maintenance"
        link_three="/practice-areas/family-law/spousal-maintenance"
        description_three="At Jemek Solicitors Limited, we offer expert guidance on spousal maintenance, working to ensure fair and appropriate calculations for both recipients and payers. We understand that adjustments may be necessary due to changes in financial circumstances, and our team can assist with those as well. We are committed to achieving the best possible outcome for our clients."
        
        menuItemId4={4}
        title_four="Financial Division"
        link_four="/practice-areas/family-law/financial-division"
        description_four="Our financial division at Jemek Solicitors Limited focuses on promoting settlements and providing cost-effective services in cases involving asset redistribution. We work closely with related professionals to ensure the best possible outcome for our clients, providing honest and experienced representation."

        menuItemId5={5}
        title_five="Civil Partnerships and Dissolution"
        link_five="/practice-areas/family-law/civil-partnerships-and-dissolution"
        description_five="We offer expert guidance in civil partnership dissolution, addressing finances and children. Our team at Jemek Solicitors Limited understands the complexities of partnership law, and we are committed to providing the best possible outcome for our clients."
        
        menuItemId6={6}
        title_six="Financial Claims For Unmarried Couples"
        link_six="/practice-areas/family-law/financial-claims"
        description_six="Unmarried couples face unique challenges when it comes to addressing financial claims, as they must rely on trust laws and different legal remedies. Our team at Jemek Solicitors Limited provides expert guidance to navigate these complex situations and achieve equitable results."

        menuItemId7={7}
        title_seven="Financial Settlements"
        link_seven="/practice-areas/family-law/financial-settlements"
        description_seven="When it comes to divorce proceedings, financial settlements can be complex and require full disclosure of finances, negotiation, and potentially a final hearing. A Consent Order can be used to formalize agreements, becoming binding once sealed by the court. Our team at Jemek Solicitors Limited can provide expert guidance on this process and help achieve equitable results."
        
        menuItemId8={8}
        title_eight="Cohabitation"
        link_eight="/practice-areas/family-law/co-habitation"
        description_eight="Unmarried couples face unique legal challenges when it comes to parental responsibility and property division. Seeking expert legal advice can help to protect the rights and interests of both partners and ensure they are treated fairly under the law."
        

      />
      </div>

    );
  };
  
  export default DomesticAbuse;