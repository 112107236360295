import React from "react";
import './complaints.css';


import {RxEnvelopeClosed} from 'react-icons/rx';

import {GiRotaryPhone} from 'react-icons/gi';

import { IoLocationOutline } from "react-icons/io5";

import {CgWebsite} from "react-icons/cg";

const Complaints = () => {
    return (
        <div className="jemek__complaints">
            <section className="jemek__complaints__section-header section__padding">
                <div className="jemek__complaints__container container">
                    <div className="jemek__complaints__header">
                    </div>
                </div>
            </section>
            <section className="jemek__complaints__section-body section_padding">
                <div className="jemek__complaints__body-container container">
                    <div className="jemek__complaints__body-row row">
                        <div className="jemek__complaints__content">
                            <section className="jemek__complaints__content-wrapper">
                                <div className="jemek__complaints__title">
                                    <h2 className="jemek__complaints__h2">Our Complaints Procedure</h2>
                                </div>
                                <div className="jemek__complaints__paragraph">
                                    <p className="jemek__complaints__p">
                                    We are committed to providing the highest quality of legal service to all our clients. Any expression of dissatisfaction about the service you have received from Jemek Solicitors Limited will be considered seriously.
                                    </p>
                                    <p className="jemek__complaints__p">
                                    If you are a client and you have any concerns or complaints about any of the services we provide to you, we would like to discuss them with you and try to resolve them as soon as possible in a professional, efficient and courteous manner. We regard it as an opportunity to monitor and improve our quality of service.
                                    </p>

                                    <p className="jemek__complaints__p">
                                    If you have any concerns about our service, our work, or our charges, you should discuss these first with the individual who has day-to-day control of your matter. If this person cannot address your concerns and you wish to make a complaint, please contact our Client Care Director Glory Oyerior-Apata and or Ogechi Duru.
                                    </p>
                                </div>
                            </section>

        
                            <section className="jemek__complaints__content-wrapper">
      
                                <div className="jemek__complaints__subheader">
                                    <h3 className="jemek__complaints__h3">
                                    What will happen Next
                                    </h3>
                                </div>
                                <div className="jemek__complaints__sub-section">
                                <div className="jemek__complaints__subheader">
                                    <h4 className="jemek__complaints__h4">
                                        Step 1: Acknowledging Your Complaint
                                    </h4>
                                </div>
                                <div className="jemek__complaints__paragraph">
                                    <p className="jemek__complaints__p">
                                    If discussions with the solicitor responsible for your matter does not resolve your issue, a complaints procedure form is available to you upon request. Within three working days of receiving your complaint, it will be catalogued and recorded accordingly in our complaint’s registrar. 
                                    </p>
                                    <p className="jemek__complaints__p">
                                    We aim to resolve your complaint within eight weeks of your notification. We will send you a letter acknowledging receipt of your complaint within three days of receiving it, enclosing a copy of this procedure.
                                    </p>
                                </div>
                                </div>

                                <div className="jemek__complaints__sub-section">
                                <div className="jemek__complaints__subheader">
                                    <h4 className="jemek__complaints__h4">
                                        Step 2: Investigating Your Complaint
                                    </h4>
                                    <div className="jemek__complaints__paragraph">
                                    <p className="jemek__complaints__p">
                                    Within five working days of receiving your complaint, it will be passed on to our client care director who will then review your complaint along with any files and documentation associated with your complaint. You will subsequently send you a letter informing you on how we propose to deal with your complaint.The letter you receive from us might read as follows:
                                    </p>
                                    <ul className="jemek__complaints__ul">
                                        <li>If your complaint is relatively straightforward, we might make a few suggestions on how we can rectify the situation, or we may offer some form of redress.</li>
                                        <li>If your complaint is more complicated, we might ask you to confirm, explain or clarify any issues.</li>
                                        <li>We may ask to meet with you for a face-to-face discussion and we aim to do this within fourteen working days of acknowledging your complaint. If you prefer not to meet or if it not possible to do so for any reason, we will write to you fully setting out our views on the situation, make suggestions on how we can further rectify the situation, or asking you to confirm, explain or expound on issues.</li>
                                    </ul>
                                    <p className="jemek__complaints__p">
                                    Within three working days we will write to you again to confirm what took place and any offer of redress we may have made.
                                    </p>
                                    </div>

                                </div>
                                <div className="jemek__complaints__subheader">
                                <h4 className="jemek__complaints__h4">
                                Step 3: Appleaing against our Final Decision
                                    </h4>
                                </div>
                                <div className="jemek__complaints__paragraph">
                                    <p className="jemek__complaints__p">
                                    If you are not at all pleased with our final decision, please let us know and we will review our decision again. We will let you know the results of your appeal within five working days of receipt and a confirmation of your options will be sent to you in writing. 
                                    </p>
                                    <p className="jemek__complaints__p">
                                    If you remain dissatisfied with our decision, you can then contact the Legal Ombudsman about your complaint.
                                    </p>
                                </div>
                                </div>
                                </section>

                                <section className="jemek__complaints__content-wrapper">
                                <div className="jemek__complaints__subheader">
                                    <h3 className="jemek__complaints__h3">
                                    The Legal Ombudsman
                                    </h3>
                                </div>
                                <div className="jemek__complaints__paragraph">
                                    <p className="jemek__complaints__p">
                                    If you are still not satisfied, you can then contact the Legal Ombudsman (LeO). They expect complaints to be brought to them within six years of the date of the act/omission about which you are concerned, or three years from when you reasonably should have known about a complaint. 
                                    </p>
                                    <p className="jemek__complaints__p">
                                    The Legal Ombudsman will usually only agree to investigate where they have been contacted within six months of our final response to the complaint. The Legal Ombudsman’s contact details are as follows:
                                    </p>
                                </div>
                                <section className="jemek__complaints__contact-container container">
                                    <div className="jemek__complaints__contact-items">
                                        <div className="jemek__complaints__contact-wrapper">
                                            <b className="jemek__complaints__telephone">
                                                <p className="jemek__telephone fs-16"><GiRotaryPhone
                                                size={24}/> : 0300 555 0333</p>
                                       
                                            </b>
                                            <br/>
                                            <b className="jemek__complaints__email fs-16">
                                                <p className="jemek__email"><RxEnvelopeClosed
                                                size={24}/> : enquiries@legalombudsman.org.uk</p>

                                            </b>
                                            <br/>
                                            <b className="jemek__complaints__website">
                                                <p className="jemek__website"><CgWebsite
                                                size={24}/> : www.legalombudsman.org.uk</p>
                                                <p></p>
                                            </b>
                                            <br/>
                                            <b className="jemek__complaints__address">
                                                <p className="jemek__address"><IoLocationOutline
                                                size={24}/> : Legal Ombudsman, PO Box 6806, Wolverhampton, WV1 9WJ</p>
                                            </b>
                                            <br/>
                                        </div>
                                    </div>
                                </section>
                                <div className="jemek__complaints__paragraph">
                                    <p className="jemek__complaints__p">
                                    The Legal Ombudsman deals with service issues. If you are concerned about our conduct or behaviour rather than our service, your complaint should be addressed to the Solicitors Regulation Authority.
                                    </p>
                                    <p className="jemek__complaints__p">
                                    Ordinarily, you cannot use the Legal Ombudsman unless you have first attempted to resolve your complaint using our Complaints Handling Procedure, but you will be able to contact the Legal Ombudsman if:
                                    </p>
                                    <ul className="jemek__complaints__ul">
                                        <li>The complaint has not been resolved to your satisfaction within eight weeks of first making the complaint to us; or </li>
                                        <li>The Legal Ombudsman decides that there are exceptional reasons why the Legal Ombudsman should consider your complaint sooner, or without you having to use our internal Complaints Handling Procedure first; or</li>
                                        <li>The Legal Ombudsman considers that your complaint cannot be resolved using our internal Complaints Handling Procedure because the relationship between you and us has broken down irretrievably.</li>
                                    </ul>

                                    <p className="jemek__complaints__p">
                                    If you wish to make a complaint to the Legal Ombudsman you must be one of the following:
                                    </p>
                                    <ul className="jemek__complaints__ul">
                                        <li>An individual. </li>
                                        <li>A micro-enterprise as defined in European Recommendation 2003/361/EC of 6 May 2003 (broadly, an enterprise with fewer than 10 staff and a turnover or balance sheet value not exceeding €2 million).</li>
                                        <li>A charity with an annual income less than £1 million.</li>
                                        <li>A club, association or society with an annual income less than £1 million.</li>
                                        <li>A trustee of a trust with a net asset value less than £1 million; or</li>
                                        <li>A personal representative or the residuary beneficiaries of an estate where a person with a complaint died before referring it to the Legal Ombudsman.</li>
                                    </ul>
                                    <p className="jemek__complaints__p">
                                    If you are not, you should be aware that you can only obtain redress by using our Complaints Handling Procedure or by mediation or arbitration, or by taking action through the Courts.
                                    </p>

                                </div>

                                </section>
                                <section className="jemek__complaints__content-wrapper">
                                <div className="jemek__complaints__subheader">
                                    <h3 className="jemek__complaints__h3">
                                    Complaints about our professional conduct or behaviour
                                    </h3>
                                </div>
                                <div className="jemek__complaints__paragraph">
                                    <p className="jemek__complaints__p">
                                    Our regulatory body, the Solicitors Regulation Authority (SRA) can help you if you are concerned about our behaviour. This could be for things like dishonesty, taking or losing your money or treating you unfairly because of your age, a disability or other characteristic.
                                    </p>
                                    <p className="jemek__complaints__p">
                                    We are bound by various professional rules of conduct contained within the <a href="www.sra.org.uk/handbook" target="_external" className="jemek__complaints__link">SRA Handbook. </a> You can also see more information about the help the SRA can give to you<a href=" https://www.sra.org.uk/consumers/problems/report-solicitor.page" target="_external" className="jemek__complaints__link"> here.</a> 
                                    </p>
                                </div>
                                </section>
                                <section className="jemek__complaints__content-wrapper">
                                <div className="jemek__complaints__subheader">
                                    <h3 className="jemek__complaints__h3">
                                    Solicitors Regulation Authority Contact Details:
                                    </h3>
                                </div>
                                <div className="jemek__complaints__sra-contact">
                                <b className="jemek__complaints__telephone">
                                                <p className="jemek__telephone fs-16"><GiRotaryPhone
                                                size={24}/> : 0370 606 2555</p>
                                       
                                            </b>
                                            <br/>
                                            <b className="jemek__complaints__email fs-16">
                                                <p className="jemek__email"><RxEnvelopeClosed
                                                size={24}/> : report@sra.org.uk</p>

                                            </b>
                                            <br/>
                                            <b className="jemek__complaints__website">
                                                <p className="jemek__website"><CgWebsite
                                                size={24}/> : www.sra.org.uk</p>
                                                <p></p>
                                            </b>
                                            <br/>

                                </div>
                                </section>
                                <section className="jemek__complaints__content-wrapper">
                                
                                </section>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Complaints;