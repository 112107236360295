import React from 'react';
import './asylum.css';
import TemplateOne from '../../../components/templateone/Templateone';
import HeaderTemplateOne from '../../../components/headertemplate/Headertemplate';

import LegalServicesMenuFour from '../../../components/legalservicesmenufour/Legalservicesmenufour';


const Asylum = () => {



    return (
      <div className="jemek__asylum-content" id="business">
      <div className='jemek__asylum-header'>
        <HeaderTemplateOne
        main_header="Asylum & Human Rights" />

        </div>

        <div className='jemek__businessimmigration-body container'>
          <TemplateOne 
          

          subheader_text="Navigating asylum and human rights claims can be a daunting process, but our experienced team is here to guide you every step of the way. At our firm, we have a proven track record of assisting numerous clients in resolving their asylum and human rights claims."
            
            
          header_one="Expert Guidance and Support"
          text_one="Our dedicated team of legal professionals is well-versed in the intricacies of these cases and works closely with clients to evaluate their situation and provide expert guidance. We understand that each case is unique, and we assess the prospects of success in each case to provide clients with the best possible advice."
          text_important_one="Our team at Jemek Solicitors Limited can help you navigate the complexities of pre-nuptial agreements and ensure that your interests are protected."

          header_two="Wide Range of Applications"
          text_two="Our extensive experience in this area enables us to assist with a wide range of applications, including asylum claims, family reunion applications, and settlement applications for those granted asylum. We recognize the importance of reuniting families and securing a stable future for those who have faced persecution or human rights violations in their home countries."
          text_important_two="These briefings cover topics such as sponsorship licences, resident labour market tests, and reporting and record-keeping duties."
          
          header_three="ECHR-based Applications"
          text_three=" We also support clients with fresh asylum claims, further submissions, and ECHR (European Convention on Human Rights) based applications. This includes Article 8 claims, which involve the right to respect for one's private and family life. Our team is committed to advocating for the human rights of our clients and ensuring that their cases are thoroughly evaluated and presented."


          header_four="Representation in Court"
          text_four="When required, our skilled advocates are always prepared to represent clients in court. We understand the significance of these proceedings and strive to provide the best possible representation, ensuring that our clients' voices are heard and their rights are protected throughout the legal process."
      

          header_five="Comprehensive Approach"
          text_five="Our comprehensive approach to asylum and human rights cases reflects our commitment to supporting clients during these challenging times. By providing expert guidance and steadfast representation, we aim to secure the best possible outcome for each individual we serve."

          
          />

        </div>



        <LegalServicesMenuFour 
        main_header="More Immigration Law Content"
        
        menuItemId1={1}
        title_one="Business Immigration"
        link_one="/practice-areas/immigration-law/business-immigration"
        description_one="We offer expert guidance on UK business immigration, including comprehensive support for sponsorship license applications and resident labor market tests. We also provide legal updates and in-house briefings to keep your organization up-to-date with the latest changes in the immigration landscape, helping you maintain compliance and avoid costly legal repercussions.+"
        
        menuItemId2={2}
        title_two="Personal & Family Immigration"
        link_two="/practice-areas/immigration-law/personal-and-family"
        description_two="Our team provides expert assistance with a wide range of personal and family immigration matters, including visa and nationality applications. We understand the importance of family reunification and work diligently to help individuals and families navigate the complexities of the immigration system. We offer a range of services, from initial consultation to application preparation and representation at appeals, to ensure the best possible outcome for our clients."
        
        enuItemId3={3}
        title_three="EEA Citizens & Family Visa"
        link_three="/practice-areas/immigration-law/eea-citizens-and-family" 
        description_three="We understand that the immigration process can be overwhelming, especially for those who are not familiar with the system. As a result, we offer simplified entry processes for EEA citizen family members, including a range of documentation options. Our team is dedicated to providing comprehensive and efficient services for EEA citizens, helping them navigate the immigration process with ease."
        
        menuItemId4={4}
        title_four="Deportation & Detention"
        link_four="/practice-areas/immigration-law/deportation-and-detention" 
        description_four="Being detained or facing deportation can be a traumatic and overwhelming experience. Our team provides comprehensive legal services for deportation and detention cases, including appeals and detainee release. We understand the importance of a swift and compassionate response, and work diligently to ensure that our clients are provided with the best possible representation and support throughout the entire process."
        

        />




        </div>


    );
  };
  
  export default Asylum;