import React from 'react';
import './separation.css';

import LegalServicesMenuFour from '../../../components/legalservicesmenufour/Legalservicesmenufour';
import TemplateOne from '../../../components/templateone/Templateone';
import HeaderTemplateOne from '../../../components/headertemplate/Headertemplate';


const Separation = () => {



    return (
      <div className="jemek__separation-content" id="business">
      <div className='jemek__separation-header'>
        <HeaderTemplateOne
        main_header="Separation Agreements" />

        </div>

        <div className='jemek__businessimmigration-body container'>
          <TemplateOne 
          

          subheader_text="Separating from a partner can be challenging and emotionally taxing. At Jemek Solicitors Limited, we understand the difficulties involved in this process. That is why our dedicated team of family law experts is committed to providing personalized and compassionate guidance to clients seeking to formalize their separation through a legally binding separation agreement."
            
            
          header_one="Identifying and Addressing Key Issues"
          text_one="Our experienced solicitors work closely with clients to identify and address the key issues that need to be resolved during the separation process. These may include matters related to the division of assets and debts, spousal and child support arrangements, and child custody and visitation agreements. "
          text_important_one="By taking a tailored approach to each client's unique circumstances, we aim to help them reach a fair and mutually beneficial agreement that promotes stability and security moving forward."

          header_two="Comprehensive Legal Support"
          text_two="Jemek Solicitors Limited is well-equipped to provide comprehensive legal support during the negotiation and drafting of separation agreements. Our knowledgeable team will carefully review the terms of the agreement to ensure they are equitable and enforceable while providing practical advice on any potential pitfalls or areas of concern. "
          text_important_two="We work diligently to ensure that our clients fully understand their rights and obligations under the agreement and are prepared to make informed decisions about their future."
          
          header_three="Expert Representation and Guidance"
          text_three="In cases where disputes arise or negotiations become contentious, our skilled solicitors are prepared to offer expert representation and guidance. We are committed to finding amicable resolutions to conflicts and assisting our clients in reaching a fair and reasonable agreement that addresses their needs and concerns. If necessary, we are also prepared to advocate on behalf of our clients in court proceedings to ensure their interests are protected."


          header_four="Client-Focused Approach"
          text_four="Jemek Solicitors Limited takes pride in our empathetic and client-focused approach to separation agreement services. Our commitment to providing exceptional legal support and maintaining open lines of communication throughout the process has made us a trusted partner for individuals navigating the complex and often emotional landscape of separation."
      

          
          />

        </div>



        <LegalServicesMenuFour 
        main_header="More Child Care Law Content"
        
        menuItemId1={1}
        title_one="Child Maintenance Agreements"
        link_one="/practice-areas/child-care-law/child-maintenance" 
        description_one="We provide expert legal services focused on securing fair and sustainable child maintenance agreements for the financial support of children following the separation or divorce of their parents. We offer comprehensive support in negotiating, formalizing, and modifying child maintenance arrangements, with a commitment to personalized and compassionate guidance"
        
        menuItemId2={2}
        title_two="Residence & Contact"
        link_two="/practice-areas/child-care-law/residence-and-contact"
        description_two="We provide expert legal services focused on residence and contact matters, offering tailored support and expert representation to clients in negotiating, formalizing, and modifying arrangements that prioritize the best interests of the children involved."
        
        enuItemId3={3}
        title_three="Adoptions"
        link_three="/practice-areas/child-care-law/adoptions" 
        description_three="We specialize in adoption matters, offering personalized and comprehensive legal support to clients pursuing adoptions. Our dedicated team of adoption specialists provides expert representation and guidance to ensure a seamless and positive experience for all parties involved."
        
        menuItemId4={4}
        title_four="Care & Protection of Children"
        link_four="/practice-areas/child-care-law/care-and-protection-of-children"
        description_four="We provide expert legal services focused on safeguarding the well-being of children in various circumstances, including divorce, custody disputes, adoption, and care proceedings, with a commitment to personalized and compassionate support."
        

        />




        </div>
    );
  };
  
  export default Separation;