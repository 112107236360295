
import React, { useState } from 'react';
import { RiArrowRightSLine, RiArrowDownSLine } from 'react-icons/ri';

import "./legalservicesmenu.css"; 


const LegalServicesMenu = ({ menuItemId1,menuItemId2, menuItemId3, menuItemId4, menuItemId5,menuItemId6,menuItemId7,menuItemId8,menuItemId9,
  link_one, link_two, link_three, link_four, link_five, link_six, link_seven, link_eight, link_nine,
  
  main_header, title_one, description_one, title_two, description_two, title_three, description_three, title_four, description_four, title_five, 
description_five, title_six, description_six, title_seven, description_seven, title_eight, description_eight, title_nine, description_nine }) => {
  
  const [toggleSubMenu, setToggleSubMenu] = useState(false);

  const handleLegalServicesHover = (hoverId) => {
    setToggleSubMenu(hoverId);
  };



  return (


    <section className='jemek__legalservices-explore_wrapper'>
    <div className='jemek__legalservices-explore-container container'>
      <div className='jemek__legalservices-links-header text-center'>
        {main_header && <h2>{main_header}</h2>}
      </div>
      <div className='jemek__legalservices-links'>


    <div className={`jemek__legalservices-item-${menuItemId1}`}>
      <div className='jemek__legalservices-item-wrapper-two'>
        <a
          href= {link_one}
          className={`jemek__legalservices-links-icon-animation${toggleSubMenu === menuItemId1 ? 'active' : ''}`}
          onMouseEnter={() => handleLegalServicesHover(menuItemId1)}
   
        >
          <span className='jemek__legalservices-text'>
            {title_one}
            {toggleSubMenu === menuItemId1 ? (
              <RiArrowDownSLine className='jemek__legalservices-icon' color='#000' />
            ) : (
              <RiArrowRightSLine className='jemek__legalservices-icon' color='#000' />
            )}
          </span>
        </a>

        {toggleSubMenu === menuItemId1 && (
          <div className='jemek__legalservices-dropdown_container container'>
            <div className='jemek__legalservices-dropdown_content'>
              <p>{description_one}</p>
            </div>
          </div>
        )}
      </div>
    </div>

    <div className={`jemek__legalservices-item-${menuItemId2}`}>
      <div className='jemek__legalservices-item-wrapper-two'>
        <a
          href={link_two}
          className={`jemek__legalservices-links-icon-animation${toggleSubMenu === menuItemId2 ? 'active' : ''}`}
          onMouseEnter={() => handleLegalServicesHover(menuItemId2)}

        >
          <span className='jemek__legalservices-text'>
            {title_two}
            {toggleSubMenu === menuItemId2 ? (
              <RiArrowDownSLine className='jemek__legalservices-icon' color='#000' />
            ) : (
              <RiArrowRightSLine className='jemek__legalservices-icon' color='#000' />
            )}
          </span>
        </a>

        {toggleSubMenu === menuItemId2 && (
          <div className='jemek__legalservices-dropdown_container container'>
            <div className='jemek__legalservices-dropdown_content'>
              <p>{description_two}</p>
            </div>
          </div>
        )}
      </div>
    </div>

    <div className={`jemek__legalservices-item-${menuItemId3}`}>
      <div className='jemek__legalservices-item-wrapper-two'>
        <a
          href={link_three}
          className={`jemek__legalservices-links-icon-animation${toggleSubMenu === menuItemId3 ? 'active' : ''}`}
          onMouseEnter={() => handleLegalServicesHover(menuItemId3)}

        >
          <span className='jemek__legalservices-text'>
            {title_three}
            {toggleSubMenu === menuItemId3 ? (
              <RiArrowDownSLine className='jemek__legalservices-icon' color='#000' />
            ) : (
              <RiArrowRightSLine className='jemek__legalservices-icon' color='#000' />
            )}
          </span>
        </a>

        {toggleSubMenu === menuItemId3 && (
          <div className='jemek__legalservices-dropdown_container container'>
            <div className='jemek__legalservices-dropdown_content'>
              <p>{description_three}</p>
            </div>
          </div>
        )}
      </div>
    </div>


    <div className={`jemek__legalservices-item-${menuItemId4}`}>
      <div className='jemek__legalservices-item-wrapper-two'>
        <a
          href={link_four}
          className={`jemek__legalservices-links-icon-animation${toggleSubMenu === menuItemId4 ? 'active' : ''}`}
          onMouseEnter={() => handleLegalServicesHover(menuItemId4)}

        >
          <span className='jemek__legalservices-text'>
            {title_four}
            {toggleSubMenu === menuItemId4 ? (
              <RiArrowDownSLine className='jemek__legalservices-icon' color='#000' />
            ) : (
              <RiArrowRightSLine className='jemek__legalservices-icon' color='#000' />
            )}
          </span>
        </a>

        {toggleSubMenu === menuItemId4 && (
          <div className='jemek__legalservices-dropdown_container container'>
            <div className='jemek__legalservices-dropdown_content'>
              <p>{description_four}</p>
            </div>
          </div>
        )}
      </div>
    </div>


    <div className={`jemek__legalservices-item-${menuItemId5}`}>
      <div className='jemek__legalservices-item-wrapper-two'>
        <a
          href={link_five}
          className={`jemek__legalservices-links-icon-animation${toggleSubMenu === menuItemId5 ? 'active' : ''}`}
          onMouseEnter={() => handleLegalServicesHover(menuItemId5)}

        >
          <span className='jemek__legalservices-text'>
            {title_five}
            {toggleSubMenu === menuItemId5 ? (
              <RiArrowDownSLine className='jemek__legalservices-icon' color='#000' />
            ) : (
              <RiArrowRightSLine className='jemek__legalservices-icon' color='#000' />
            )}
          </span>
        </a>

        {toggleSubMenu === menuItemId5 && (
          <div className='jemek__legalservices-dropdown_container container'>
            <div className='jemek__legalservices-dropdown_content'>
              <p>{description_five}</p>
            </div>
          </div>
        )}
      </div>
    </div>


    <div className={`jemek__legalservices-item-${menuItemId6}`}>
      <div className='jemek__legalservices-item-wrapper-two'>
        <a
          href={link_six}
          className={`jemek__legalservices-links-icon-animation${toggleSubMenu === menuItemId6 ? 'active' : ''}`}
          onMouseEnter={() => handleLegalServicesHover(menuItemId6)}

        >
          <span className='jemek__legalservices-text'>
            {title_six}
            {toggleSubMenu === menuItemId6 ? (
              <RiArrowDownSLine className='jemek__legalservices-icon' color='#000' />
            ) : (
              <RiArrowRightSLine className='jemek__legalservices-icon' color='#000' />
            )}
          </span>
        </a>

        {toggleSubMenu === menuItemId6 && (
          <div className='jemek__legalservices-dropdown_container container'>
            <div className='jemek__legalservices-dropdown_content'>
              <p>{description_six}</p>
            </div>
          </div>
        )}
      </div>
    </div>



    <div className={`jemek__legalservices-item-${menuItemId7}`}>
      <div className='jemek__legalservices-item-wrapper-two'>
        <a
          href={link_seven}
          className={`jemek__legalservices-links-icon-animation${toggleSubMenu === menuItemId7 ? 'active' : ''}`}
          onMouseEnter={() => handleLegalServicesHover(menuItemId7)}

        >
          <span className='jemek__legalservices-text'>
            {title_seven}
            {toggleSubMenu === menuItemId7 ? (
              <RiArrowDownSLine className='jemek__legalservices-icon' color='#000' />
            ) : (
              <RiArrowRightSLine className='jemek__legalservices-icon' color='#000' />
            )}
          </span>
        </a>

        {toggleSubMenu === menuItemId7 && (
          <div className='jemek__legalservices-dropdown_container container'>
            <div className='jemek__legalservices-dropdown_content'>
              <p>{description_seven}</p>
            </div>
          </div>
        )}
      </div>
    </div>

    <div className={`jemek__legalservices-item-${menuItemId8}`}>
      <div className='jemek__legalservices-item-wrapper-two'>
        <a
          href={link_eight}
          className={`jemek__legalservices-links-icon-animation${toggleSubMenu === menuItemId8 ? 'active' : ''}`}
          onMouseEnter={() => handleLegalServicesHover(menuItemId8)}

        >
          <span className='jemek__legalservices-text'>
            {title_eight}
            {toggleSubMenu === menuItemId8 ? (
              <RiArrowDownSLine className='jemek__legalservices-icon' color='#000' />
            ) : (
              <RiArrowRightSLine className='jemek__legalservices-icon' color='#000' />
            )}
          </span>
        </a>

        {toggleSubMenu === menuItemId8 && (
          <div className='jemek__legalservices-dropdown_container container'>
            <div className='jemek__legalservices-dropdown_content'>
              <p>{description_eight}</p>
            </div>
          </div>
        )}
      </div>
    </div>

  

   

    </div>
    </div>
    </section>
  );
};

export default LegalServicesMenu;